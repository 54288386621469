@use "settings/s-general.scss" as general;

.i-logo {

  &.--grayscale {

    img {
      filter: grayscale(1) brightness(60%) contrast(0);
      transition: general.$transition-01;
    }

    &:hover {

      img {
        opacity: 1;
        filter: grayscale(0) brightness(1) contrast(1);
      }
    }
  }
}
