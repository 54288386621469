@use "settings/s-colors" as colors;

@use "settings/s-spacing" as spacing;

.s-content-detail__intro {
  position: relative;
  margin-bottom: 32px;
  padding-bottom: 24px;
}

.s-content-detail__header {
  margin-bottom: 24px;
  row-gap: 0;

  h1 {
    margin-bottom: 24px;
  }
}
