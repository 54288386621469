@use "settings/s-colors.scss" as colors;

$size: 40px;
$offsetFromPage: 40px;
$borderRadius: 20px;
$backgroundColor: colors.$primary;
$backgroundColorHover: colors.$primary-300;
$iconColor: colors.$white;
$iconSize: 16px;
$transition: cubic-bezier(0.68, -0.55, 0.27, 1.55);

.scroll-up {
  width: $size;
  height: $size;
  bottom: $offsetFromPage;
  right: $offsetFromPage;
  border-radius: $borderRadius;
  background-color: $backgroundColor;
  z-index: 50;
  transition:
    background-color 0.3s ease,
    opacity 0.3s $transition,
    transform 0.3s $transition;
  will-change: opacity, transform, background-color;
  pointer-events: none;
  opacity: 0;
  transform: translateY($offsetFromPage) scale(0.8);
  cursor: pointer;

  &:hover {
    background-color: $backgroundColorHover;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 20px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
    transition-property: opacity, box-shadow;
    box-shadow: 0 0 10px 40px colors.$white;
  }

  &:active::after {
    box-shadow: 0 0 0 0 colors.$white;
    position: absolute;
    opacity: 1;
    transition: 0s;
  }

  svg {
    width: $iconSize;
    height: $iconSize;
  }

  path {
    fill: $iconColor;
  }

  &.--active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0) scale(1);
  }
}
