@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

.main-nav__list {

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    width: 100%;
  }

  display: flex;
  justify-content: flex-end;

  &.--lang {
    padding-left: 56px;
    flex: 0;

    @include media-breakpoint-down(xxl) {
      padding-left: 24px;
    }

    @include media-breakpoint-down(lg) {
      padding-left: 0;
    }

    &::before {
      content: "";
      display: block;
      width: calc(100% - 48px);
      height: 1px;
      margin: 0 auto -1px;
      background: colors.$primary-300;
      position: relative;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
}

.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down(lg) {
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 64px;
    flex-direction: column;
    min-width: min(70%, 320px);
    width: max-content;
    max-width: 100%;
    position: fixed;
    left: 100%;
    top: 0;
    min-height: 100vh;
    max-height: 100vh;
    background-color: colors.$white;
    box-shadow: general.$shadow-03;
    transform: translate(0);
    transition: transform general.$transition-01;
  }

  &.--opened {

    @include media-breakpoint-down(lg) {
      transform: translate(-100%);
    }
  }
}

.main-nav__in {
  display: flex;
  width: 100%;

  @include media-breakpoint-down(lg) {
    overflow: auto;
    flex-direction: column;
  }
}
