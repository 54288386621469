@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

@use "settings/s-spacing.scss" as spacing;

.i-review {
  background-color: colors.$white;
  padding: spacing.$spacing-lg 24px 32px;
  border-radius: general.$border-base;
  box-shadow: general.$shadow-border colors.$secondary-200;

  @for $i from 1 to 5 {

    &.--rating-#{$i} {

      .icon {

        &:nth-child(n + #{$i + 1}) {

          path {
            fill: colors.$secondary-200;
          }
        }
      }
    }
  }
}

.i-review__rating {
  width: max-content;
  left: 50%;
  top: 0;
  border-radius: general.$border-base;
  transform: translate(-50%, -50%);
  background-color: colors.$white;
  padding: 12px 16px;
}

.i-review__perex {
  text-decoration-color: transparent;
  transition: text-decoration-color general.$transition-01;

  &:hover {
    text-decoration-color: currentcolor;
  }

  .link {
    margin-left: 4px;
  }
}

.i-review__shortened-perex {
  text-decoration: underline;
  text-decoration-color: inherit;
}

.i-review__author {
  align-self: flex-end;
  color: colors.$primary-500;
}

.i-review__date {
  color: colors.$primary-500;
}
