@use "settings/s-spacing.scss" as spacing;

@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

.base-header {
  margin: 0 auto;
  max-width: 940px;
  margin-bottom: spacing.$spacing-lg;

  @include media-breakpoint-down(lg) {
    max-width: none;
  }

  &.--left {
    text-align: left;
    margin-right: 0;
    margin-left: 0;
    max-width: none;
  }

  &.--m-bottom-0 {
    margin-bottom: 0;
  }

  &.--m-bottom-4 {
    margin-bottom: general.$spacing * 4;
  }

  &.--m-top-4 {
    margin-top: general.$spacing * 4;
  }
}

.base-header__paragraph {

  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}

.base-header__subheading {
  margin-bottom: spacing.$spacing-sm;
  color: colors.$secondary-500;
  line-height: 24px;
  text-transform: uppercase;
}
