.g-logo-slider {
  height: 152px;

  &.swiper {
    overflow: visible;
  }

  .swiper-slide {

    @include media-breakpoint-down(sm) {
      padding: 0 44px;
    }
  }
}
