.i-entry-content-w-header {

  &,
  .entry-content,
  .base-header {

    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }
}
