@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

@use "settings/s-fonts.scss" as fonts;

@use "settings/s-spacing.scss" as spacing;

$header-with-icon-gap: 42px;

.i-icon-cards__sliding-box {
  position: relative;
  width: 100%;
  background: colors.$primary-100;
  z-index: -1;

  @include media-breakpoint-up(lg) {
    position: relative;
    max-height: 0;
    z-index: 1;
  }
}

.i-icon-cards__list {
  overflow: hidden;
  background: colors.$primary-100;
  border-radius: 0 0 general.$border-base general.$border-base;

  @include media-breakpoint-up(lg) {
    max-height: 0;
    transform: translateY(-100%) scaleY(0);
    transition: transform general.$transition-01;
  }

  @include media-breakpoint-up(xl) {
    position: absolute;
    width: 100%;
    padding-left: 24px + $header-with-icon-gap + 80px;
  }
}

.i-icon-cards__title-box {
  padding: 32px 12px 12px 24px;
  background: colors.$white;
  z-index: 2;

  @include media-breakpoint-up(lg) {
    outline: 1px solid colors.$secondary-200;
    border-radius: general.$border-base;
  }
}

.i-icon-cards {
  filter: drop-shadow(#{general.$shadow-empty});
  transition: filter general.$transition-01;

  @include media-breakpoint-down(lg) {
    border-radius: general.$border-base;
    overflow: hidden;
    filter: drop-shadow(#{general.$shadow-05});
  }

  &:hover {
    filter: drop-shadow(#{general.$shadow-05});
    z-index: 10;

    .i-icon-cards__title-box {

      @include media-breakpoint-up(lg) {
        border-radius: general.$border-base general.$border-base 0 0;
        outline: none;
      }
    }

    .i-icon-cards__list {

      @include media-breakpoint-up(lg) {
        max-height: 100vh;
        transform: translateY(0);
      }
    }
  }
}

.i-icon-cards__icon {
  flex: 0 0 auto;
}

.i-icon-cards__list-title {
  color: colors.$primary-500;
  font-weight: 700;
}

.i-icon-cards__header-with-icon {
  column-gap: $header-with-icon-gap;
  padding-right: 12px;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    row-gap: spacing.$spacing-sm;
    margin-bottom: spacing.$spacing-sm;
  }
}

.i-icon-cards__header {
  width: clamp(50%, 360px, 100%);
}

.i-icon-cards__link {
  align-self: flex-end;
}
