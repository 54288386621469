$gallery-img-height: 352px;

.g-gallery-slider {
  --g-gallery-slider-image-height: #{$gallery-img-height};

  &.swiper {
    overflow: visible;

    @include media-breakpoint-down(md) {
      padding: 0 44px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  .swiper-buttons {
    top: calc(var(--g-gallery-slider-image-height) * 0.5);
    margin-top: 8px;
  }
}
