@use "settings/s-colors.scss" as colors;

@use "settings/s-spacing.scss" as spacing;

$list-number-size: 48px;

.i-icon-progress {
  width: clamp(268px, 20%, 100%);
  padding: 0 40px;
  counter-increment: ol-counter;

  @include media-breakpoint-down(lg) {
    flex-direction: row;
    width: clamp(50%, 320px, 100%);
    gap: spacing.$spacing-lg 24px;
    padding: 0 0 0 32px;
    text-align: left;
  }
}

.i-icon-progress__icon-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-down(lg) {
    width: auto;
    flex: 0 0 auto;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 80px);
    height: 1px;
    background-color: colors.$secondary-500;
    z-index: 0;

    @include media-breakpoint-down(lg) {
      width: 1px;
      height: 100vh;
    }
  }
}

.i-icon-progress__icon {
  flex: 0 0 auto;

  .icon {
    position: relative;
    z-index: 1;
    border: 10px solid colors.$white;
    border-radius: 50%;

    svg {
      border-radius: inherit;
    }
  }

  &::after {
    content: counter(ol-counter);
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: $list-number-size;
    height: $list-number-size;
    border-radius: 50%;
    background: colors.$white;
    color: colors.$secondary-600;
    font-size: 26px;
    line-height: $list-number-size;
    font-weight: 900;
    text-align: center;
    z-index: 2;
  }
}
