@use "settings/s-general.scss" as general;

%u-img-hover {
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  border-radius: general.$border-base;

  img {
    transition-property: opacity, transform;
  }
}

/* stylelint-disable no-descending-specificity */

.u-img-hover {

  @extend %u-img-hover;

  &:hover {

    img {
      transform: scale(1.07);
    }

    .icon img {
      transform: none;
    }
  }
}

.u-img-hover-large {

  @extend %u-img-hover;

  &:hover {

    img {
      transform: scale(1.15);
    }

    .icon img {
      transform: none;
    }
  }
}

/* stylelint-enable no-descending-specificity */
