@use "sass:math";

@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

#projectNotices {
  text-align: center;
  position: relative;
  z-index: 60;

  p {
    padding: math.div(general.$spacing * 3, 2);
    transform: none;

    &:first-child {
      margin-top: general.$spacing * 3;
    }

    &.success {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6;
    }

    &.error {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
    }

    &.warning {
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faebcc;
    }

    &.info {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;
    }
  }
}
