@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

//Aside img block

.widget.widget_media_image {
  padding-left: 0;
  padding-right: 0;
  background: none;
  padding-top: 0;
  padding-bottom: 0;

  .widgettitle {
    padding-bottom: general.$spacing * 5;
    background: colors.$white;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      padding-bottom: general.$spacing * 3;
    }
  }

  a {
    display: block;
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;

    img {
      height: 100% !important;
      width: 100%;
      object-fit: cover;
    }
  }
}
