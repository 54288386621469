@use "settings/s-spacing.scss" as spacing;

.g-icon-progress {
  row-gap: spacing.$spacing-lg;
  margin-bottom: spacing.$spacing-lg;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    align-items: center;
  }
}
