.i-share-button {

  &.btn {

    &::before {
      display: none;
    }

    &:hover {
      box-shadow: none;
    }
  }

  &.--facebook {
    background-color: #0879cf;
  }

  &.--twitter {
    background-color: #31b4ff;
  }

  &.--linkedin {
    background-color: #1b44a2;
  }

  &.--pinterest {
    background-color: #e01a3e;
  }
}
