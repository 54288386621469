@use "settings/s-colors.scss" as colors;

.info {
  color: colors.$primary-800;
  padding: 8px;

  &.--with-title {
    padding: 0;
  }

  &.--primary {

    path {
      fill: colors.$primary-500;
    }
  }

  &.--conversion {

    path {
      fill: colors.$conversion-400;
    }
  }
}
