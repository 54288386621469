@use "settings/s-colors" as colors;

@use "settings/s-general" as general;

$contact-form-input-gap: general.$spacing * 4;
$contact-form-radio-gap: general.$spacing * 3;

.f-contact__wrapper {
  display: grid;
  gap: $contact-form-input-gap;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  &.--radios {
    gap: $contact-form-radio-gap;
  }
}

.f-contact__flash {
  padding: 8px 12px;
  margin-bottom: 0;

  &.--success {
    background: colors.$success-bg;
    color: colors.$success;
  }

  &.--error {
    background: colors.$danger-bg;
    color: colors.$danger;
  }

  p {
    margin-bottom: 0;
  }
}

.f-contact {
  padding: general.$spacing * 6;
  box-shadow: general.$shadow-05;
  background-color: colors.$white;
  border-radius: general.$border-base;

  @include media-breakpoint-down(md) {
    padding: general.$spacing * 3;
  }

  .link {
    font-size: 1em;
  }

  &.--large {

    .f-contact__wrapper {

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    textarea.f-base {
      min-height: 224px;
    }
  }
}

.f-contact__perex {
  gap: 20px;
}

.f-contact__bottom {
  gap: $contact-form-input-gap;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.f-contact__file-input {
  gap: 16px 64px;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.f-contact__btn-wrapper {

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
