.i-author {
  column-gap: 20px;

  @include media-breakpoint-down(sm) {
    column-gap: 12px;
  }
}

.i-author__image {
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  height: 48px;

  @include media-breakpoint-down(sm) {
    flex: none;
  }

  .img,
  img {
    border-radius: 50%;
  }
}
