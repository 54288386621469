h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-weight: 700;
}

h1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 800;
}

h2 {
  font-size: 32px;
  line-height: 40px;
}

h3 {
  font-size: 28px;
  line-height: 40px;
}

h4 {
  font-size: 24px;
  line-height: 32px;
}

h5 {
  font-size: 20px;
  line-height: 24px;
}

h6 {
  font-size: 20px;
  line-height: 24px;
}
