@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

$borderRadius: general.$border-base;
$backgroundColor: colors.$primary-100;

$fontColor: colors.$conversion-500;

$paddingY: 4px;
$paddingX: 10px;

.i-tag {
  padding: $paddingY $paddingX;
  background-color: $backgroundColor;
  color: $fontColor;
  box-shadow: inset general.$shadow-empty;
  font-weight: $fontWeight;
  border-radius: $borderRadius;

  &.link {
    text-decoration: none;
    transition: box-shadow general.$transition-01;
  }

  &:hover {
    box-shadow: inset general.$shadow-border currentcolor;
  }
}
