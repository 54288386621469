@use "settings/s-colors" as colors;

@use "settings/s-general" as general;

$page-width: general.$spacing * 3;
$page-height: general.$spacing * 3;

$paddingY: general.$spacing * 0.5;

.pagination {
  display: flex;
  align-items: center;

  li {
    text-align: center;

    a,
    span {
      width: $page-width;
      height: $page-height;
      font-weight: bold;
      font-size: 14px;
      display: block;
    }

    a {
      color: colors.$black-400;
      text-decoration-color: transparent;

      &:hover,
      &:active,
      &:focus {
        color: colors.$primary-500;
      }
    }
  }

  .prev,
  .next {
    transform: rotate(-90deg);
    transition: transform 0.2s ease;

    img {
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    &:hover {
      transform: translateX(-3px) rotate(-90deg);
    }
  }

  .next { /* stylelint-disable-line no-descending-specificity */
    transform: rotate(90deg);

    &:hover {
      transform: translateX(3px) rotate(90deg);
    }
  }

  .active {

    span {  /* stylelint-disable-line no-descending-specificity */
      color: colors.$primary-500;
      background-color: colors.$primary-100;
      border-radius: 50%;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
