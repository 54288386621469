@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

.f-file__info {
  align-self: flex-start;
  flex: 0 0 auto;
}

.f-file__label {
  pointer-events: none;

  @include media-breakpoint-down(lg) {
    gap: 16px;
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  .btn {
    flex: 0 0 auto;
    max-width: 100%;
  }
}

.f-file__input {
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.f-file__message {
  max-width: calc(100% - 64px);
  opacity: 0.5;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.f-file__remove {
  flex: 0 0 auto;
  opacity: 0;
  pointer-events: none;

  &.--visible {
    opacity: 1;
    pointer-events: auto;
  }

  path,
  rect {
    fill: colors.$conversion;
  }
}

.f-file__size-error {

  @include media-breakpoint-down(lg) {
    grid-column: 1 / span 2;
  }

  &.--hidden {
    display: none;
  }

  .f-error {
    left: 0;
  }
}

.f-file {
  overflow: hidden;
  transition: opacity general.$transition-01;

  &.--hidden {
    opacity: 0;
    pointer-events: none;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &.--error {
    padding: 4px;
    margin: -4px;
    box-shadow: general.$shadow-border colors.$danger;
    border-radius: general.$border-base;
  }

  &.--visible {
    opacity: 1;
    pointer-events: auto;

    @include media-breakpoint-down(lg) {
      display: grid;
      grid-template-columns: 32px 1fr;
    }

    .btn {
      pointer-events: auto;
    }
  }

  .info {
    margin-top: 8px;
  }
}
