@use "settings/s-spacing" as spacing;

@use "settings/s-colors" as colors;

@use "settings/s-general" as general;

@use "tools/t-divider" as divider;

$overlay-color: colors.$secondary-600;

//left/right variant image background frame size & position

$image-frame-padding: 32px;
$image-frame-width: calc(100% - #{ $image-frame-padding + 16px});
$image-frame-height: calc(100% + 8px);

$image-frame-top: 40px;

.s-intro__image {
  position: relative;
  margin: 0 spacing.$row-overflow;

  img {
    aspect-ratio: 62 / 19;

    @include media-breakpoint-down(lg) {
      aspect-ratio: 16 / 9;
    }
  }
}

.s-intro {

  .base-section__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.--bottom {

    .s-intro__image {
      order: -1;
      margin-bottom: spacing.$spacing-lg;
    }
  }

  &.--top {

    .s-intro__content {
      margin-bottom: spacing.$spacing-lg;
      margin-top: spacing.$spacing-lg;
    }
  }

  &.--inside {

    .s-intro__content {
      left: 0;
      margin: 0;
      padding: spacing.$spacing-xl-mobile 0;
      color: colors.$white;
    }

    .s-intro__image {
      position: absolute;
      height: 100%;
      width: auto;
      margin: 0 spacing.$row-overflow-without-container;
      right: 0;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;

      .img {
        background-color: $overlay-color;
        height: 100%;
      }

      img {
        opacity: 0.35;
        aspect-ratio: auto;
      }
    }

    path {
      fill: colors.$primary-500;
    }
  }

  &.--left,
  &.--right {

    .container {
      flex-direction: row;
      column-gap: 64px;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }

    .s-intro__image {
      position: relative;
      align-self: center;
      margin: 0;
      width: 100%;

      @include media-breakpoint-up(xxl) {
        min-width: 808px;
      }

      &::before {
        content: "";
        width: $image-frame-width;
        height: $image-frame-height;
        position: absolute;
        top: $image-frame-top;
        border-radius: general.$border-base general.$border-base 0 0;
        filter: drop-shadow(general.$shadow-05);
        z-index: -1;

        @include divider.t-divider-background;
      }

      img {
        aspect-ratio: auto;
      }
    }

    .s-intro__content {
      display: flex;
      width: 100%;

      @include media-breakpoint-down(lg) {
        margin-bottom: spacing.$spacing-lg;
      }
    }
  }

  &.--left {

    .s-intro__image {

      &::before {
        right: -$image-frame-padding;
      }
    }
  }

  &.--right {

    .s-intro__image {

      @include media-breakpoint-up(lg) {
        order: -1;
      }

      &::before {
        left: -$image-frame-padding;
      }
    }
  }
}
