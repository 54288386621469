@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;
/* stylelint-disable */
.widget_nav_menu {
  //Sub menu button

  .sub-menu-button {
    display: block;
    width: general.$spacing * 3;
    height: general.$spacing * 3 !important;
    position: absolute;
    top: general.$spacing;
    right: 0;
    transform: translate(-50%, 0);
    transition: transform 0.3s;
    z-index: 20;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      border-style: solid;
      border-color: colors.$primary;
      border-width: 0 2px 2px 0;
      height: 12px;
      width: 12px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: transform 0.2s;
    }
    &.js-open {
      &::before {
        transform: translate(-50%, -50%) rotate(225deg);
      }
    }
  }
}

.widget_nav_menu > div > ul,
.widget_pages > ul {
  .active,
  .current-menu-item {
    & > a {
      color: colors.$primary;
    }
  }
}
/* stylelint-enable */
