@use "settings/s-general.scss" as general;

@use "settings/s-typography.scss" as typo;

@use "settings/s-colors.scss" as colors;

.entry-content {

  .table-responsive {
    margin: general.$spacing * 2 0;
    padding: general.$spacing * 2;
    box-shadow: inset 0 0 0 1px colors.$secondary-200;
    overflow: auto;
    border-radius: general.$border-base;
  }

  th,
  td,
  tr {
    border: none !important;
  }

  td,
  th {
    padding: general.$spacing * 2;
    text-align: center;
    position: relative;
  }

  thead {
    background-color: colors.$primary-500;
    color: colors.$white;
    font-size: 16px;
    font-weight: bold;
  }

  tbody {
    background-color: colors.$white;
    font-size: 14px;

    tr {

      &:nth-child(2n) {
        background-color: colors.$primary-100;
      }
    }

    td,
    th {
      padding: general.$spacing * 2;
    }
  }

  table {
    margin-bottom: 0 !important;
    width: 100%;
    max-width: 100%;

    p {
      margin-bottom: 0 !important;
      font-size: 16px;
      transform: none;
    }
  }
}
