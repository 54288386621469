@use "settings/s-colors.scss" as colors;

a {
  display: inline-block;
  color: colors.$primary-500;
  transition: text-decoration-color 0.3s ease;
  text-decoration: underline;
  text-decoration-color: currentcolor;

  &:hover,
  &:focus {
    text-decoration-color: transparent;
  }
}
