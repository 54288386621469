@use "settings/s-general.scss" as general;

.u-element-scale-hover {
  transition: transform general.$transition-01;

  &.--left,
  .--left & {
    transform-origin: left top;
  }

  &.--right,
  .--right & {
    transform-origin: right top;
  }

  &:hover {
    transform: scale(1.025);
  }
}
