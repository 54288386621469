@use "settings/s-spacing.scss" as spacing;

.i-button-header {
  margin-bottom: spacing.$spacing-lg;

  .base-header {
    max-width: none;
  }

  .base-heading {
    max-width: 940px;
    margin: 0 auto;
    margin-bottom: 8px;
  }

  .base-header__paragraph {
    max-width: 928px;
    margin: 0 auto;
  }

  &.--left {
    align-items: flex-start;
    justify-content: center;

    .base-heading,
    .base-header__paragraph {
      max-width: none;
      margin: 0;
    }
  }

  &.--m-bottom-0 {
    margin-bottom: 0;
  }
}
