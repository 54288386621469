@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

$switch-width: 64px;
$switch-height: 32px;
$switch-handle-size: 22px;
$switch-handle-scale: 1.18;
$switch-color: colors.$primary-500;
$switch-background: colors.$primary-100;
$switch-padding: 4px;

.f-switch__label {
  position: relative;
  height: $switch-height;
  width: $switch-width;
  display: block;
  margin: 0 16px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    border: 1px solid $switch-color;
    background-color: $switch-background;
    transition: background-color 0.2s, border-color 0.2s;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 100%;
    background-color: $switch-color;
    transform-origin: left center;
    transform: translate($switch-padding, -50%);
    transition: 0.2s, transform 0.2s;
  }

  &:hover,
  &:focus {
    cursor: pointer;

    &::after {
      transform: translate($switch-padding, -50%) scale($switch-handle-scale);
    }
  }
}

.f-switch {

  label {
    text-transform: uppercase;
    font-size: 14px;
    padding: 0;
  }

  input {
    height: 0;
    width: 0;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  input:disabled + .f-switch__label {
    pointer-events: none;

    &::before {
      border-color: colors.$gray-200;
      border-width: 1px;
    }

    &::after {
      transform: translateX(4px);
      background-color: colors.$gray-200;
    }
  }

  input:checked + .f-switch__label {

    &::after {
      transform-origin: right center;
      transform: translate(#{ $switch-width - $switch-handle-size - $switch-padding }, -50%);
    }

    &:hover,
    &:focus {

      &::after {
        transform: translate(38px, -50%) scale($switch-handle-scale);
      }
    }
  }
}
