@use "settings/s-spacing" as spacing;

$gallery-offset: 232px;
$gallery-side-padding-small: 64px;
$gallery-side-padding-large: 192px;  //96 * 2;
$gallery-side-padding: var(--gallery-side-padding);
$column-added-padding-large: 96px;
$column-added-padding-small: 80px;
$column-added-padding: var(--column-added-padding);

.s-entry-content-media__gallery {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;

  @include media-breakpoint-up(xl) {
    position: relative;
    width: 100%;
    margin-top: -40px;
  }

  @include media-breakpoint-up(xxl) {
    margin-top: -$gallery-offset;
  }

  > .icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -75%);
    z-index: 10;

    @include media-breakpoint-down(xxl) {
      left: 42%;
    }

    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
}

.s-entry-content-media__content {
  padding-bottom: spacing.$spacing-lg;

  &.--without-button {

    @include media-breakpoint-down(xxl) {
      padding-bottom: 4px;
    }
  }

  .entry-content {
    margin-bottom: 40px;
  }
}

.s-entry-content-media__media {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  @include media-breakpoint-down(xl) {
    margin-bottom: spacing.$spacing-sm;
  }
}

.s-entry-content-media {
  --gallery-side-padding: #{$gallery-side-padding-small};
  --column-added-padding: #{$column-added-padding-small};

  @include media-breakpoint-up(xxl) {
    --gallery-side-padding: #{$gallery-side-padding-large};
    --column-added-padding: #{$column-added-padding-large};
  }

  &.--has-gallery {

    .s-entry-content-media__content {

      @include media-breakpoint-up(xl) {
        margin-bottom: spacing.$spacing-lg-mobile;
      }

      @include media-breakpoint-up(xxl) {
        margin-bottom: $gallery-offset;
      }
    }
  }

  &.--left {

    .s-entry-content-media__content {

      @include media-breakpoint-up(xxl) {
        padding-right: $column-added-padding;
      }

      @include media-breakpoint-between(xl, xxl) {
        padding-right: calc(#{$column-added-padding-large} * 0.5);
      }
    }

    .s-entry-content-media__media {

      @include media-breakpoint-between(xl, xxl) {
        padding-left: $column-added-padding;
      }
    }

    .s-entry-content-media__gallery {

      @include media-breakpoint-up(xl) {
        padding-right: $gallery-side-padding;
      }

      @include media-breakpoint-between(lg, xxl) {
        justify-content: flex-end;
      }

      > .icon {

        @include media-breakpoint-down(xxl) {
          left: 58%;
        }
      }
    }
  }

  &.--right {

    .row {

      @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;
      }
    }

    .s-entry-content-media__content {

      @include media-breakpoint-up(xxl) {
        padding-left: $column-added-padding;
      }

      @include media-breakpoint-between(xl, xxl) {
        padding-left: calc(#{$column-added-padding-large} * 0.5);
      }
    }

    .s-entry-content-media__media {

      @include media-breakpoint-between(xl, xxl) {
        padding-right: $column-added-padding;
      }
    }

    .s-entry-content-media__gallery {

      @include media-breakpoint-up(xl) {
        padding-left: $gallery-side-padding;
      }

      @include media-breakpoint-between(lg, xxl) {
        justify-content: flex-start;
      }
    }
  }

  &.--single {

    .s-entry-content-media__content {

      @include media-breakpoint-between(lg, xxl) {
        padding-bottom: 0;
      }
    }

    .s-entry-content-media__media {

      @include media-breakpoint-between(xl, xxl) {
        margin-bottom: 212px;
      }

      @include media-breakpoint-between(lg, xl) {
        margin-bottom: 202px;
      }
    }

    .s-entry-content-media__gallery {

      @include media-breakpoint-up(xl) {
        padding: 0 240px;
      }

      @include media-breakpoint-between(lg, xxl) {
        position: absolute;
      }

      @include media-breakpoint-between(xl, xxl) {
        top: 420px;
        right: 0;
      }

      @include media-breakpoint-between(lg, xl) {
        top: 360px;
        padding: 0 80px;
      }

      .i-media-gallery {

        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }
  }
}
