@use "settings/s-general" as general;

@use "settings/s-colors" as colors;

.entry-content {

  .gallery-item {
    position: relative;
    margin: 0;
    padding: 0;

    a {
      display: block;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.7;
      }
    }

    img {
      height: 100%;
      object-fit: contain;
      margin: 0 auto;
    }
  }
}
