@use "settings/s-general.scss" as general;

@use "settings/s-fonts.scss" as fonts;

@use "settings/s-colors.scss" as colors;

@use "settings/s-spacing.scss" as spacing;

@use "settings/s-typography.scss" as typo;

//WP generated widgets

/* stylelint-disable no-descending-specificity */

.aside-main {
  padding-top: general.$spacing * 8;
  padding-bottom: general.$spacing * 8;
  display: flex;
  flex-direction: column;
  gap: general.$spacing * 8;

  @include media-breakpoint-down(sm) {
    padding-top: general.$spacing * 5;
    padding-bottom: general.$spacing * 5;
  }
}

.widget {
  background: colors.$white;
  font-weight: 400;
}

.widgettitle {
  font-weight: bold;
  font-size: typo.$article-heading-font-size;
  line-height: typo.$article-heading-line-height;
  margin-bottom: general.$spacing * 4;
  font-family: fonts.$primary;

  @include media-breakpoint-down(sm) {
    margin-bottom: general.$spacing * 3;
  }
}

// Stránky a navigace, rubriky

.widget_categories:not(.widget_categories_select) ul,
.widget_nav_menu > div > ul,
.widget_pages > ul {
  margin-bottom: general.$spacing * -1;

  li {
    position: relative;

    a::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 100%;
      background-color: colors.$gray-200;
    }

    & > ul {
      height: 0;
      opacity: 0;
      pointer-events: none;
      transition: height 0.2s, opacity 0s;
    }
  }

  li.js-open {

    & > ul {
      height: auto;
      opacity: 1;
      pointer-events: inherit;
      transition: height 0.2s, opacity 0.2s;
    }
  }

  li li {
    padding-left: 15px;
  }

  .active,
  .current-menu-item {

    & > a {
      text-decoration: none;
    }
  }

  & > li:last-of-type {

    a::before {
      display: none;
    }
  }

  a {
    color: colors.$black-400;
    padding: general.$spacing 0;
    display: block;
    position: relative;
    text-decoration: none;
    z-index: 1;
    width: 100%;

    &:hover {
      text-decoration: underline;
    }
  }
}

//rubriky

.widget_categories:not(.widget_categories_select) ul {

  li {
    display: flex;
    align-items: center;
  }
}

.widget_categories {

  form {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      right: general.$spacing * 2;
      border-style: solid;
      border-color: colors.$primary;
      border-width: 0 2px 2px 0;
      top: 50%;
      height: 12px;
      width: 12px;
      transform: translate(0, -50%) rotate(45deg);
      pointer-events: none;
    }
  }

  select {
    border: none;
    box-shadow: inset 0 0 0 1px colors.$gray-200;
    width: 100%;
    padding: general.$spacing;
    appearance: none;
    outline: none;
    background-color: colors.$white;

    &:active,
    &:focus {
      box-shadow: inset 0 0 0 1px colors.$primary;
    }
  }
}

.widget_search {

  form > div {
    display: flex;
    align-items: center;
  }

  #s {
    height: general.$spacing * 5;
    line-height: general.$spacing * 6;
    outline: 0;
    padding: 0 15px;
    width: 100%;
    border: none;
    box-shadow: inset 0 0 0 1px colors.$gray-200;
    appearance: none;
  }

  #searchsubmit {
    margin-left: 3px;
    min-width: 0;
    flex-shrink: 0;
    user-select: none;
    border: none;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease-out;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;

    // Styles thats will change by design
    font-size: 16px;
    color: colors.$white;
    border-radius: 6px;
    transition-property: background-color;
    line-height: general.$spacing * 3;
    padding: general.$spacing 15px;
    background-color: colors.$primary;
    z-index: 1;

    &:hover {
      text-decoration: none;
      color: colors.$white;
      background-color: colors.$primary-300;
    }

    &:focus,
    &:active {
      outline: 0;
      color: colors.$white;
      text-decoration: none;
    }
  }
}

.widget_custom_html {

  .custom-html-widget {
    max-height: 500px;
    overflow: scroll;
  }
}

//Tablet landscape - from 992px to 1199px

@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {

  .widget {
    padding: general.$spacing * 2;
  }
}

//Up to tablet portrait - up to 991px

@media (max-width: map-get($grid-breakpoints, lg) - 1px) {

  .aside-main {
    order: -2;
  }

  .widget {
    padding: general.$spacing * 2;
  }

  .widget_nav_menu,
  .widget_pages,
  .widget_categories {

    .widgettitle {
      position: relative;
      line-height: general.$spacing * 3;
      padding: 0;
      margin-bottom: general.$spacing;
      transform: none;

      &::before {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        border-style: solid;
        border-color: colors.$primary;
        border-width: 0 2px 2px 0;
        height: 12px;
        width: 12px;
        transform: translate(0, -50%) rotate(45deg);
        transition: transform 0.2s;
      }
    }

    .js-open {
      opacity: 1;
      height: auto;
      pointer-events: inherit;
      transition: opacity 0.2s, height 0.2s;

      &::before {
        transform: translate(0%, -50%) rotate(225deg);
      }
    }

    form.js-open {

      &::before {
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }

  .widget_categories ul,
  .widget_nav_menu > div > ul,
  .widget_pages > ul,
  .widget_categories form {
    opacity: 0;
    box-shadow: none;
    height: 0;
    pointer-events: none;
  }
}

//Up to phone landscape - up to 767px

@media (max-width: map-get($grid-breakpoints, md) - 1px) {

  .aside-main {
    padding-top: general.$spacing * 3;
    margin-bottom: general.$spacing * 3;
  }
}
/* stylelint-enable no-descending-specificity */
