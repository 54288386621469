@use "settings/s-spacing.scss" as spacing;

.content-w-aside__content {

  @include media-breakpoint-up(lg) {
    grid-column: 1/ span 8;
    grid-row: 1/span 3;
  }
}

.content-w-aside {

  .base-section {
    margin: spacing.$spacing-lg 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .content-w-aside__row {
    gap: 32px;

    @include media-breakpoint-up(lg) {
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(12, 1fr);
      grid-auto-rows: auto;
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
    }

    & > .content-w-aside__content:last-child {
      grid-column: 3 / span 8;
    }
  }

  .content-w-aside__aside {
    display: flex;
    flex-direction: column;
    row-gap: spacing.$spacing-lg;

    @include media-breakpoint-up(lg) {
      grid-column: span 3/ -1;
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: spacing.$spacing-lg;
    }

    &.--bottom {

      @include media-breakpoint-down(lg) {
        order: 1;
      }
    }
  }
}
