@use "settings/s-colors" as colors;

@use "settings/s-fonts" as fonts;

@use "settings/s-general" as general;

$borderRadius: general.$border-base;

$paddingY: 12px;
$paddingX: 32px;

$paddingLargeY: 12px;
$paddingLargeX: 48px;

$paddingMediumY: 16px;
$paddingMediumX: 16px;

$paddingSmallY: 8px;
$paddingSmallX: 10px;

$lineHeight: 32px;
$fontFamily: fonts.$primary;
$fontColor: colors.$white;
$fontWeight: 600;

$backgroundColor: colors.$conversion-400;
$backgroundColorHover: colors.$conversion-500;

$minWidth: 168px;

.btn__icon {

  svg,
  .img {
    height: 16px;
    width: 16px;
    transition: transform 0.3s ease;
  }
}

.btn {
  user-select: none;
  transition: background-color 0.3s ease-out;
  transition-property: background-color;
  line-height: $lineHeight;
  font-family: $fontFamily;
  color: $fontColor;
  font-weight: $fontWeight;
  border-radius: $borderRadius;
  padding: $paddingY $paddingX;
  border: none;
  z-index: 1;
  text-decoration: none;
  touch-action: manipulation;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    text-decoration: none;
    color: colors.$white;
  }

  &:focus,
  &:active {
    outline: 0;
    color: colors.$white;
    text-decoration: none;
  }

  &.--conversion {
    background-color: $backgroundColor;
    min-width: $minWidth;
    transition: box-shadow general.$transition-01;

    path {
      fill: $fontColor;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 32px);
      height: 100%;
      transform: translateX(-100%);
      background-color: $backgroundColorHover;
      clip-path: polygon(0 0, calc(100% - 32px) 0, 100% 100%, 0% 100%);
      transition: transform general.$transition-01;
      z-index: -1;
    }

    &:hover {
      color: $fontColor;
      box-shadow: general.$shadow-02;

      &::before {
        transform: translateX(0%);
      }
    }
  }

  /* stylelint-disable no-descending-specificity */

  &.--primary {
    background-color: colors.$primary-100;
    min-width: $minWidth;
    transition: background-color general.$transition-01;

    path {
      fill: colors.$primary-500;
    }

    &:hover {
      background-color: colors.$primary-500;
      color: colors.$white;

      path {
        fill: colors.$white;
      }
    }
  }

  &.--outline {
    background: transparent;
    box-shadow: inset 0 0 0 2px $backgroundColor;
    min-width: $minWidth;
    color: $backgroundColorHover;
    border: none;
    transition: background general.$transition-01;

    path {
      fill: $backgroundColor;
    }

    &:hover {
      background: $backgroundColor;
      color: colors.$white;

      path {
        fill: colors.$white;
      }
    }
  }

  &.--white {
    background-color: #ffffff90;

    &:hover {
      background-color: #fff;
    }
  }

  &.--center {
    width: max-content;
    display: flex;
    margin: 0 auto;
  }

  /* stylelint-enable no-descending-specificity */

  &.--animation-right {

    .btn__icon {

      svg,
      .img {
        transition-duration: 0.4s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
      }
    }

    &:hover {

      svg,
      .img {
        transform: translateX(5px);
      }
    }
  }

  &.--animation-left {

    .btn__icon {

      svg,
      .img {
        transition-duration: 0.4s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
      }
    }

    &:hover {

      svg,
      .img {
        transform: translateX(-5px);
      }
    }
  }

  &.--animation-zoom {

    &:hover {

      svg,
      .img {
        transform: scale(1.1);
      }
    }
  }

  &.--animation-rotate {

    &:hover {

      svg,
      .img {
        transform: rotate(45deg);
      }
    }
  }

  &.--small {
    padding: $paddingSmallY $paddingSmallX;
    min-width: 100px;
    font-size: 14px;
    line-height: 1;
  }

  &.--medium {
    padding: $paddingMediumY $paddingMediumX;
    min-width: 120px;
    line-height: 1;
  }

  &.--icon-before {

    .btn__icon {
      order: -1;
    }
  }

  &.--icon-only {
    width: 48px;
    height: 48px;
    min-width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .btn__title {
      display: none;
    }

    .btn__icon {
      height: 100%;

      svg,
      .img {
        width: 24px;
        height: 24px;
      }
    }

    &.--small {
      width: 32px;
      height: 32px;

      .btn__icon {

        svg,
        .img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &.--large {
    padding: $paddingLargeY $paddingLargeX;
    font-size: 16px;
  }

  &.--full-width-mobile {

    @include media-breakpoint-down(sm) {
      max-width: 360px;
      width: 100%;
    }
  }

  &.--circle {
    border-radius: 50%;
    min-width: auto;
  }

  &.--no-style {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }

  &.--full-width {
    width: 100%;
  }
}
