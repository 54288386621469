@use "settings/s-general" as general;

@use "settings/s-colors" as colors;

.i-catalog {
  background-color: colors.$white;
  box-shadow: general.$shadow-07;
  border-radius: general.$border-base;
  transition: box-shadow general.$transition-01;
  min-width: 0;

  .link {
    text-decoration: none;
    flex: 1 1 auto;

    @include media-breakpoint-up(md) {
      overflow: hidden;
    }
  }

  .link__title {

    @include media-breakpoint-down(md) {
      word-break: break-word;
    }

    @include media-breakpoint-up(md) {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .icon {
    flex: 0 0 auto;
  }

  path {
    transition: transform general.$transition-01;
  }

  .i-catalog__icon {
    flex: 0 0 auto;
  }

  &:hover {
    box-shadow: general.$shadow-08;

    .i-catalog__icon {

      path:first-child {
        transform: translateY(5px);
      }
    }
  }
}
