@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

.i-product-list {
  box-shadow: general.$shadow-border colors.$secondary-200;
  border-radius: general.$border-base;
  overflow: hidden;
  transition: general.$transition-01;

  &:hover {
    box-shadow: general.$shadow-05;
  }
}

.i-product-list__content {
  border-top: 4px solid colors.$primary-300;
  padding: 20px 0 24px;
  justify-content: space-between;
  row-gap: 20px;
}

.i-product-list__title {
  line-height: 24px;
  width: clamp(80%, 225px, 100%);
}

.i-product-list__price {

  .link {
    flex: 0 0 auto;
    align-self: flex-end;
  }
}
