@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

.footer-social {
  transition-property: text-decoration-color, color;
  text-decoration: underline;
  color: colors.$gray-200;
  text-decoration-color: transparent;

  rect,
  circle,
  path {
    transition: opacity general.$transition-01;
  }

  &:hover {

    rect,
    circle,
    path {
      opacity: 1;
    }
  }
}
