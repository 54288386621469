@use "settings/s-general.scss" as general;

@use "settings/s-typography.scss" as typo;

@use "settings/s-colors.scss" as colors;

main {
  padding-top: general.$spacing * 13;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    padding-top: general.$spacing * 10;
  }
}
