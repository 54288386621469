@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

.link__icon {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transition: transform 0.3s ease;
    will-change: transform;
  }
}

.link {
  display: inline-flex;
  transition: text-decoration-color 0.3s ease;
  transition-property: text-decoration-color, color;
  text-decoration: underline;
  align-items: center;
  column-gap: 8px;
  font-weight: 600;

  &.--black {
    color: colors.$black;
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: colors.$black;
      text-decoration-color: colors.$black;
    }
  }

  &.--primary {
    color: colors.$primary-500;
    text-decoration-color: currentcolor;

    &:hover,
    &:focus {
      text-decoration-color: transparent;
    }
  }

  &.--conversion {
    color: colors.$conversion;
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: colors.$conversion;
      text-decoration-color: colors.$conversion;
    }
  }

  &.--icon-before {

    .link__icon {
      order: -1;
    }
  }

  /* stylelint-disable no-descending-specificity */

  &.--animation-right {

    svg {
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }

    &:hover {

      svg {
        transform: translateX(8px);
      }
    }
  }

  &.--animation-zoom {

    &:hover {

      svg {
        transform: scale(1.1);
      }
    }
  }

  &.--animation-rotate {

    svg {
      transform: rotate(45deg);
    }

    &:hover {

      svg {
        transform: rotate(0);
      }
    }
  }

  /* stylelint-enable no-descending-specificity */

  &.--underline-initial {
    text-decoration-color: inherit;

    &:hover,
    &:focus {
      text-decoration-color: transparent;
    }
  }

  &.--invisible-title {
    text-decoration: none;

    .link__title {
      font-weight: 700;

      @include media-breakpoint-up(lg) {
        transition: opacity general.$transition-01;
        opacity: 0;
      }
    }

    &:hover {

      .link__title {

        @include media-breakpoint-up(lg) {
          opacity: 1;
        }
      }
    }
  }

  &.--full-size-click {

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  &.--hover-primary {

    &:hover {
      color: colors.$primary-500;
    }
  }
}
