@use "settings/s-spacing.scss" as spacing;

@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

.i-post-list-small {
  padding: spacing.$spacing-md 0;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid colors.$secondary-200;
  }

  &:last-child {

    &::after {
      display: none;
    }
  }
}

.i-post-list-small__image {
  flex: 0 0 88px;
  max-width: 30%;
}

.i-post-list-small__link {
  line-height: 24px;
  color: colors.$primary-800;
  text-decoration: underline;
  text-decoration-color: currentcolor;
  transition: text-decoration general.$transition-01;

  &:hover {
    color: colors.$primary-500;
    text-decoration-color: transparent;
  }
}
