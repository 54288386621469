.g-catalog {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;

  @include media-breakpoint-down(xl) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
  }
}
