@use "settings/s-colors.scss" as colors;

.i-counter-number {
  flex: 0 0 auto;
  width: clamp(25%, 328px, 100%);
}

.i-counter-number__number {
  font-size: 48px;
  line-height: 1;
  white-space: nowrap;
  color: colors.$primary-500;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 88px;
    height: 100%;
    border-top: 1px solid colors.$primary-300;
    border-bottom: 1px solid colors.$primary-300;
    top: 0;
    left: 50%;
    transform: translate(-50%);
  }
}

.i-counter-number__perex {
  width: clamp(75%, 272px, 100%);
}
