@use "settings/s-general" as general;

@use "settings/s-colors" as colors;

$border-radius: general.$border-base;

.f-select__icon {
  padding: 8px;
  position: absolute;
  pointer-events: none;
  transition: general.$transition-01;

  &.--after {
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  .dropdown-active + & {
    transform: translateY(-50%) rotate(-180deg);
  }
}

.f-select__info {
  position: absolute;
  right: 32px;
  top: calc(50% - 16px);

  .link {
    display: block;
  }
}

.f-select {
  border: 1px solid colors.$gray;
  border-radius: $border-radius;
  color: colors.$primary-500;

  &.--icon-after {

    .f-select__select {
      padding-right: 24px;
    }
  }

  .f-label {
    flex: 0 0 auto;
    padding: 12px 8px 12px 16px;
  }

  &.--error {
    border-color: colors.$danger;
    color: colors.$danger;
  }
}
