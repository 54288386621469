@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

.f-radio {

  input {
    height: 0;
    width: 0;
    opacity: 0;
    pointer-events: none;
  }

  label {
    position: relative;
    padding-left: 26px;
    padding-right: 0;
    font-size: 14px;
    font-weight: 700;
    color: colors.$primary-800;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      height: 16px;
      width: 16px;
      border-radius: 100%;
      border-style: solid;
      border-width: 1px;
      border-color: colors.$primary-500;
      background-color: colors.$primary-100;
      transform: translateY(-50%);
      transition: background-color 0.2s, border-color 0.2s;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 3px;
      height: 10px;
      width: 10px;
      border-radius: 100%;
      background-color: colors.$primary-500;
      transform: translateY(-50%);
      transition: opacity 0.2s, background-color 0.2s;
      opacity: 0;
    }

    &:hover {
      cursor: pointer;

      &::before {
        background-color: colors.$white;
      }
    }
  }

  input:checked + label {

    &::after {
      opacity: 1;
    }

    &::before {
      border-color: colors.$primary-500;
    }

    &:hover {

      &::before {
        background-color: colors.$white;
      }

      &::after {
        background-color: colors.$primary-500;
      }
    }
  }
}

.f-radio__info {

  .link {
    display: block;
  }
}
