@use "settings/s-bootstrap.scss" as bootstrap;

@use "settings/s-general.scss" as general;

@use "sass:map";

:root {
  --spacing-xl: #{general.$spacing * 14}; //112
  --spacing-lg: #{general.$spacing * 7};  //56
  --spacing-md: #{general.$spacing * 3};  //24
  --spacing-sm: #{general.$spacing * 2};  //16
  --spacing-lg-mobile: #{general.$spacing * 5}; //40
  --spacing-xl-mobile: #{general.$spacing * 10}; //80
  --spacing-row-overflow: #{general.$spacing * -5}; //-40
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;

  @media (max-width: 359px) {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }

  @media only screen and (max-width: map-get(bootstrap.$grid-breakpoints, lg)) {
    --spacing-xl: #{general.$spacing * 10}; //80
    --spacing-lg: #{general.$spacing * 5};  //40
    --spacing-md: #{general.$spacing * 2};  //16
    --spacing-sn: #{general.$spacing * 2};  //16
    --spacing-row-overflow: #{general.$spacing * -2}; //16
  }
}

$spacing-xl: var(--spacing-xl);
$spacing-lg: var(--spacing-lg);
$spacing-md: var(--spacing-md);
$spacing-sm: var(--spacing-sm);

$spacing-xl-mobile: var(--spacing-xl-mobile);
$spacing-lg-mobile: var(--spacing-lg-mobile);

//container padding
$container-padding: var(--spacing-container-padding);

//spacing row overflow
$row-overflow: var(--spacing-row-overflow);
$row-overflow-without-container: calc(#{$row-overflow} + #{$container-padding});
