@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

@use "settings/s-spacing.scss" as spacing;

.breadcrumbs {
  z-index: 50;
  padding: spacing.$spacing-lg 0;
  color: colors.$primary-500;

  span {
    line-height: general.$spacing * 3;
    display: inline;

    a {
      display: inline-block;
      position: relative;
      color: colors.$conversion-400;
      padding-right: general.$spacing * 2;
      margin-right: general.$spacing * 2;
      transition: text-decoration-color 0.2s ease;
      line-height: general.$spacing * 3;
      text-decoration: underline;
      text-decoration-color: transparent;

      &:hover {
        text-decoration-color: currentcolor;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 20px;
        right: 0;
        background-color: colors.$secondary-200;
        border-radius: 0;
      }
    }
  }

  // Breadcrumbs home

  & > span > span > a:first-child {
    user-select: none;

    .icon {
      position: relative;
      top: -4px;
      max-width: 20px;

      svg {
        width: auto;
        height: auto;
      }
    }

    path {
      fill: colors.$conversion-400;
    }
  }

  @include media-breakpoint-down(xl) {

    &.breadcrumbs-absolute {
      position: relative;
      top: 0;
    }
  }
}

// phone portrait - up to 768px

@media (max-width: map-get($grid-breakpoints, md) - 1px) {

  .breadcrumbs {
    padding-top: 0;
    padding-bottom: 0;
    display: none;
  }
}
