@use "settings/s-general" as general;

$divider-content: url("/images/svg/paper-cut.svg");
$divider-background: url("/images/svg/paper-cut-mask.svg");
$divider-background-height: 105px;
$divider-color: #ffff;

@mixin t-divider($top) {
  position: relative;

  &::after {
    content: $divider-content;
    position: absolute;
    display: block;
    width: 100%;
    top: $top;
    transform: translateY(-1px);
    left: 0;
  }
}

@mixin t-divider-background {
  background:
    $divider-background no-repeat bottom center,
    linear-gradient(0deg, #0000 0%, $divider-color $divider-background-height, $divider-color 100%);
  background-size: 100% auto;
}
