@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

.u-element-border {
  background: colors.$white;
  box-shadow: general.$shadow-05;
  border: 8px solid colors.$white;
  border-radius: general.$border-base;
  overflow: hidden;
}
