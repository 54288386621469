@use "settings/s-spacing.scss" as spacing;

@use "tools/t-flex-gap.scss" as flex-gap;

.g-icon-boxes {

  @include flex-gap.flex-column-wrap-gap(spacing.$spacing-xl-mobile);

  row-gap: spacing.$spacing-xl-mobile;
  margin-bottom: spacing.$spacing-lg;
}
