@use "settings/s-colors" as colors;

@use "settings/s-general.scss" as general;

.f-error__list {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  margin-top: 8px;
  padding: 12px 16px;
  right: 0;
  background: colors.$danger-bg;
  border: 1px solid colors.$danger;
  border-radius: general.$border-base;
  overflow: hidden;
  width: 100%;
  z-index: 100;

  @include media-breakpoint-down(lg) {
    position: static;
    opacity: 1;
  }
}

.f-error__item {
  color: colors.$danger;
  margin-bottom: 4px;
}

.f-error__icon {
  position: absolute;
  width: 16px;
  right: -16px;
  top: 25px;
  transform: translateY(-50%);
  pointer-events: auto;
  cursor: pointer;

  &:hover {

    + .f-error__list {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.f-error {

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
}
