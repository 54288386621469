@use "settings/s-colors.scss" as colors;

.i-footer-contact {

  .link {
    line-height: 32px;
  }
}

.i-footer-contact__person {
  text-align: left;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid colors.$secondary-200;
  }
}

.i-footer-contact__person-credentials {
  color: colors.$primary-500;
}

.i-footer-contact__person-name {
  font-weight: 700;
}

.i-footer-contact__person-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid colors.$primary-500;
  flex: 0 0 auto;
}
