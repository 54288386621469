@use "settings/s-colors" as colors;

.s-share-button {

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 1px;
    width: 100%;
    background: colors.$secondary-200;
  }

  &::after {
    top: 100%;
  }
}

.s-share-button__title {
  flex: 0 0 auto;
}
