@use "tools/t-divider" as divider;

@use "settings/s-spacing" as spacing;

.u-bottom-divider {

  @include divider.t-divider(100%);
}

.u-top-divider {

  @include divider.t-divider(0);
}

%u-section-bottom-divider {

  @include media-breakpoint-up(lg) {
    padding-bottom: spacing.$spacing-xl;

    @include divider.t-divider(100%);
  }
}

%u-section-top-divider {

  @include media-breakpoint-up(lg) {

    @include divider.t-divider(0);
  }
}

.u-section-bottom-divider {

  @extend %u-section-bottom-divider;
}

.u-section-top-divider {

  @extend %u-section-top-divider;
}
