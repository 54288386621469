@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

.f-wrap {

  .f-label {
    position: absolute;
    left: 8px;
    top: 12px;
    background-color: colors.$white;
    transition: general.$transition-03;
    border-radius: 16px;
    pointer-events: none;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input,
  textarea {

    &:focus-within,
    &:not(:placeholder-shown),
    + .validator {

      + .f-label {
        transform: translateY(-50%);
        top: 0;
        font-size: 12px;
      }
    }
  }
}
