.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

.u-fw-800 {
  font-weight: 800;
}

.u-fw-900 {
  font-weight: 900;
}
