.mobile-button {
  display: none;

  @include media-breakpoint-down(lg) {
    display: block;
  }

  .mobile-button__button-close {
    display: none;
  }

  &.--opened {

    .mobile-button__button-close {
      display: block;
    }

    .mobile-button__button-open {
      display: none;
    }
  }
}
