@use "settings/s-general" as general;

@use "settings/s-colors" as colors;

$padding-x: 16px;
$padding-y: 12px;

$border-radius: general.$border-base;
$font-size: 16px;

$background: colors.$white;
$color: colors.$black;
$color-placeholder: colors.$gray;
$color-invalid: colors.$danger;

$text-area-min-height: 152px;

.f-base {
  display: block;
  width: 100%;
  color: $color;
  background-color: $background;
  background-clip: padding-box;
  padding: $padding-y $padding-x;
  border-radius: $border-radius;
  font-size: $font-size;
  appearance: none;
  border: none;

  &:focus {
    color: $color;
    border-color: none;
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    color: $color-placeholder;
  }
}

textarea.f-base {
  min-height: $text-area-min-height;
  resize: vertical;
}
