@use "settings/s-general" as general;

@use "settings/s-colors" as colors;

$border-radius: general.$border-base;

.f-input__icon {
  padding: 8px;
  position: absolute;
  pointer-events: none;

  &.--before {
    padding-left: 16px;
    left: 0;
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  &.--after {
    padding-right: 16px;
    right: 0;
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
}

.f-input__info {
  right: 8px;
  top: calc(50% - 16px);

  .link {
    display: block;
  }
}

.f-input {
  border: 1px solid colors.$gray;
  color: colors.$primary-500;
  border-radius: $border-radius;

  &:focus-within {
    border-color: colors.$focus;
    color: colors.$focus;
  }

  &.--icon-before {

    .f-input__input {
      padding-left: 48px;
    }
  }

  &.--icon-after {

    .f-input__input {
      padding-right: 48px;
    }
  }

  &.--error {
    border-color: colors.$danger;
    color: colors.$danger;
  }
}
