div.validator {
  height: 0;
  position: absolute;
  top: 100%;

  > span.erorr-s {
    position: absolute;
    padding: 4px 8px;
    display: block;
    left: 0;
    top: 4px;
    white-space: nowrap;
    border-radius: 8px;
    color: #fff;
    letter-spacing: -1px;
    font-size: 14px;
    background: #c92a2a;
    z-index: 99;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: -5px;
      left: 10px;
      border-style: solid;
      border-width: 0 5px 10px;
      border-color: transparent transparent #c92a2a;
    }
  }
}
