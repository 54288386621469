.s-posts-grid__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 64px;

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-posts-grid__grid-cell {

  &:first-of-type {

    @include media-breakpoint-up(xl) {
      grid-row: span 3 / auto;
    }
  }
}
