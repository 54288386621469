@use "settings/s-colors" as colors;

$size-xs: 10px;
$size-sm: 14px;
$size-md: 16px;
$size-lg: 24px;
$size-xl: 48px;

.icon {
  color: colors.$black;
  flex: 0 0 $size-md;
  max-width: $size-md;
  height: $size-md;

  svg {
    width: 100%;
    height: 100%;
  }

  &.--primary {
    color: colors.$primary;

    path {
      fill: colors.$primary;
    }
  }

  &.--white {
    color: colors.$white;

    path {
      fill: colors.$white;
    }
  }

  &.--black {
    color: colors.$black;

    path {
      fill: colors.$black;
    }
  }

  &.--conversion {
    color: colors.$conversion;

    path {
      fill: colors.$conversion;
    }
  }

  &.--lg {
    flex: 0 0 $size-lg;
    max-width: $size-lg;
    height: $size-lg;
  }

  &.--sm {
    flex: 0 0 $size-sm;
    max-width: $size-sm;
    height: $size-sm;
  }

  &.--xs {
    flex: 0 0 $size-xs;
    max-width: $size-xs;
    height: $size-xs;
  }

  &.--xl {
    flex: 0 0 $size-xl;
    max-width: $size-xl;
    height: $size-xl;
  }

  &.--full {
    flex: 0 0 auto;
    max-width: max-content;
    height: max-content;

    svg {
      width: auto;
      height: auto;
    }
  }
}
