@use "settings/s-spacing.scss" as spacing;

@use "settings/s-colors.scss" as colors;

.s-breadcrumbs {
  padding-top: spacing.$spacing-lg;
  margin-bottom: spacing.$spacing-xl-mobile;

  @include media-breakpoint-down(lg) {
    margin-bottom: spacing.$spacing-lg;
  }
}

.s-breadcrumbs__item {
  color: colors.$primary-500;

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-right: 1px solid colors.$gray;
    height: 20px;
    right: 0;
    top: 0;
  }

  &:last-child {

    &::after {
      display: none;
    }
  }
}
