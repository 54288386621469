@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

.entry-content {

  p {
    color: colors.$black-400;
    margin-bottom: general.$spacing * 3;

    a {
      display: inline;
    }
  }

  .base-header__paragraph {

    &.mb-0 {
      margin-bottom: 0;
    }
  }
}
