@use "settings/s-colors" as colors;

.i-graphic-detail {
  position: absolute;
  pointer-events: none;

  .icon {
    max-width: 100%;
    width: max-content;
    height: auto;
  }

  svg {
    width: auto;
    height: auto;

    @include media-breakpoint-down(md) {
      max-width: 50%;
    }
  }

  path {
    fill: colors.$secondary-600;
  }

  /* vertical align */

  &.--v-top {
    top: 0;
  }

  &.--v-bottom {
    top: 100%;
  }

  &.--v-center {
    top: 50%;
  }

  /* horizontal align */

  &.--h-right {
    left: 100%;
  }

  &.--h-left {
    left: 0;
  }

  &.--h-center {
    left: 50%;
  }

  /* vertical self align */

  &.--vs-top {
    transform: translateY(0);
  }

  &.--vs-bottom {
    transform: translateY(-100%);
  }

  &.--vs-center {
    transform: translateY(-50%);
  }

  /* horizontal self align */

  &.--hs-right {

    .i-graphic-detail__in {
      transform: translateX(0);
    }
  }

  &.--hs-left {

    .i-graphic-detail__in {
      transform: translateX(-100%);
    }
  }

  &.--hs-center {

    .i-graphic-detail__in {
      transform: translateX(-50%);
    }
  }
}
