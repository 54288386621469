@use "settings/s-general" as general;

@use "settings/s-colors" as colors;

@use "settings/s-spacing.scss" as spacing;

.s-info {
  margin: spacing.$spacing-md 0 spacing.$spacing-lg;
}

.s-info__block {
  border-radius: general.$border-base;
  background: colors.$primary-100;

  @include media-breakpoint-down(md) {
    padding: 16px;
    flex-direction: column;
  }
}

.s-info__perex {
  margin-bottom: 0;
  padding: 16px 0;

  @include media-breakpoint-up(md) {
    flex-direction: column;
    margin-left: 32px;
    padding-left: 32px;
    border-left: 1px solid colors.$secondary-200;
  }
}
