.u-fz-12 {
  font-size: 12px;
}

.u-fz-14 {
  font-size: 14px;
}

.u-fz-16 {
  font-size: 16px;
}

.u-fz-18 {
  font-size: 18px;
}

.u-fz-20 {
  font-size: 20px;
}

.u-fz-24 {
  font-size: 24px;
}
