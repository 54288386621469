.s-posts__grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  column-gap: 32px;

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(2, 1fr);
  }
}
