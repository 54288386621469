.swiper.swiper-horizontal {
  overflow: visible;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

.swiper-slide-visible {
  opacity: 1;
}

.swiper-buttons {
  position: absolute;
  width: calc(100% + 166px);
  max-width: 100vw;
  padding: 0 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  pointer-events: none;
}

.swiper-button {
  pointer-events: auto;

  .icon {
    flex: 0 0 auto;
    height: auto;
    max-width: none;
  }
}

.swiper-button-prev {
  float: left;
}

.swiper-button-next {
  float: right;
}

.swiper-button-disabled {
  opacity: 0;
}
