//grid spacing
$spacing: 8px;

$border-base: 8px;
$border-base-small: 4px;

//box-shadow
$shadow-01: 0 8px 32px #1d1d1b1a;
$shadow-02: 0 10px 10px #ee6c724d;
$shadow-03: 5px 5px 30px #383d3d4d;
$shadow-04: 0 0 5px #383d3d4d;
$shadow-05: 5px 5px 15px #bebebe7f;
$shadow-06: 5px 5px 20px #bebebe7f;
$shadow-07: 3px 3px 6px #383d3d4d;
$shadow-08: 3px 3px 15px #383d3d4d;
$shadow-border: 0 0 0 1px;
$shadow-empty: 0 0 0 #bebebe;

//transitions
$transition-01: 0.2s ease;
$transition-02: 0.4s ease;
$transition-03: 0.1s ease;
