@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

.i-category-cards__content {
  padding: 16px 8px 8px 16px;
  flex: 1 0 auto;
  justify-content: space-between;

  .link {
    align-self: flex-end;
  }

  .base-heading {
    padding-right: 80px;
    transition: general.$transition-01;
  }
}

.i-category-cards {
  overflow: hidden;
  border-radius: general.$border-base;
  box-shadow: general.$shadow-border colors.$secondary-200;
  transition: box-shadow general.$transition-01;

  &:hover {
    box-shadow: general.$shadow-05;

    .base-heading {
      color: colors.$primary-500;
    }
  }
}

.i-category-cards__image {
  aspect-ratio: 4 / 3;
  position: relative;
  flex: 0 0 auto;

  .icon {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    right: 16px;
  }

  .img,
  img {
    height: 100%;
    border-radius: 0;
  }
}
