@use "settings/s-general" as general;

@use "settings/s-colors" as colors;

@use "settings/s-spacing" as spacing;

.s-post-detail__meta {
  color: colors.$primary-500;
  gap: general.$spacing * 2 general.$spacing * 4;
}

.s-post-detail__meta-left {
  gap: 36px;

  @include media-breakpoint-down(sm) {
    gap: 16px;
  }
}

.s-post-detail {
  margin-bottom: spacing.$spacing-lg;
}

.s-post-detail__intro {

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    border-top: 1px solid colors.$gray;
  }
}

.s-post-detail__header {
  margin-bottom: 0;
}
