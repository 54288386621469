$primary-100: var(--primary-100);
$primary-300: var(--primary-300);
$primary-500: var(--primary-500);
$primary-800: var(--primary-800);
$primary: $primary-300;

$secondary-200: var(--secondary-200);
$secondary-300: var(--secondary-300);
$secondary-500: var(--secondary-500);
$secondary-600: var(--secondary-600);

$conversion-400: var(--conversion-400);
$conversion-500: var(--conversion-500);
$conversion: $conversion-500;

$gray-200: $secondary-200;
$gray: $gray-200;

$white: #fff;

$black: $primary-800;
$black-400: $primary-800;

$background: $primary-100;

$danger: #e21c3d; //default C500
$danger-bg: #f9dede;

$focus: $primary-500;

$success: #48a463;
$success-bg: #e7f4e4;
