.f-search-form {

  .f-wrap {
    flex: 1;
  }

  .btn {
    flex: 0 0 auto;
    padding: 8px 24px;

    @include media-breakpoint-down(lg) {
      min-width: auto;
    }
  }
}
