@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

.main-header__container {

  @include media-breakpoint-down(lg) {
    align-items: center;
    min-height: 64px;
  }
}

.main-header__content {
  width: 70%;

  &::before {
    content: "";
    display: none;
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    border-top: 1px solid colors.$gray;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 8px;
  }
}

.main-header__nav {
  display: flex;
  align-items: center;
}

.main-header__logo {

  img {
    max-width: 232px;
    max-height: 72px;

    @include media-breakpoint-down(md) {
      max-width: 160px;
      max-height: 56px;
    }
  }
}

.main-header {
  min-height: 112px;
  background-color: colors.$white;
  transition: general.$transition-02;
  transition-property: padding-bottom, padding-top, box-shadow, transform;
  z-index: 150;

  &.--search-open {

    .main-header__nav {
      opacity: 0;
      pointer-events: none;
      transition: general.$transition-01;
    }
  }

  &.headroom--pinned {
    transform: translateY(0%);
  }

  &.headroom--unpinned {
    box-shadow: none;

    @include media-breakpoint-up(lg) {
      transform: translateY(-100%);
    }
  }

  &.headroom--not-top {
    padding-top: 0;
    padding-bottom: 0;
    min-height: auto;
    box-shadow: general.$shadow-04;

    .main-header__content {
      padding-top: 0;
    }

    .main-header__logo {

      img {
        max-height: 64px;
        padding: 8px 0;
      }
    }
  }
}
