@use "settings/s-general.scss" as general;

//Text
//Main heading
$main-heading-font-size: 48px;
$main-heading-line-height: general.$spacing * 7;

//Base heading
$base-heading-font-size: 40px;
$base-heading-line-height: general.$spacing * 6;

//Base subheading
$base-subheading-font-size: 32px;
$base-subheading-line-height: general.$spacing * 5;

//Article heading
$article-heading-font-size: 24px;
$article-heading-line-height: general.$spacing * 4;

//small heading
$small-heading-font-size: 20px;
$small-heading-offset: 5px;

//large text
$large-text-font-size: 16px;
$large-text-offset: 6px;

//Base text
$base-text-font-size: 16px;
$base-text-offset: 6px;

//Small text
$small-text-font-size: 14px;
$small-text-offset: 0;
