@use "settings/s-general" as general;

@use "settings/s-colors" as colors;

.i-search-result {
  max-width: 80ch;
}

.i-search-result__heading {
  margin-bottom: general.$spacing * 2;
}

.i-search-result__heading-link {
  color: colors.$black;
  text-decoration-color: currentcolor;

  &:hover {
    color: colors.$primary-500;
    text-decoration-color: transparent;
  }
}

.i-search-result__link {
  margin-bottom: general.$spacing;
  text-decoration-color: transparent;
  transition-property: color, text-decoration-color;

  &:hover {
    color: colors.$primary;
    text-decoration-color: colors.$primary;
  }
}
