@use "settings/s-colors" as colors;

@use "settings/s-general" as general;

$error-font-size: general.$spacing * 22;
$error-line-height: general.$spacing * 22;

$error-font-size-sm: general.$spacing * 17;
$error-line-height-sm: general.$spacing * 17;

.s-error-404 {
  text-align: center;
}

.s-error-404__heading {
  display: block;
  font-size: $error-font-size;
  line-height: $error-line-height;
  color: colors.$primary-500;

  @include media-breakpoint-down(md) {
    font-size: $error-font-size-sm;
    line-height: $error-line-height-sm;
  }
}

.s-error-404__sub-heading {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: general.$spacing * 3;
  max-width: 500px;
}

.s-error-404__perex {
  max-width: 500px;
  font-weight: 400;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
