@use "settings/s-general.scss" as general;

@use "settings/s-fonts.scss" as fonts;

@use "settings/s-typography.scss" as typo;

@use "settings/s-colors.scss" as colors;

.entry-content {

  @include media-breakpoint-down(sm) {
    margin-top: general.$spacing * 2;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: general.$spacing * 6;
    color: colors.$black-400;
    font-family: fonts.$primary;
    font-weight: bold;
    margin-bottom: general.$spacing * 2;

    @include media-breakpoint-down(sm) {
      margin-bottom: general.$spacing * 3 !important;
    }
  }

  h1 {
    margin-top: 0;
    position: relative;
  }

  h2 {
    position: relative;
  }

  & > h2:first-child,
  & > h3:first-child {
    margin-top: 0;
  }

  h5,
  h6 {
    font-size: typo.$base-text-font-size;
    line-height: general.$spacing * 3;
  }

  .base-heading {
    margin-top: 0;
  }

  .i-product-list__title {
    margin: 0;
  }
}
