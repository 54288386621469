@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

$placeholderColor: colors.$gray-200;

.i-post__image-col {
  grid-area: image;
}

.i-post__content-col {
  grid-area: content;
}

.i-post__heading {
  line-height: 24px;
}

.i-post__perex {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.i-post__heading-link {
  text-decoration-color: transparent;
  color: colors.$black;
  overflow: hidden;

  &:hover {
    color: colors.$primary-500;
    text-decoration-color: transparent;
  }
}

.i-post-image-link {
  border-radius: $borderRadius;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $placeholderColor;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 16px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $borderRadius;
    transform: scale(1);
    transition-property: opacity, transform;

    &[data-src]:not(.loaded) {
      opacity: 0;
      transform: scale(0.95);
    }
  }
}

.i-post__date {
  color: colors.$primary-500;
}

.i-post__author {
  color: colors.$primary-500;
}

.i-post {
  display: grid;
  grid-template-columns: 240px auto;
  grid-template-areas: "image content";

  @include media-breakpoint-down(md) {
    grid-template-columns: 192px auto;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    max-width: 336px;
    margin-left: auto;
    margin-right: auto;
  }

  &.--column {

    @include media-breakpoint-up(xl) {
      height: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      .i-post__content-col {
        height: 100%;
      }

      .i-post__heading {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  &.--bordered {
    padding: 16px;
    border-radius: general.$border-base;
    box-shadow: general.$shadow-border colors.$secondary-200;
  }
}
