@use "settings/s-general.scss" as general;

@use "settings/s-typography.scss" as typo;

@use "settings/s-colors.scss" as colors;

.entry-content {

  blockquote {
    font-size: typo.$base-text-font-size;
    font-weight: 400;
    padding: 32px 24px 24px;
    margin: general.$spacing * 6 0;
    border-radius: general.$border-base;
    position: relative;
    font-style: italic;
    color: colors.$secondary-600;
    background-color: colors.$secondary-300;

    &::before {
      content: url("/images/svg/quotes.svg");
      display: block;
      position: absolute;
      top: 4px;
      transform: translateY(-50%);
    }

    p {
      font-style: italic;
      margin-bottom: 0;
    }
  }
}
