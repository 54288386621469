@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 16px 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 16px;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -16px;
  margin-bottom: 16px;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 32px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1500px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1460px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1500px;
  --bs-breakpoint-fullhd: 1920px;
}

.row {
  --bs-gutter-x: 32px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 16px;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 16px;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 24px;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 24px;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 32px;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 32px;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 40px;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 40px;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 48px;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 48px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 16px;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 16px;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 24px;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 24px;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 32px;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 32px;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 40px;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 40px;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 48px;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 48px;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 16px;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 16px;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 24px;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 24px;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 32px;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 32px;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 40px;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 40px;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 48px;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 48px;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 16px;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 16px;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 24px;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 24px;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 32px;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 32px;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 40px;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 40px;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 48px;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 48px;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 16px;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 16px;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 24px;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 24px;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 32px;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 32px;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 40px;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 40px;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 48px;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 48px;
  }
}
@media (min-width: 1500px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 16px;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 16px;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 24px;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 24px;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 32px;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 32px;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 40px;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 40px;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 48px;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 48px;
  }
}
@media (min-width: 1920px) {
  .col-fullhd {
    flex: 1 0 0%;
  }
  .row-cols-fullhd-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-fullhd-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-fullhd-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-fullhd-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-fullhd-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-fullhd-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-fullhd-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-fullhd-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-fullhd-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-fullhd-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-fullhd-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-fullhd-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-fullhd-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-fullhd-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-fullhd-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-fullhd-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-fullhd-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-fullhd-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-fullhd-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-fullhd-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-fullhd-0 {
    margin-left: 0;
  }
  .offset-fullhd-1 {
    margin-left: 8.33333333%;
  }
  .offset-fullhd-2 {
    margin-left: 16.66666667%;
  }
  .offset-fullhd-3 {
    margin-left: 25%;
  }
  .offset-fullhd-4 {
    margin-left: 33.33333333%;
  }
  .offset-fullhd-5 {
    margin-left: 41.66666667%;
  }
  .offset-fullhd-6 {
    margin-left: 50%;
  }
  .offset-fullhd-7 {
    margin-left: 58.33333333%;
  }
  .offset-fullhd-8 {
    margin-left: 66.66666667%;
  }
  .offset-fullhd-9 {
    margin-left: 75%;
  }
  .offset-fullhd-10 {
    margin-left: 83.33333333%;
  }
  .offset-fullhd-11 {
    margin-left: 91.66666667%;
  }
  .g-fullhd-0,
  .gx-fullhd-0 {
    --bs-gutter-x: 0;
  }
  .g-fullhd-0,
  .gy-fullhd-0 {
    --bs-gutter-y: 0;
  }
  .g-fullhd-1,
  .gx-fullhd-1 {
    --bs-gutter-x: 16px;
  }
  .g-fullhd-1,
  .gy-fullhd-1 {
    --bs-gutter-y: 16px;
  }
  .g-fullhd-2,
  .gx-fullhd-2 {
    --bs-gutter-x: 24px;
  }
  .g-fullhd-2,
  .gy-fullhd-2 {
    --bs-gutter-y: 24px;
  }
  .g-fullhd-3,
  .gx-fullhd-3 {
    --bs-gutter-x: 32px;
  }
  .g-fullhd-3,
  .gy-fullhd-3 {
    --bs-gutter-y: 32px;
  }
  .g-fullhd-4,
  .gx-fullhd-4 {
    --bs-gutter-x: 40px;
  }
  .g-fullhd-4,
  .gy-fullhd-4 {
    --bs-gutter-y: 40px;
  }
  .g-fullhd-5,
  .gx-fullhd-5 {
    --bs-gutter-x: 48px;
  }
  .g-fullhd-5,
  .gy-fullhd-5 {
    --bs-gutter-y: 48px;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1));
}

.text-bg-secondary {
  color: #fff;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1));
}

.text-bg-success {
  color: #fff;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1));
}

.text-bg-info {
  color: #000;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1));
}

.text-bg-warning {
  color: #000;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1));
}

.text-bg-danger {
  color: #fff;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1));
}

.text-bg-light {
  color: #000;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1));
}

.text-bg-dark {
  color: #fff;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1));
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1));
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1));
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1));
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1));
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1));
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1));
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1));
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1));
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1));
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1));
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1));
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1));
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1));
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1));
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1));
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1));
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1));
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75));
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75));
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1500px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1920px) {
  .sticky-fullhd-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-fullhd-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}

.float-none {
  float: none;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-fill {
  object-fit: fill;
}

.object-fit-scale {
  object-fit: scale-down;
}

.object-fit-none {
  object-fit: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-inline-grid {
  display: inline-grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-none {
  box-shadow: none;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-50 {
  bottom: 50%;
}

.bottom-100 {
  bottom: 100%;
}

.start-0 {
  left: 0;
}

.start-50 {
  left: 50%;
}

.start-100 {
  left: 100%;
}

.end-0 {
  right: 0;
}

.end-50 {
  right: 50%;
}

.end-100 {
  right: 100%;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.translate-middle-x {
  transform: translateX(-50%);
}

.translate-middle-y {
  transform: translateY(-50%);
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-0 {
  border: 0;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-top-0 {
  border-top: 0;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-end-0 {
  border-right: 0;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-start-0 {
  border-left: 0;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity));
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity));
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity));
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity));
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity));
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity));
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity));
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity));
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity));
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity));
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle);
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle);
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle);
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle);
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle);
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle);
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle);
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle);
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.vw-100 {
  width: 100vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-100 {
  max-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.order-first {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-last {
  order: 6;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 8px;
}

.m-2 {
  margin: 16px;
}

.m-3 {
  margin: 24px;
}

.m-4 {
  margin: 32px;
}

.m-5 {
  margin: 40px;
}

.m-6 {
  margin: 48px;
}

.m-7 {
  margin: 56px;
}

.m-8 {
  margin: 64px;
}

.m-9 {
  margin: 72px;
}

.m-10 {
  margin: 80px;
}

.m-11 {
  margin: 88px;
}

.m-12 {
  margin: 96px;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-1 {
  margin-right: 8px;
  margin-left: 8px;
}

.mx-2 {
  margin-right: 16px;
  margin-left: 16px;
}

.mx-3 {
  margin-right: 24px;
  margin-left: 24px;
}

.mx-4 {
  margin-right: 32px;
  margin-left: 32px;
}

.mx-5 {
  margin-right: 40px;
  margin-left: 40px;
}

.mx-6 {
  margin-right: 48px;
  margin-left: 48px;
}

.mx-7 {
  margin-right: 56px;
  margin-left: 56px;
}

.mx-8 {
  margin-right: 64px;
  margin-left: 64px;
}

.mx-9 {
  margin-right: 72px;
  margin-left: 72px;
}

.mx-10 {
  margin-right: 80px;
  margin-left: 80px;
}

.mx-11 {
  margin-right: 88px;
  margin-left: 88px;
}

.mx-12 {
  margin-right: 96px;
  margin-left: 96px;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-3 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-4 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.my-5 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-6 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.my-7 {
  margin-top: 56px;
  margin-bottom: 56px;
}

.my-8 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.my-9 {
  margin-top: 72px;
  margin-bottom: 72px;
}

.my-10 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.my-11 {
  margin-top: 88px;
  margin-bottom: 88px;
}

.my-12 {
  margin-top: 96px;
  margin-bottom: 96px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.mt-5 {
  margin-top: 40px;
}

.mt-6 {
  margin-top: 48px;
}

.mt-7 {
  margin-top: 56px;
}

.mt-8 {
  margin-top: 64px;
}

.mt-9 {
  margin-top: 72px;
}

.mt-10 {
  margin-top: 80px;
}

.mt-11 {
  margin-top: 88px;
}

.mt-12 {
  margin-top: 96px;
}

.mt-auto {
  margin-top: auto;
}

.me-0 {
  margin-right: 0;
}

.me-1 {
  margin-right: 8px;
}

.me-2 {
  margin-right: 16px;
}

.me-3 {
  margin-right: 24px;
}

.me-4 {
  margin-right: 32px;
}

.me-5 {
  margin-right: 40px;
}

.me-6 {
  margin-right: 48px;
}

.me-7 {
  margin-right: 56px;
}

.me-8 {
  margin-right: 64px;
}

.me-9 {
  margin-right: 72px;
}

.me-10 {
  margin-right: 80px;
}

.me-11 {
  margin-right: 88px;
}

.me-12 {
  margin-right: 96px;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mb-4 {
  margin-bottom: 32px;
}

.mb-5 {
  margin-bottom: 40px;
}

.mb-6 {
  margin-bottom: 48px;
}

.mb-7 {
  margin-bottom: 56px;
}

.mb-8 {
  margin-bottom: 64px;
}

.mb-9 {
  margin-bottom: 72px;
}

.mb-10 {
  margin-bottom: 80px;
}

.mb-11 {
  margin-bottom: 88px;
}

.mb-12 {
  margin-bottom: 96px;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: 8px;
}

.ms-2 {
  margin-left: 16px;
}

.ms-3 {
  margin-left: 24px;
}

.ms-4 {
  margin-left: 32px;
}

.ms-5 {
  margin-left: 40px;
}

.ms-6 {
  margin-left: 48px;
}

.ms-7 {
  margin-left: 56px;
}

.ms-8 {
  margin-left: 64px;
}

.ms-9 {
  margin-left: 72px;
}

.ms-10 {
  margin-left: 80px;
}

.ms-11 {
  margin-left: 88px;
}

.ms-12 {
  margin-left: 96px;
}

.ms-auto {
  margin-left: auto;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 8px;
}

.p-2 {
  padding: 16px;
}

.p-3 {
  padding: 24px;
}

.p-4 {
  padding: 32px;
}

.p-5 {
  padding: 40px;
}

.p-6 {
  padding: 48px;
}

.p-7 {
  padding: 56px;
}

.p-8 {
  padding: 64px;
}

.p-9 {
  padding: 72px;
}

.p-10 {
  padding: 80px;
}

.p-11 {
  padding: 88px;
}

.p-12 {
  padding: 96px;
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-1 {
  padding-right: 8px;
  padding-left: 8px;
}

.px-2 {
  padding-right: 16px;
  padding-left: 16px;
}

.px-3 {
  padding-right: 24px;
  padding-left: 24px;
}

.px-4 {
  padding-right: 32px;
  padding-left: 32px;
}

.px-5 {
  padding-right: 40px;
  padding-left: 40px;
}

.px-6 {
  padding-right: 48px;
  padding-left: 48px;
}

.px-7 {
  padding-right: 56px;
  padding-left: 56px;
}

.px-8 {
  padding-right: 64px;
  padding-left: 64px;
}

.px-9 {
  padding-right: 72px;
  padding-left: 72px;
}

.px-10 {
  padding-right: 80px;
  padding-left: 80px;
}

.px-11 {
  padding-right: 88px;
  padding-left: 88px;
}

.px-12 {
  padding-right: 96px;
  padding-left: 96px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-4 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py-5 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-6 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.py-7 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.py-8 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.py-9 {
  padding-top: 72px;
  padding-bottom: 72px;
}

.py-10 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.py-11 {
  padding-top: 88px;
  padding-bottom: 88px;
}

.py-12 {
  padding-top: 96px;
  padding-bottom: 96px;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 8px;
}

.pt-2 {
  padding-top: 16px;
}

.pt-3 {
  padding-top: 24px;
}

.pt-4 {
  padding-top: 32px;
}

.pt-5 {
  padding-top: 40px;
}

.pt-6 {
  padding-top: 48px;
}

.pt-7 {
  padding-top: 56px;
}

.pt-8 {
  padding-top: 64px;
}

.pt-9 {
  padding-top: 72px;
}

.pt-10 {
  padding-top: 80px;
}

.pt-11 {
  padding-top: 88px;
}

.pt-12 {
  padding-top: 96px;
}

.pe-0 {
  padding-right: 0;
}

.pe-1 {
  padding-right: 8px;
}

.pe-2 {
  padding-right: 16px;
}

.pe-3 {
  padding-right: 24px;
}

.pe-4 {
  padding-right: 32px;
}

.pe-5 {
  padding-right: 40px;
}

.pe-6 {
  padding-right: 48px;
}

.pe-7 {
  padding-right: 56px;
}

.pe-8 {
  padding-right: 64px;
}

.pe-9 {
  padding-right: 72px;
}

.pe-10 {
  padding-right: 80px;
}

.pe-11 {
  padding-right: 88px;
}

.pe-12 {
  padding-right: 96px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 8px;
}

.pb-2 {
  padding-bottom: 16px;
}

.pb-3 {
  padding-bottom: 24px;
}

.pb-4 {
  padding-bottom: 32px;
}

.pb-5 {
  padding-bottom: 40px;
}

.pb-6 {
  padding-bottom: 48px;
}

.pb-7 {
  padding-bottom: 56px;
}

.pb-8 {
  padding-bottom: 64px;
}

.pb-9 {
  padding-bottom: 72px;
}

.pb-10 {
  padding-bottom: 80px;
}

.pb-11 {
  padding-bottom: 88px;
}

.pb-12 {
  padding-bottom: 96px;
}

.ps-0 {
  padding-left: 0;
}

.ps-1 {
  padding-left: 8px;
}

.ps-2 {
  padding-left: 16px;
}

.ps-3 {
  padding-left: 24px;
}

.ps-4 {
  padding-left: 32px;
}

.ps-5 {
  padding-left: 40px;
}

.ps-6 {
  padding-left: 48px;
}

.ps-7 {
  padding-left: 56px;
}

.ps-8 {
  padding-left: 64px;
}

.ps-9 {
  padding-left: 72px;
}

.ps-10 {
  padding-left: 80px;
}

.ps-11 {
  padding-left: 88px;
}

.ps-12 {
  padding-left: 96px;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 8px;
}

.gap-2 {
  gap: 16px;
}

.gap-3 {
  gap: 24px;
}

.gap-4 {
  gap: 32px;
}

.gap-5 {
  gap: 40px;
}

.gap-6 {
  gap: 48px;
}

.gap-7 {
  gap: 56px;
}

.gap-8 {
  gap: 64px;
}

.gap-9 {
  gap: 72px;
}

.gap-10 {
  gap: 80px;
}

.gap-11 {
  gap: 88px;
}

.gap-12 {
  gap: 96px;
}

.row-gap-0 {
  row-gap: 0;
}

.row-gap-1 {
  row-gap: 8px;
}

.row-gap-2 {
  row-gap: 16px;
}

.row-gap-3 {
  row-gap: 24px;
}

.row-gap-4 {
  row-gap: 32px;
}

.row-gap-5 {
  row-gap: 40px;
}

.row-gap-6 {
  row-gap: 48px;
}

.row-gap-7 {
  row-gap: 56px;
}

.row-gap-8 {
  row-gap: 64px;
}

.row-gap-9 {
  row-gap: 72px;
}

.row-gap-10 {
  row-gap: 80px;
}

.row-gap-11 {
  row-gap: 88px;
}

.row-gap-12 {
  row-gap: 96px;
}

.column-gap-0 {
  column-gap: 0;
}

.column-gap-1 {
  column-gap: 8px;
}

.column-gap-2 {
  column-gap: 16px;
}

.column-gap-3 {
  column-gap: 24px;
}

.column-gap-4 {
  column-gap: 32px;
}

.column-gap-5 {
  column-gap: 40px;
}

.column-gap-6 {
  column-gap: 48px;
}

.column-gap-7 {
  column-gap: 56px;
}

.column-gap-8 {
  column-gap: 64px;
}

.column-gap-9 {
  column-gap: 72px;
}

.column-gap-10 {
  column-gap: 80px;
}

.column-gap-11 {
  column-gap: 88px;
}

.column-gap-12 {
  column-gap: 96px;
}

.font-monospace {
  font-family: var(--bs-font-monospace);
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw);
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw);
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw);
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw);
}

.fs-5 {
  font-size: 1.25rem;
}

.fs-6 {
  font-size: 1rem;
}

.fst-italic {
  font-style: italic;
}

.fst-normal {
  font-style: normal;
}

.fw-lighter {
  font-weight: lighter;
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-bolder {
  font-weight: bolder;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.25;
}

.lh-base {
  line-height: 1.5;
}

.lh-lg {
  line-height: 2;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity));
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity));
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity));
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity));
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity));
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity));
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity));
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity));
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color);
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color);
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color);
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color);
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis);
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis);
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis);
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis);
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis);
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis);
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis);
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis);
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em;
}

.link-offset-2 {
  text-underline-offset: 0.25em;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em;
}

.link-offset-3 {
  text-underline-offset: 0.375em;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity));
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity));
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity));
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity));
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity));
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity));
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity));
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity));
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1));
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity));
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity));
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity));
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity));
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity));
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity));
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle);
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle);
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle);
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle);
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle);
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle);
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle);
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle);
}

.bg-gradient {
  background-image: var(--bs-gradient);
}

.user-select-all {
  user-select: all;
}

.user-select-auto {
  user-select: auto;
}

.user-select-none {
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded {
  border-radius: var(--bs-border-radius);
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm);
}

.rounded-2 {
  border-radius: var(--bs-border-radius);
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg);
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl);
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl);
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill);
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm);
  border-top-right-radius: var(--bs-border-radius-sm);
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg);
  border-top-right-radius: var(--bs-border-radius-lg);
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl);
  border-top-right-radius: var(--bs-border-radius-xl);
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl);
  border-top-right-radius: var(--bs-border-radius-xxl);
}

.rounded-top-circle {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill);
  border-top-right-radius: var(--bs-border-radius-pill);
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rounded-end-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm);
  border-bottom-right-radius: var(--bs-border-radius-sm);
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg);
  border-bottom-right-radius: var(--bs-border-radius-lg);
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl);
  border-bottom-right-radius: var(--bs-border-radius-xl);
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl);
  border-bottom-right-radius: var(--bs-border-radius-xxl);
}

.rounded-end-circle {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill);
  border-bottom-right-radius: var(--bs-border-radius-pill);
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm);
  border-bottom-left-radius: var(--bs-border-radius-sm);
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg);
  border-bottom-left-radius: var(--bs-border-radius-lg);
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl);
  border-bottom-left-radius: var(--bs-border-radius-xl);
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl);
  border-bottom-left-radius: var(--bs-border-radius-xxl);
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill);
  border-bottom-left-radius: var(--bs-border-radius-pill);
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}

.rounded-start-0 {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm);
  border-top-left-radius: var(--bs-border-radius-sm);
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg);
  border-top-left-radius: var(--bs-border-radius-lg);
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl);
  border-top-left-radius: var(--bs-border-radius-xl);
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl);
  border-top-left-radius: var(--bs-border-radius-xxl);
}

.rounded-start-circle {
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill);
  border-top-left-radius: var(--bs-border-radius-pill);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.z-n1 {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left;
  }
  .float-sm-end {
    float: right;
  }
  .float-sm-none {
    float: none;
  }
  .object-fit-sm-contain {
    object-fit: contain;
  }
  .object-fit-sm-cover {
    object-fit: cover;
  }
  .object-fit-sm-fill {
    object-fit: fill;
  }
  .object-fit-sm-scale {
    object-fit: scale-down;
  }
  .object-fit-sm-none {
    object-fit: none;
  }
  .d-sm-inline {
    display: inline;
  }
  .d-sm-inline-block {
    display: inline-block;
  }
  .d-sm-block {
    display: block;
  }
  .d-sm-grid {
    display: grid;
  }
  .d-sm-inline-grid {
    display: inline-grid;
  }
  .d-sm-table {
    display: table;
  }
  .d-sm-table-row {
    display: table-row;
  }
  .d-sm-table-cell {
    display: table-cell;
  }
  .d-sm-flex {
    display: flex;
  }
  .d-sm-inline-flex {
    display: inline-flex;
  }
  .d-sm-none {
    display: none;
  }
  .flex-sm-fill {
    flex: 1 1 auto;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-sm-grow-0 {
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }
  .flex-sm-wrap {
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-sm-start {
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    justify-content: center;
  }
  .justify-content-sm-between {
    justify-content: space-between;
  }
  .justify-content-sm-around {
    justify-content: space-around;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }
  .align-items-sm-start {
    align-items: flex-start;
  }
  .align-items-sm-end {
    align-items: flex-end;
  }
  .align-items-sm-center {
    align-items: center;
  }
  .align-items-sm-baseline {
    align-items: baseline;
  }
  .align-items-sm-stretch {
    align-items: stretch;
  }
  .align-content-sm-start {
    align-content: flex-start;
  }
  .align-content-sm-end {
    align-content: flex-end;
  }
  .align-content-sm-center {
    align-content: center;
  }
  .align-content-sm-between {
    align-content: space-between;
  }
  .align-content-sm-around {
    align-content: space-around;
  }
  .align-content-sm-stretch {
    align-content: stretch;
  }
  .align-self-sm-auto {
    align-self: auto;
  }
  .align-self-sm-start {
    align-self: flex-start;
  }
  .align-self-sm-end {
    align-self: flex-end;
  }
  .align-self-sm-center {
    align-self: center;
  }
  .align-self-sm-baseline {
    align-self: baseline;
  }
  .align-self-sm-stretch {
    align-self: stretch;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-last {
    order: 6;
  }
  .m-sm-0 {
    margin: 0;
  }
  .m-sm-1 {
    margin: 8px;
  }
  .m-sm-2 {
    margin: 16px;
  }
  .m-sm-3 {
    margin: 24px;
  }
  .m-sm-4 {
    margin: 32px;
  }
  .m-sm-5 {
    margin: 40px;
  }
  .m-sm-6 {
    margin: 48px;
  }
  .m-sm-7 {
    margin: 56px;
  }
  .m-sm-8 {
    margin: 64px;
  }
  .m-sm-9 {
    margin: 72px;
  }
  .m-sm-10 {
    margin: 80px;
  }
  .m-sm-11 {
    margin: 88px;
  }
  .m-sm-12 {
    margin: 96px;
  }
  .m-sm-auto {
    margin: auto;
  }
  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-sm-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-sm-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-sm-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-sm-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-sm-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-sm-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-sm-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-sm-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-sm-9 {
    margin-right: 72px;
    margin-left: 72px;
  }
  .mx-sm-10 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mx-sm-11 {
    margin-right: 88px;
    margin-left: 88px;
  }
  .mx-sm-12 {
    margin-right: 96px;
    margin-left: 96px;
  }
  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-sm-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-sm-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-sm-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-sm-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-sm-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-sm-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-sm-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-sm-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-sm-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-sm-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .my-sm-11 {
    margin-top: 88px;
    margin-bottom: 88px;
  }
  .my-sm-12 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-sm-0 {
    margin-top: 0;
  }
  .mt-sm-1 {
    margin-top: 8px;
  }
  .mt-sm-2 {
    margin-top: 16px;
  }
  .mt-sm-3 {
    margin-top: 24px;
  }
  .mt-sm-4 {
    margin-top: 32px;
  }
  .mt-sm-5 {
    margin-top: 40px;
  }
  .mt-sm-6 {
    margin-top: 48px;
  }
  .mt-sm-7 {
    margin-top: 56px;
  }
  .mt-sm-8 {
    margin-top: 64px;
  }
  .mt-sm-9 {
    margin-top: 72px;
  }
  .mt-sm-10 {
    margin-top: 80px;
  }
  .mt-sm-11 {
    margin-top: 88px;
  }
  .mt-sm-12 {
    margin-top: 96px;
  }
  .mt-sm-auto {
    margin-top: auto;
  }
  .me-sm-0 {
    margin-right: 0;
  }
  .me-sm-1 {
    margin-right: 8px;
  }
  .me-sm-2 {
    margin-right: 16px;
  }
  .me-sm-3 {
    margin-right: 24px;
  }
  .me-sm-4 {
    margin-right: 32px;
  }
  .me-sm-5 {
    margin-right: 40px;
  }
  .me-sm-6 {
    margin-right: 48px;
  }
  .me-sm-7 {
    margin-right: 56px;
  }
  .me-sm-8 {
    margin-right: 64px;
  }
  .me-sm-9 {
    margin-right: 72px;
  }
  .me-sm-10 {
    margin-right: 80px;
  }
  .me-sm-11 {
    margin-right: 88px;
  }
  .me-sm-12 {
    margin-right: 96px;
  }
  .me-sm-auto {
    margin-right: auto;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .mb-sm-1 {
    margin-bottom: 8px;
  }
  .mb-sm-2 {
    margin-bottom: 16px;
  }
  .mb-sm-3 {
    margin-bottom: 24px;
  }
  .mb-sm-4 {
    margin-bottom: 32px;
  }
  .mb-sm-5 {
    margin-bottom: 40px;
  }
  .mb-sm-6 {
    margin-bottom: 48px;
  }
  .mb-sm-7 {
    margin-bottom: 56px;
  }
  .mb-sm-8 {
    margin-bottom: 64px;
  }
  .mb-sm-9 {
    margin-bottom: 72px;
  }
  .mb-sm-10 {
    margin-bottom: 80px;
  }
  .mb-sm-11 {
    margin-bottom: 88px;
  }
  .mb-sm-12 {
    margin-bottom: 96px;
  }
  .mb-sm-auto {
    margin-bottom: auto;
  }
  .ms-sm-0 {
    margin-left: 0;
  }
  .ms-sm-1 {
    margin-left: 8px;
  }
  .ms-sm-2 {
    margin-left: 16px;
  }
  .ms-sm-3 {
    margin-left: 24px;
  }
  .ms-sm-4 {
    margin-left: 32px;
  }
  .ms-sm-5 {
    margin-left: 40px;
  }
  .ms-sm-6 {
    margin-left: 48px;
  }
  .ms-sm-7 {
    margin-left: 56px;
  }
  .ms-sm-8 {
    margin-left: 64px;
  }
  .ms-sm-9 {
    margin-left: 72px;
  }
  .ms-sm-10 {
    margin-left: 80px;
  }
  .ms-sm-11 {
    margin-left: 88px;
  }
  .ms-sm-12 {
    margin-left: 96px;
  }
  .ms-sm-auto {
    margin-left: auto;
  }
  .p-sm-0 {
    padding: 0;
  }
  .p-sm-1 {
    padding: 8px;
  }
  .p-sm-2 {
    padding: 16px;
  }
  .p-sm-3 {
    padding: 24px;
  }
  .p-sm-4 {
    padding: 32px;
  }
  .p-sm-5 {
    padding: 40px;
  }
  .p-sm-6 {
    padding: 48px;
  }
  .p-sm-7 {
    padding: 56px;
  }
  .p-sm-8 {
    padding: 64px;
  }
  .p-sm-9 {
    padding: 72px;
  }
  .p-sm-10 {
    padding: 80px;
  }
  .p-sm-11 {
    padding: 88px;
  }
  .p-sm-12 {
    padding: 96px;
  }
  .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-sm-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-sm-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-sm-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-sm-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-sm-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-sm-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-sm-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-sm-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .px-sm-9 {
    padding-right: 72px;
    padding-left: 72px;
  }
  .px-sm-10 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .px-sm-11 {
    padding-right: 88px;
    padding-left: 88px;
  }
  .px-sm-12 {
    padding-right: 96px;
    padding-left: 96px;
  }
  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-sm-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-sm-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-sm-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-sm-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-sm-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-sm-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-sm-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-sm-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-sm-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-sm-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .py-sm-11 {
    padding-top: 88px;
    padding-bottom: 88px;
  }
  .py-sm-12 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .pt-sm-0 {
    padding-top: 0;
  }
  .pt-sm-1 {
    padding-top: 8px;
  }
  .pt-sm-2 {
    padding-top: 16px;
  }
  .pt-sm-3 {
    padding-top: 24px;
  }
  .pt-sm-4 {
    padding-top: 32px;
  }
  .pt-sm-5 {
    padding-top: 40px;
  }
  .pt-sm-6 {
    padding-top: 48px;
  }
  .pt-sm-7 {
    padding-top: 56px;
  }
  .pt-sm-8 {
    padding-top: 64px;
  }
  .pt-sm-9 {
    padding-top: 72px;
  }
  .pt-sm-10 {
    padding-top: 80px;
  }
  .pt-sm-11 {
    padding-top: 88px;
  }
  .pt-sm-12 {
    padding-top: 96px;
  }
  .pe-sm-0 {
    padding-right: 0;
  }
  .pe-sm-1 {
    padding-right: 8px;
  }
  .pe-sm-2 {
    padding-right: 16px;
  }
  .pe-sm-3 {
    padding-right: 24px;
  }
  .pe-sm-4 {
    padding-right: 32px;
  }
  .pe-sm-5 {
    padding-right: 40px;
  }
  .pe-sm-6 {
    padding-right: 48px;
  }
  .pe-sm-7 {
    padding-right: 56px;
  }
  .pe-sm-8 {
    padding-right: 64px;
  }
  .pe-sm-9 {
    padding-right: 72px;
  }
  .pe-sm-10 {
    padding-right: 80px;
  }
  .pe-sm-11 {
    padding-right: 88px;
  }
  .pe-sm-12 {
    padding-right: 96px;
  }
  .pb-sm-0 {
    padding-bottom: 0;
  }
  .pb-sm-1 {
    padding-bottom: 8px;
  }
  .pb-sm-2 {
    padding-bottom: 16px;
  }
  .pb-sm-3 {
    padding-bottom: 24px;
  }
  .pb-sm-4 {
    padding-bottom: 32px;
  }
  .pb-sm-5 {
    padding-bottom: 40px;
  }
  .pb-sm-6 {
    padding-bottom: 48px;
  }
  .pb-sm-7 {
    padding-bottom: 56px;
  }
  .pb-sm-8 {
    padding-bottom: 64px;
  }
  .pb-sm-9 {
    padding-bottom: 72px;
  }
  .pb-sm-10 {
    padding-bottom: 80px;
  }
  .pb-sm-11 {
    padding-bottom: 88px;
  }
  .pb-sm-12 {
    padding-bottom: 96px;
  }
  .ps-sm-0 {
    padding-left: 0;
  }
  .ps-sm-1 {
    padding-left: 8px;
  }
  .ps-sm-2 {
    padding-left: 16px;
  }
  .ps-sm-3 {
    padding-left: 24px;
  }
  .ps-sm-4 {
    padding-left: 32px;
  }
  .ps-sm-5 {
    padding-left: 40px;
  }
  .ps-sm-6 {
    padding-left: 48px;
  }
  .ps-sm-7 {
    padding-left: 56px;
  }
  .ps-sm-8 {
    padding-left: 64px;
  }
  .ps-sm-9 {
    padding-left: 72px;
  }
  .ps-sm-10 {
    padding-left: 80px;
  }
  .ps-sm-11 {
    padding-left: 88px;
  }
  .ps-sm-12 {
    padding-left: 96px;
  }
  .gap-sm-0 {
    gap: 0;
  }
  .gap-sm-1 {
    gap: 8px;
  }
  .gap-sm-2 {
    gap: 16px;
  }
  .gap-sm-3 {
    gap: 24px;
  }
  .gap-sm-4 {
    gap: 32px;
  }
  .gap-sm-5 {
    gap: 40px;
  }
  .gap-sm-6 {
    gap: 48px;
  }
  .gap-sm-7 {
    gap: 56px;
  }
  .gap-sm-8 {
    gap: 64px;
  }
  .gap-sm-9 {
    gap: 72px;
  }
  .gap-sm-10 {
    gap: 80px;
  }
  .gap-sm-11 {
    gap: 88px;
  }
  .gap-sm-12 {
    gap: 96px;
  }
  .row-gap-sm-0 {
    row-gap: 0;
  }
  .row-gap-sm-1 {
    row-gap: 8px;
  }
  .row-gap-sm-2 {
    row-gap: 16px;
  }
  .row-gap-sm-3 {
    row-gap: 24px;
  }
  .row-gap-sm-4 {
    row-gap: 32px;
  }
  .row-gap-sm-5 {
    row-gap: 40px;
  }
  .row-gap-sm-6 {
    row-gap: 48px;
  }
  .row-gap-sm-7 {
    row-gap: 56px;
  }
  .row-gap-sm-8 {
    row-gap: 64px;
  }
  .row-gap-sm-9 {
    row-gap: 72px;
  }
  .row-gap-sm-10 {
    row-gap: 80px;
  }
  .row-gap-sm-11 {
    row-gap: 88px;
  }
  .row-gap-sm-12 {
    row-gap: 96px;
  }
  .column-gap-sm-0 {
    column-gap: 0;
  }
  .column-gap-sm-1 {
    column-gap: 8px;
  }
  .column-gap-sm-2 {
    column-gap: 16px;
  }
  .column-gap-sm-3 {
    column-gap: 24px;
  }
  .column-gap-sm-4 {
    column-gap: 32px;
  }
  .column-gap-sm-5 {
    column-gap: 40px;
  }
  .column-gap-sm-6 {
    column-gap: 48px;
  }
  .column-gap-sm-7 {
    column-gap: 56px;
  }
  .column-gap-sm-8 {
    column-gap: 64px;
  }
  .column-gap-sm-9 {
    column-gap: 72px;
  }
  .column-gap-sm-10 {
    column-gap: 80px;
  }
  .column-gap-sm-11 {
    column-gap: 88px;
  }
  .column-gap-sm-12 {
    column-gap: 96px;
  }
  .text-sm-start {
    text-align: left;
  }
  .text-sm-end {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left;
  }
  .float-md-end {
    float: right;
  }
  .float-md-none {
    float: none;
  }
  .object-fit-md-contain {
    object-fit: contain;
  }
  .object-fit-md-cover {
    object-fit: cover;
  }
  .object-fit-md-fill {
    object-fit: fill;
  }
  .object-fit-md-scale {
    object-fit: scale-down;
  }
  .object-fit-md-none {
    object-fit: none;
  }
  .d-md-inline {
    display: inline;
  }
  .d-md-inline-block {
    display: inline-block;
  }
  .d-md-block {
    display: block;
  }
  .d-md-grid {
    display: grid;
  }
  .d-md-inline-grid {
    display: inline-grid;
  }
  .d-md-table {
    display: table;
  }
  .d-md-table-row {
    display: table-row;
  }
  .d-md-table-cell {
    display: table-cell;
  }
  .d-md-flex {
    display: flex;
  }
  .d-md-inline-flex {
    display: inline-flex;
  }
  .d-md-none {
    display: none;
  }
  .flex-md-fill {
    flex: 1 1 auto;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-grow-0 {
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1;
  }
  .flex-md-wrap {
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-md-start {
    justify-content: flex-start;
  }
  .justify-content-md-end {
    justify-content: flex-end;
  }
  .justify-content-md-center {
    justify-content: center;
  }
  .justify-content-md-between {
    justify-content: space-between;
  }
  .justify-content-md-around {
    justify-content: space-around;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly;
  }
  .align-items-md-start {
    align-items: flex-start;
  }
  .align-items-md-end {
    align-items: flex-end;
  }
  .align-items-md-center {
    align-items: center;
  }
  .align-items-md-baseline {
    align-items: baseline;
  }
  .align-items-md-stretch {
    align-items: stretch;
  }
  .align-content-md-start {
    align-content: flex-start;
  }
  .align-content-md-end {
    align-content: flex-end;
  }
  .align-content-md-center {
    align-content: center;
  }
  .align-content-md-between {
    align-content: space-between;
  }
  .align-content-md-around {
    align-content: space-around;
  }
  .align-content-md-stretch {
    align-content: stretch;
  }
  .align-self-md-auto {
    align-self: auto;
  }
  .align-self-md-start {
    align-self: flex-start;
  }
  .align-self-md-end {
    align-self: flex-end;
  }
  .align-self-md-center {
    align-self: center;
  }
  .align-self-md-baseline {
    align-self: baseline;
  }
  .align-self-md-stretch {
    align-self: stretch;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-last {
    order: 6;
  }
  .m-md-0 {
    margin: 0;
  }
  .m-md-1 {
    margin: 8px;
  }
  .m-md-2 {
    margin: 16px;
  }
  .m-md-3 {
    margin: 24px;
  }
  .m-md-4 {
    margin: 32px;
  }
  .m-md-5 {
    margin: 40px;
  }
  .m-md-6 {
    margin: 48px;
  }
  .m-md-7 {
    margin: 56px;
  }
  .m-md-8 {
    margin: 64px;
  }
  .m-md-9 {
    margin: 72px;
  }
  .m-md-10 {
    margin: 80px;
  }
  .m-md-11 {
    margin: 88px;
  }
  .m-md-12 {
    margin: 96px;
  }
  .m-md-auto {
    margin: auto;
  }
  .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-md-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-md-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-md-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-md-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-md-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-md-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-md-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-md-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-md-9 {
    margin-right: 72px;
    margin-left: 72px;
  }
  .mx-md-10 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mx-md-11 {
    margin-right: 88px;
    margin-left: 88px;
  }
  .mx-md-12 {
    margin-right: 96px;
    margin-left: 96px;
  }
  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-md-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-md-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-md-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-md-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-md-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-md-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-md-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-md-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-md-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-md-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .my-md-11 {
    margin-top: 88px;
    margin-bottom: 88px;
  }
  .my-md-12 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-md-0 {
    margin-top: 0;
  }
  .mt-md-1 {
    margin-top: 8px;
  }
  .mt-md-2 {
    margin-top: 16px;
  }
  .mt-md-3 {
    margin-top: 24px;
  }
  .mt-md-4 {
    margin-top: 32px;
  }
  .mt-md-5 {
    margin-top: 40px;
  }
  .mt-md-6 {
    margin-top: 48px;
  }
  .mt-md-7 {
    margin-top: 56px;
  }
  .mt-md-8 {
    margin-top: 64px;
  }
  .mt-md-9 {
    margin-top: 72px;
  }
  .mt-md-10 {
    margin-top: 80px;
  }
  .mt-md-11 {
    margin-top: 88px;
  }
  .mt-md-12 {
    margin-top: 96px;
  }
  .mt-md-auto {
    margin-top: auto;
  }
  .me-md-0 {
    margin-right: 0;
  }
  .me-md-1 {
    margin-right: 8px;
  }
  .me-md-2 {
    margin-right: 16px;
  }
  .me-md-3 {
    margin-right: 24px;
  }
  .me-md-4 {
    margin-right: 32px;
  }
  .me-md-5 {
    margin-right: 40px;
  }
  .me-md-6 {
    margin-right: 48px;
  }
  .me-md-7 {
    margin-right: 56px;
  }
  .me-md-8 {
    margin-right: 64px;
  }
  .me-md-9 {
    margin-right: 72px;
  }
  .me-md-10 {
    margin-right: 80px;
  }
  .me-md-11 {
    margin-right: 88px;
  }
  .me-md-12 {
    margin-right: 96px;
  }
  .me-md-auto {
    margin-right: auto;
  }
  .mb-md-0 {
    margin-bottom: 0;
  }
  .mb-md-1 {
    margin-bottom: 8px;
  }
  .mb-md-2 {
    margin-bottom: 16px;
  }
  .mb-md-3 {
    margin-bottom: 24px;
  }
  .mb-md-4 {
    margin-bottom: 32px;
  }
  .mb-md-5 {
    margin-bottom: 40px;
  }
  .mb-md-6 {
    margin-bottom: 48px;
  }
  .mb-md-7 {
    margin-bottom: 56px;
  }
  .mb-md-8 {
    margin-bottom: 64px;
  }
  .mb-md-9 {
    margin-bottom: 72px;
  }
  .mb-md-10 {
    margin-bottom: 80px;
  }
  .mb-md-11 {
    margin-bottom: 88px;
  }
  .mb-md-12 {
    margin-bottom: 96px;
  }
  .mb-md-auto {
    margin-bottom: auto;
  }
  .ms-md-0 {
    margin-left: 0;
  }
  .ms-md-1 {
    margin-left: 8px;
  }
  .ms-md-2 {
    margin-left: 16px;
  }
  .ms-md-3 {
    margin-left: 24px;
  }
  .ms-md-4 {
    margin-left: 32px;
  }
  .ms-md-5 {
    margin-left: 40px;
  }
  .ms-md-6 {
    margin-left: 48px;
  }
  .ms-md-7 {
    margin-left: 56px;
  }
  .ms-md-8 {
    margin-left: 64px;
  }
  .ms-md-9 {
    margin-left: 72px;
  }
  .ms-md-10 {
    margin-left: 80px;
  }
  .ms-md-11 {
    margin-left: 88px;
  }
  .ms-md-12 {
    margin-left: 96px;
  }
  .ms-md-auto {
    margin-left: auto;
  }
  .p-md-0 {
    padding: 0;
  }
  .p-md-1 {
    padding: 8px;
  }
  .p-md-2 {
    padding: 16px;
  }
  .p-md-3 {
    padding: 24px;
  }
  .p-md-4 {
    padding: 32px;
  }
  .p-md-5 {
    padding: 40px;
  }
  .p-md-6 {
    padding: 48px;
  }
  .p-md-7 {
    padding: 56px;
  }
  .p-md-8 {
    padding: 64px;
  }
  .p-md-9 {
    padding: 72px;
  }
  .p-md-10 {
    padding: 80px;
  }
  .p-md-11 {
    padding: 88px;
  }
  .p-md-12 {
    padding: 96px;
  }
  .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-md-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-md-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-md-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-md-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-md-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-md-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-md-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-md-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .px-md-9 {
    padding-right: 72px;
    padding-left: 72px;
  }
  .px-md-10 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .px-md-11 {
    padding-right: 88px;
    padding-left: 88px;
  }
  .px-md-12 {
    padding-right: 96px;
    padding-left: 96px;
  }
  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-md-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-md-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-md-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-md-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-md-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-md-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-md-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-md-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-md-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-md-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .py-md-11 {
    padding-top: 88px;
    padding-bottom: 88px;
  }
  .py-md-12 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .pt-md-0 {
    padding-top: 0;
  }
  .pt-md-1 {
    padding-top: 8px;
  }
  .pt-md-2 {
    padding-top: 16px;
  }
  .pt-md-3 {
    padding-top: 24px;
  }
  .pt-md-4 {
    padding-top: 32px;
  }
  .pt-md-5 {
    padding-top: 40px;
  }
  .pt-md-6 {
    padding-top: 48px;
  }
  .pt-md-7 {
    padding-top: 56px;
  }
  .pt-md-8 {
    padding-top: 64px;
  }
  .pt-md-9 {
    padding-top: 72px;
  }
  .pt-md-10 {
    padding-top: 80px;
  }
  .pt-md-11 {
    padding-top: 88px;
  }
  .pt-md-12 {
    padding-top: 96px;
  }
  .pe-md-0 {
    padding-right: 0;
  }
  .pe-md-1 {
    padding-right: 8px;
  }
  .pe-md-2 {
    padding-right: 16px;
  }
  .pe-md-3 {
    padding-right: 24px;
  }
  .pe-md-4 {
    padding-right: 32px;
  }
  .pe-md-5 {
    padding-right: 40px;
  }
  .pe-md-6 {
    padding-right: 48px;
  }
  .pe-md-7 {
    padding-right: 56px;
  }
  .pe-md-8 {
    padding-right: 64px;
  }
  .pe-md-9 {
    padding-right: 72px;
  }
  .pe-md-10 {
    padding-right: 80px;
  }
  .pe-md-11 {
    padding-right: 88px;
  }
  .pe-md-12 {
    padding-right: 96px;
  }
  .pb-md-0 {
    padding-bottom: 0;
  }
  .pb-md-1 {
    padding-bottom: 8px;
  }
  .pb-md-2 {
    padding-bottom: 16px;
  }
  .pb-md-3 {
    padding-bottom: 24px;
  }
  .pb-md-4 {
    padding-bottom: 32px;
  }
  .pb-md-5 {
    padding-bottom: 40px;
  }
  .pb-md-6 {
    padding-bottom: 48px;
  }
  .pb-md-7 {
    padding-bottom: 56px;
  }
  .pb-md-8 {
    padding-bottom: 64px;
  }
  .pb-md-9 {
    padding-bottom: 72px;
  }
  .pb-md-10 {
    padding-bottom: 80px;
  }
  .pb-md-11 {
    padding-bottom: 88px;
  }
  .pb-md-12 {
    padding-bottom: 96px;
  }
  .ps-md-0 {
    padding-left: 0;
  }
  .ps-md-1 {
    padding-left: 8px;
  }
  .ps-md-2 {
    padding-left: 16px;
  }
  .ps-md-3 {
    padding-left: 24px;
  }
  .ps-md-4 {
    padding-left: 32px;
  }
  .ps-md-5 {
    padding-left: 40px;
  }
  .ps-md-6 {
    padding-left: 48px;
  }
  .ps-md-7 {
    padding-left: 56px;
  }
  .ps-md-8 {
    padding-left: 64px;
  }
  .ps-md-9 {
    padding-left: 72px;
  }
  .ps-md-10 {
    padding-left: 80px;
  }
  .ps-md-11 {
    padding-left: 88px;
  }
  .ps-md-12 {
    padding-left: 96px;
  }
  .gap-md-0 {
    gap: 0;
  }
  .gap-md-1 {
    gap: 8px;
  }
  .gap-md-2 {
    gap: 16px;
  }
  .gap-md-3 {
    gap: 24px;
  }
  .gap-md-4 {
    gap: 32px;
  }
  .gap-md-5 {
    gap: 40px;
  }
  .gap-md-6 {
    gap: 48px;
  }
  .gap-md-7 {
    gap: 56px;
  }
  .gap-md-8 {
    gap: 64px;
  }
  .gap-md-9 {
    gap: 72px;
  }
  .gap-md-10 {
    gap: 80px;
  }
  .gap-md-11 {
    gap: 88px;
  }
  .gap-md-12 {
    gap: 96px;
  }
  .row-gap-md-0 {
    row-gap: 0;
  }
  .row-gap-md-1 {
    row-gap: 8px;
  }
  .row-gap-md-2 {
    row-gap: 16px;
  }
  .row-gap-md-3 {
    row-gap: 24px;
  }
  .row-gap-md-4 {
    row-gap: 32px;
  }
  .row-gap-md-5 {
    row-gap: 40px;
  }
  .row-gap-md-6 {
    row-gap: 48px;
  }
  .row-gap-md-7 {
    row-gap: 56px;
  }
  .row-gap-md-8 {
    row-gap: 64px;
  }
  .row-gap-md-9 {
    row-gap: 72px;
  }
  .row-gap-md-10 {
    row-gap: 80px;
  }
  .row-gap-md-11 {
    row-gap: 88px;
  }
  .row-gap-md-12 {
    row-gap: 96px;
  }
  .column-gap-md-0 {
    column-gap: 0;
  }
  .column-gap-md-1 {
    column-gap: 8px;
  }
  .column-gap-md-2 {
    column-gap: 16px;
  }
  .column-gap-md-3 {
    column-gap: 24px;
  }
  .column-gap-md-4 {
    column-gap: 32px;
  }
  .column-gap-md-5 {
    column-gap: 40px;
  }
  .column-gap-md-6 {
    column-gap: 48px;
  }
  .column-gap-md-7 {
    column-gap: 56px;
  }
  .column-gap-md-8 {
    column-gap: 64px;
  }
  .column-gap-md-9 {
    column-gap: 72px;
  }
  .column-gap-md-10 {
    column-gap: 80px;
  }
  .column-gap-md-11 {
    column-gap: 88px;
  }
  .column-gap-md-12 {
    column-gap: 96px;
  }
  .text-md-start {
    text-align: left;
  }
  .text-md-end {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left;
  }
  .float-lg-end {
    float: right;
  }
  .float-lg-none {
    float: none;
  }
  .object-fit-lg-contain {
    object-fit: contain;
  }
  .object-fit-lg-cover {
    object-fit: cover;
  }
  .object-fit-lg-fill {
    object-fit: fill;
  }
  .object-fit-lg-scale {
    object-fit: scale-down;
  }
  .object-fit-lg-none {
    object-fit: none;
  }
  .d-lg-inline {
    display: inline;
  }
  .d-lg-inline-block {
    display: inline-block;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-grid {
    display: grid;
  }
  .d-lg-inline-grid {
    display: inline-grid;
  }
  .d-lg-table {
    display: table;
  }
  .d-lg-table-row {
    display: table-row;
  }
  .d-lg-table-cell {
    display: table-cell;
  }
  .d-lg-flex {
    display: flex;
  }
  .d-lg-inline-flex {
    display: inline-flex;
  }
  .d-lg-none {
    display: none;
  }
  .flex-lg-fill {
    flex: 1 1 auto;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-grow-0 {
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }
  .flex-lg-wrap {
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-lg-start {
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
  .justify-content-lg-between {
    justify-content: space-between;
  }
  .justify-content-lg-around {
    justify-content: space-around;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }
  .align-items-lg-start {
    align-items: flex-start;
  }
  .align-items-lg-end {
    align-items: flex-end;
  }
  .align-items-lg-center {
    align-items: center;
  }
  .align-items-lg-baseline {
    align-items: baseline;
  }
  .align-items-lg-stretch {
    align-items: stretch;
  }
  .align-content-lg-start {
    align-content: flex-start;
  }
  .align-content-lg-end {
    align-content: flex-end;
  }
  .align-content-lg-center {
    align-content: center;
  }
  .align-content-lg-between {
    align-content: space-between;
  }
  .align-content-lg-around {
    align-content: space-around;
  }
  .align-content-lg-stretch {
    align-content: stretch;
  }
  .align-self-lg-auto {
    align-self: auto;
  }
  .align-self-lg-start {
    align-self: flex-start;
  }
  .align-self-lg-end {
    align-self: flex-end;
  }
  .align-self-lg-center {
    align-self: center;
  }
  .align-self-lg-baseline {
    align-self: baseline;
  }
  .align-self-lg-stretch {
    align-self: stretch;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-last {
    order: 6;
  }
  .m-lg-0 {
    margin: 0;
  }
  .m-lg-1 {
    margin: 8px;
  }
  .m-lg-2 {
    margin: 16px;
  }
  .m-lg-3 {
    margin: 24px;
  }
  .m-lg-4 {
    margin: 32px;
  }
  .m-lg-5 {
    margin: 40px;
  }
  .m-lg-6 {
    margin: 48px;
  }
  .m-lg-7 {
    margin: 56px;
  }
  .m-lg-8 {
    margin: 64px;
  }
  .m-lg-9 {
    margin: 72px;
  }
  .m-lg-10 {
    margin: 80px;
  }
  .m-lg-11 {
    margin: 88px;
  }
  .m-lg-12 {
    margin: 96px;
  }
  .m-lg-auto {
    margin: auto;
  }
  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-lg-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-lg-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-lg-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-lg-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-lg-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-lg-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-lg-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-lg-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-lg-9 {
    margin-right: 72px;
    margin-left: 72px;
  }
  .mx-lg-10 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mx-lg-11 {
    margin-right: 88px;
    margin-left: 88px;
  }
  .mx-lg-12 {
    margin-right: 96px;
    margin-left: 96px;
  }
  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-lg-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-lg-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-lg-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-lg-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-lg-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-lg-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-lg-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-lg-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-lg-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-lg-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .my-lg-11 {
    margin-top: 88px;
    margin-bottom: 88px;
  }
  .my-lg-12 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-lg-0 {
    margin-top: 0;
  }
  .mt-lg-1 {
    margin-top: 8px;
  }
  .mt-lg-2 {
    margin-top: 16px;
  }
  .mt-lg-3 {
    margin-top: 24px;
  }
  .mt-lg-4 {
    margin-top: 32px;
  }
  .mt-lg-5 {
    margin-top: 40px;
  }
  .mt-lg-6 {
    margin-top: 48px;
  }
  .mt-lg-7 {
    margin-top: 56px;
  }
  .mt-lg-8 {
    margin-top: 64px;
  }
  .mt-lg-9 {
    margin-top: 72px;
  }
  .mt-lg-10 {
    margin-top: 80px;
  }
  .mt-lg-11 {
    margin-top: 88px;
  }
  .mt-lg-12 {
    margin-top: 96px;
  }
  .mt-lg-auto {
    margin-top: auto;
  }
  .me-lg-0 {
    margin-right: 0;
  }
  .me-lg-1 {
    margin-right: 8px;
  }
  .me-lg-2 {
    margin-right: 16px;
  }
  .me-lg-3 {
    margin-right: 24px;
  }
  .me-lg-4 {
    margin-right: 32px;
  }
  .me-lg-5 {
    margin-right: 40px;
  }
  .me-lg-6 {
    margin-right: 48px;
  }
  .me-lg-7 {
    margin-right: 56px;
  }
  .me-lg-8 {
    margin-right: 64px;
  }
  .me-lg-9 {
    margin-right: 72px;
  }
  .me-lg-10 {
    margin-right: 80px;
  }
  .me-lg-11 {
    margin-right: 88px;
  }
  .me-lg-12 {
    margin-right: 96px;
  }
  .me-lg-auto {
    margin-right: auto;
  }
  .mb-lg-0 {
    margin-bottom: 0;
  }
  .mb-lg-1 {
    margin-bottom: 8px;
  }
  .mb-lg-2 {
    margin-bottom: 16px;
  }
  .mb-lg-3 {
    margin-bottom: 24px;
  }
  .mb-lg-4 {
    margin-bottom: 32px;
  }
  .mb-lg-5 {
    margin-bottom: 40px;
  }
  .mb-lg-6 {
    margin-bottom: 48px;
  }
  .mb-lg-7 {
    margin-bottom: 56px;
  }
  .mb-lg-8 {
    margin-bottom: 64px;
  }
  .mb-lg-9 {
    margin-bottom: 72px;
  }
  .mb-lg-10 {
    margin-bottom: 80px;
  }
  .mb-lg-11 {
    margin-bottom: 88px;
  }
  .mb-lg-12 {
    margin-bottom: 96px;
  }
  .mb-lg-auto {
    margin-bottom: auto;
  }
  .ms-lg-0 {
    margin-left: 0;
  }
  .ms-lg-1 {
    margin-left: 8px;
  }
  .ms-lg-2 {
    margin-left: 16px;
  }
  .ms-lg-3 {
    margin-left: 24px;
  }
  .ms-lg-4 {
    margin-left: 32px;
  }
  .ms-lg-5 {
    margin-left: 40px;
  }
  .ms-lg-6 {
    margin-left: 48px;
  }
  .ms-lg-7 {
    margin-left: 56px;
  }
  .ms-lg-8 {
    margin-left: 64px;
  }
  .ms-lg-9 {
    margin-left: 72px;
  }
  .ms-lg-10 {
    margin-left: 80px;
  }
  .ms-lg-11 {
    margin-left: 88px;
  }
  .ms-lg-12 {
    margin-left: 96px;
  }
  .ms-lg-auto {
    margin-left: auto;
  }
  .p-lg-0 {
    padding: 0;
  }
  .p-lg-1 {
    padding: 8px;
  }
  .p-lg-2 {
    padding: 16px;
  }
  .p-lg-3 {
    padding: 24px;
  }
  .p-lg-4 {
    padding: 32px;
  }
  .p-lg-5 {
    padding: 40px;
  }
  .p-lg-6 {
    padding: 48px;
  }
  .p-lg-7 {
    padding: 56px;
  }
  .p-lg-8 {
    padding: 64px;
  }
  .p-lg-9 {
    padding: 72px;
  }
  .p-lg-10 {
    padding: 80px;
  }
  .p-lg-11 {
    padding: 88px;
  }
  .p-lg-12 {
    padding: 96px;
  }
  .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-lg-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-lg-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-lg-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-lg-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-lg-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-lg-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-lg-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-lg-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .px-lg-9 {
    padding-right: 72px;
    padding-left: 72px;
  }
  .px-lg-10 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .px-lg-11 {
    padding-right: 88px;
    padding-left: 88px;
  }
  .px-lg-12 {
    padding-right: 96px;
    padding-left: 96px;
  }
  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-lg-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-lg-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-lg-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-lg-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-lg-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-lg-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-lg-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-lg-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-lg-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-lg-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .py-lg-11 {
    padding-top: 88px;
    padding-bottom: 88px;
  }
  .py-lg-12 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .pt-lg-0 {
    padding-top: 0;
  }
  .pt-lg-1 {
    padding-top: 8px;
  }
  .pt-lg-2 {
    padding-top: 16px;
  }
  .pt-lg-3 {
    padding-top: 24px;
  }
  .pt-lg-4 {
    padding-top: 32px;
  }
  .pt-lg-5 {
    padding-top: 40px;
  }
  .pt-lg-6 {
    padding-top: 48px;
  }
  .pt-lg-7 {
    padding-top: 56px;
  }
  .pt-lg-8 {
    padding-top: 64px;
  }
  .pt-lg-9 {
    padding-top: 72px;
  }
  .pt-lg-10 {
    padding-top: 80px;
  }
  .pt-lg-11 {
    padding-top: 88px;
  }
  .pt-lg-12 {
    padding-top: 96px;
  }
  .pe-lg-0 {
    padding-right: 0;
  }
  .pe-lg-1 {
    padding-right: 8px;
  }
  .pe-lg-2 {
    padding-right: 16px;
  }
  .pe-lg-3 {
    padding-right: 24px;
  }
  .pe-lg-4 {
    padding-right: 32px;
  }
  .pe-lg-5 {
    padding-right: 40px;
  }
  .pe-lg-6 {
    padding-right: 48px;
  }
  .pe-lg-7 {
    padding-right: 56px;
  }
  .pe-lg-8 {
    padding-right: 64px;
  }
  .pe-lg-9 {
    padding-right: 72px;
  }
  .pe-lg-10 {
    padding-right: 80px;
  }
  .pe-lg-11 {
    padding-right: 88px;
  }
  .pe-lg-12 {
    padding-right: 96px;
  }
  .pb-lg-0 {
    padding-bottom: 0;
  }
  .pb-lg-1 {
    padding-bottom: 8px;
  }
  .pb-lg-2 {
    padding-bottom: 16px;
  }
  .pb-lg-3 {
    padding-bottom: 24px;
  }
  .pb-lg-4 {
    padding-bottom: 32px;
  }
  .pb-lg-5 {
    padding-bottom: 40px;
  }
  .pb-lg-6 {
    padding-bottom: 48px;
  }
  .pb-lg-7 {
    padding-bottom: 56px;
  }
  .pb-lg-8 {
    padding-bottom: 64px;
  }
  .pb-lg-9 {
    padding-bottom: 72px;
  }
  .pb-lg-10 {
    padding-bottom: 80px;
  }
  .pb-lg-11 {
    padding-bottom: 88px;
  }
  .pb-lg-12 {
    padding-bottom: 96px;
  }
  .ps-lg-0 {
    padding-left: 0;
  }
  .ps-lg-1 {
    padding-left: 8px;
  }
  .ps-lg-2 {
    padding-left: 16px;
  }
  .ps-lg-3 {
    padding-left: 24px;
  }
  .ps-lg-4 {
    padding-left: 32px;
  }
  .ps-lg-5 {
    padding-left: 40px;
  }
  .ps-lg-6 {
    padding-left: 48px;
  }
  .ps-lg-7 {
    padding-left: 56px;
  }
  .ps-lg-8 {
    padding-left: 64px;
  }
  .ps-lg-9 {
    padding-left: 72px;
  }
  .ps-lg-10 {
    padding-left: 80px;
  }
  .ps-lg-11 {
    padding-left: 88px;
  }
  .ps-lg-12 {
    padding-left: 96px;
  }
  .gap-lg-0 {
    gap: 0;
  }
  .gap-lg-1 {
    gap: 8px;
  }
  .gap-lg-2 {
    gap: 16px;
  }
  .gap-lg-3 {
    gap: 24px;
  }
  .gap-lg-4 {
    gap: 32px;
  }
  .gap-lg-5 {
    gap: 40px;
  }
  .gap-lg-6 {
    gap: 48px;
  }
  .gap-lg-7 {
    gap: 56px;
  }
  .gap-lg-8 {
    gap: 64px;
  }
  .gap-lg-9 {
    gap: 72px;
  }
  .gap-lg-10 {
    gap: 80px;
  }
  .gap-lg-11 {
    gap: 88px;
  }
  .gap-lg-12 {
    gap: 96px;
  }
  .row-gap-lg-0 {
    row-gap: 0;
  }
  .row-gap-lg-1 {
    row-gap: 8px;
  }
  .row-gap-lg-2 {
    row-gap: 16px;
  }
  .row-gap-lg-3 {
    row-gap: 24px;
  }
  .row-gap-lg-4 {
    row-gap: 32px;
  }
  .row-gap-lg-5 {
    row-gap: 40px;
  }
  .row-gap-lg-6 {
    row-gap: 48px;
  }
  .row-gap-lg-7 {
    row-gap: 56px;
  }
  .row-gap-lg-8 {
    row-gap: 64px;
  }
  .row-gap-lg-9 {
    row-gap: 72px;
  }
  .row-gap-lg-10 {
    row-gap: 80px;
  }
  .row-gap-lg-11 {
    row-gap: 88px;
  }
  .row-gap-lg-12 {
    row-gap: 96px;
  }
  .column-gap-lg-0 {
    column-gap: 0;
  }
  .column-gap-lg-1 {
    column-gap: 8px;
  }
  .column-gap-lg-2 {
    column-gap: 16px;
  }
  .column-gap-lg-3 {
    column-gap: 24px;
  }
  .column-gap-lg-4 {
    column-gap: 32px;
  }
  .column-gap-lg-5 {
    column-gap: 40px;
  }
  .column-gap-lg-6 {
    column-gap: 48px;
  }
  .column-gap-lg-7 {
    column-gap: 56px;
  }
  .column-gap-lg-8 {
    column-gap: 64px;
  }
  .column-gap-lg-9 {
    column-gap: 72px;
  }
  .column-gap-lg-10 {
    column-gap: 80px;
  }
  .column-gap-lg-11 {
    column-gap: 88px;
  }
  .column-gap-lg-12 {
    column-gap: 96px;
  }
  .text-lg-start {
    text-align: left;
  }
  .text-lg-end {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left;
  }
  .float-xl-end {
    float: right;
  }
  .float-xl-none {
    float: none;
  }
  .object-fit-xl-contain {
    object-fit: contain;
  }
  .object-fit-xl-cover {
    object-fit: cover;
  }
  .object-fit-xl-fill {
    object-fit: fill;
  }
  .object-fit-xl-scale {
    object-fit: scale-down;
  }
  .object-fit-xl-none {
    object-fit: none;
  }
  .d-xl-inline {
    display: inline;
  }
  .d-xl-inline-block {
    display: inline-block;
  }
  .d-xl-block {
    display: block;
  }
  .d-xl-grid {
    display: grid;
  }
  .d-xl-inline-grid {
    display: inline-grid;
  }
  .d-xl-table {
    display: table;
  }
  .d-xl-table-row {
    display: table-row;
  }
  .d-xl-table-cell {
    display: table-cell;
  }
  .d-xl-flex {
    display: flex;
  }
  .d-xl-inline-flex {
    display: inline-flex;
  }
  .d-xl-none {
    display: none;
  }
  .flex-xl-fill {
    flex: 1 1 auto;
  }
  .flex-xl-row {
    flex-direction: row;
  }
  .flex-xl-column {
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xl-grow-0 {
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xl-wrap {
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xl-start {
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    justify-content: center;
  }
  .justify-content-xl-between {
    justify-content: space-between;
  }
  .justify-content-xl-around {
    justify-content: space-around;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xl-start {
    align-items: flex-start;
  }
  .align-items-xl-end {
    align-items: flex-end;
  }
  .align-items-xl-center {
    align-items: center;
  }
  .align-items-xl-baseline {
    align-items: baseline;
  }
  .align-items-xl-stretch {
    align-items: stretch;
  }
  .align-content-xl-start {
    align-content: flex-start;
  }
  .align-content-xl-end {
    align-content: flex-end;
  }
  .align-content-xl-center {
    align-content: center;
  }
  .align-content-xl-between {
    align-content: space-between;
  }
  .align-content-xl-around {
    align-content: space-around;
  }
  .align-content-xl-stretch {
    align-content: stretch;
  }
  .align-self-xl-auto {
    align-self: auto;
  }
  .align-self-xl-start {
    align-self: flex-start;
  }
  .align-self-xl-end {
    align-self: flex-end;
  }
  .align-self-xl-center {
    align-self: center;
  }
  .align-self-xl-baseline {
    align-self: baseline;
  }
  .align-self-xl-stretch {
    align-self: stretch;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-last {
    order: 6;
  }
  .m-xl-0 {
    margin: 0;
  }
  .m-xl-1 {
    margin: 8px;
  }
  .m-xl-2 {
    margin: 16px;
  }
  .m-xl-3 {
    margin: 24px;
  }
  .m-xl-4 {
    margin: 32px;
  }
  .m-xl-5 {
    margin: 40px;
  }
  .m-xl-6 {
    margin: 48px;
  }
  .m-xl-7 {
    margin: 56px;
  }
  .m-xl-8 {
    margin: 64px;
  }
  .m-xl-9 {
    margin: 72px;
  }
  .m-xl-10 {
    margin: 80px;
  }
  .m-xl-11 {
    margin: 88px;
  }
  .m-xl-12 {
    margin: 96px;
  }
  .m-xl-auto {
    margin: auto;
  }
  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xl-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-xl-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-xl-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-xl-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-xl-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-xl-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-xl-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-xl-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-xl-9 {
    margin-right: 72px;
    margin-left: 72px;
  }
  .mx-xl-10 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mx-xl-11 {
    margin-right: 88px;
    margin-left: 88px;
  }
  .mx-xl-12 {
    margin-right: 96px;
    margin-left: 96px;
  }
  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xl-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xl-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xl-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xl-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xl-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-xl-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xl-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-xl-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-xl-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-xl-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .my-xl-11 {
    margin-top: 88px;
    margin-bottom: 88px;
  }
  .my-xl-12 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xl-0 {
    margin-top: 0;
  }
  .mt-xl-1 {
    margin-top: 8px;
  }
  .mt-xl-2 {
    margin-top: 16px;
  }
  .mt-xl-3 {
    margin-top: 24px;
  }
  .mt-xl-4 {
    margin-top: 32px;
  }
  .mt-xl-5 {
    margin-top: 40px;
  }
  .mt-xl-6 {
    margin-top: 48px;
  }
  .mt-xl-7 {
    margin-top: 56px;
  }
  .mt-xl-8 {
    margin-top: 64px;
  }
  .mt-xl-9 {
    margin-top: 72px;
  }
  .mt-xl-10 {
    margin-top: 80px;
  }
  .mt-xl-11 {
    margin-top: 88px;
  }
  .mt-xl-12 {
    margin-top: 96px;
  }
  .mt-xl-auto {
    margin-top: auto;
  }
  .me-xl-0 {
    margin-right: 0;
  }
  .me-xl-1 {
    margin-right: 8px;
  }
  .me-xl-2 {
    margin-right: 16px;
  }
  .me-xl-3 {
    margin-right: 24px;
  }
  .me-xl-4 {
    margin-right: 32px;
  }
  .me-xl-5 {
    margin-right: 40px;
  }
  .me-xl-6 {
    margin-right: 48px;
  }
  .me-xl-7 {
    margin-right: 56px;
  }
  .me-xl-8 {
    margin-right: 64px;
  }
  .me-xl-9 {
    margin-right: 72px;
  }
  .me-xl-10 {
    margin-right: 80px;
  }
  .me-xl-11 {
    margin-right: 88px;
  }
  .me-xl-12 {
    margin-right: 96px;
  }
  .me-xl-auto {
    margin-right: auto;
  }
  .mb-xl-0 {
    margin-bottom: 0;
  }
  .mb-xl-1 {
    margin-bottom: 8px;
  }
  .mb-xl-2 {
    margin-bottom: 16px;
  }
  .mb-xl-3 {
    margin-bottom: 24px;
  }
  .mb-xl-4 {
    margin-bottom: 32px;
  }
  .mb-xl-5 {
    margin-bottom: 40px;
  }
  .mb-xl-6 {
    margin-bottom: 48px;
  }
  .mb-xl-7 {
    margin-bottom: 56px;
  }
  .mb-xl-8 {
    margin-bottom: 64px;
  }
  .mb-xl-9 {
    margin-bottom: 72px;
  }
  .mb-xl-10 {
    margin-bottom: 80px;
  }
  .mb-xl-11 {
    margin-bottom: 88px;
  }
  .mb-xl-12 {
    margin-bottom: 96px;
  }
  .mb-xl-auto {
    margin-bottom: auto;
  }
  .ms-xl-0 {
    margin-left: 0;
  }
  .ms-xl-1 {
    margin-left: 8px;
  }
  .ms-xl-2 {
    margin-left: 16px;
  }
  .ms-xl-3 {
    margin-left: 24px;
  }
  .ms-xl-4 {
    margin-left: 32px;
  }
  .ms-xl-5 {
    margin-left: 40px;
  }
  .ms-xl-6 {
    margin-left: 48px;
  }
  .ms-xl-7 {
    margin-left: 56px;
  }
  .ms-xl-8 {
    margin-left: 64px;
  }
  .ms-xl-9 {
    margin-left: 72px;
  }
  .ms-xl-10 {
    margin-left: 80px;
  }
  .ms-xl-11 {
    margin-left: 88px;
  }
  .ms-xl-12 {
    margin-left: 96px;
  }
  .ms-xl-auto {
    margin-left: auto;
  }
  .p-xl-0 {
    padding: 0;
  }
  .p-xl-1 {
    padding: 8px;
  }
  .p-xl-2 {
    padding: 16px;
  }
  .p-xl-3 {
    padding: 24px;
  }
  .p-xl-4 {
    padding: 32px;
  }
  .p-xl-5 {
    padding: 40px;
  }
  .p-xl-6 {
    padding: 48px;
  }
  .p-xl-7 {
    padding: 56px;
  }
  .p-xl-8 {
    padding: 64px;
  }
  .p-xl-9 {
    padding: 72px;
  }
  .p-xl-10 {
    padding: 80px;
  }
  .p-xl-11 {
    padding: 88px;
  }
  .p-xl-12 {
    padding: 96px;
  }
  .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xl-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-xl-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-xl-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-xl-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-xl-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-xl-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-xl-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-xl-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .px-xl-9 {
    padding-right: 72px;
    padding-left: 72px;
  }
  .px-xl-10 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .px-xl-11 {
    padding-right: 88px;
    padding-left: 88px;
  }
  .px-xl-12 {
    padding-right: 96px;
    padding-left: 96px;
  }
  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xl-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xl-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xl-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xl-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xl-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-xl-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-xl-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-xl-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-xl-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-xl-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .py-xl-11 {
    padding-top: 88px;
    padding-bottom: 88px;
  }
  .py-xl-12 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .pt-xl-0 {
    padding-top: 0;
  }
  .pt-xl-1 {
    padding-top: 8px;
  }
  .pt-xl-2 {
    padding-top: 16px;
  }
  .pt-xl-3 {
    padding-top: 24px;
  }
  .pt-xl-4 {
    padding-top: 32px;
  }
  .pt-xl-5 {
    padding-top: 40px;
  }
  .pt-xl-6 {
    padding-top: 48px;
  }
  .pt-xl-7 {
    padding-top: 56px;
  }
  .pt-xl-8 {
    padding-top: 64px;
  }
  .pt-xl-9 {
    padding-top: 72px;
  }
  .pt-xl-10 {
    padding-top: 80px;
  }
  .pt-xl-11 {
    padding-top: 88px;
  }
  .pt-xl-12 {
    padding-top: 96px;
  }
  .pe-xl-0 {
    padding-right: 0;
  }
  .pe-xl-1 {
    padding-right: 8px;
  }
  .pe-xl-2 {
    padding-right: 16px;
  }
  .pe-xl-3 {
    padding-right: 24px;
  }
  .pe-xl-4 {
    padding-right: 32px;
  }
  .pe-xl-5 {
    padding-right: 40px;
  }
  .pe-xl-6 {
    padding-right: 48px;
  }
  .pe-xl-7 {
    padding-right: 56px;
  }
  .pe-xl-8 {
    padding-right: 64px;
  }
  .pe-xl-9 {
    padding-right: 72px;
  }
  .pe-xl-10 {
    padding-right: 80px;
  }
  .pe-xl-11 {
    padding-right: 88px;
  }
  .pe-xl-12 {
    padding-right: 96px;
  }
  .pb-xl-0 {
    padding-bottom: 0;
  }
  .pb-xl-1 {
    padding-bottom: 8px;
  }
  .pb-xl-2 {
    padding-bottom: 16px;
  }
  .pb-xl-3 {
    padding-bottom: 24px;
  }
  .pb-xl-4 {
    padding-bottom: 32px;
  }
  .pb-xl-5 {
    padding-bottom: 40px;
  }
  .pb-xl-6 {
    padding-bottom: 48px;
  }
  .pb-xl-7 {
    padding-bottom: 56px;
  }
  .pb-xl-8 {
    padding-bottom: 64px;
  }
  .pb-xl-9 {
    padding-bottom: 72px;
  }
  .pb-xl-10 {
    padding-bottom: 80px;
  }
  .pb-xl-11 {
    padding-bottom: 88px;
  }
  .pb-xl-12 {
    padding-bottom: 96px;
  }
  .ps-xl-0 {
    padding-left: 0;
  }
  .ps-xl-1 {
    padding-left: 8px;
  }
  .ps-xl-2 {
    padding-left: 16px;
  }
  .ps-xl-3 {
    padding-left: 24px;
  }
  .ps-xl-4 {
    padding-left: 32px;
  }
  .ps-xl-5 {
    padding-left: 40px;
  }
  .ps-xl-6 {
    padding-left: 48px;
  }
  .ps-xl-7 {
    padding-left: 56px;
  }
  .ps-xl-8 {
    padding-left: 64px;
  }
  .ps-xl-9 {
    padding-left: 72px;
  }
  .ps-xl-10 {
    padding-left: 80px;
  }
  .ps-xl-11 {
    padding-left: 88px;
  }
  .ps-xl-12 {
    padding-left: 96px;
  }
  .gap-xl-0 {
    gap: 0;
  }
  .gap-xl-1 {
    gap: 8px;
  }
  .gap-xl-2 {
    gap: 16px;
  }
  .gap-xl-3 {
    gap: 24px;
  }
  .gap-xl-4 {
    gap: 32px;
  }
  .gap-xl-5 {
    gap: 40px;
  }
  .gap-xl-6 {
    gap: 48px;
  }
  .gap-xl-7 {
    gap: 56px;
  }
  .gap-xl-8 {
    gap: 64px;
  }
  .gap-xl-9 {
    gap: 72px;
  }
  .gap-xl-10 {
    gap: 80px;
  }
  .gap-xl-11 {
    gap: 88px;
  }
  .gap-xl-12 {
    gap: 96px;
  }
  .row-gap-xl-0 {
    row-gap: 0;
  }
  .row-gap-xl-1 {
    row-gap: 8px;
  }
  .row-gap-xl-2 {
    row-gap: 16px;
  }
  .row-gap-xl-3 {
    row-gap: 24px;
  }
  .row-gap-xl-4 {
    row-gap: 32px;
  }
  .row-gap-xl-5 {
    row-gap: 40px;
  }
  .row-gap-xl-6 {
    row-gap: 48px;
  }
  .row-gap-xl-7 {
    row-gap: 56px;
  }
  .row-gap-xl-8 {
    row-gap: 64px;
  }
  .row-gap-xl-9 {
    row-gap: 72px;
  }
  .row-gap-xl-10 {
    row-gap: 80px;
  }
  .row-gap-xl-11 {
    row-gap: 88px;
  }
  .row-gap-xl-12 {
    row-gap: 96px;
  }
  .column-gap-xl-0 {
    column-gap: 0;
  }
  .column-gap-xl-1 {
    column-gap: 8px;
  }
  .column-gap-xl-2 {
    column-gap: 16px;
  }
  .column-gap-xl-3 {
    column-gap: 24px;
  }
  .column-gap-xl-4 {
    column-gap: 32px;
  }
  .column-gap-xl-5 {
    column-gap: 40px;
  }
  .column-gap-xl-6 {
    column-gap: 48px;
  }
  .column-gap-xl-7 {
    column-gap: 56px;
  }
  .column-gap-xl-8 {
    column-gap: 64px;
  }
  .column-gap-xl-9 {
    column-gap: 72px;
  }
  .column-gap-xl-10 {
    column-gap: 80px;
  }
  .column-gap-xl-11 {
    column-gap: 88px;
  }
  .column-gap-xl-12 {
    column-gap: 96px;
  }
  .text-xl-start {
    text-align: left;
  }
  .text-xl-end {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
}
@media (min-width: 1500px) {
  .float-xxl-start {
    float: left;
  }
  .float-xxl-end {
    float: right;
  }
  .float-xxl-none {
    float: none;
  }
  .object-fit-xxl-contain {
    object-fit: contain;
  }
  .object-fit-xxl-cover {
    object-fit: cover;
  }
  .object-fit-xxl-fill {
    object-fit: fill;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down;
  }
  .object-fit-xxl-none {
    object-fit: none;
  }
  .d-xxl-inline {
    display: inline;
  }
  .d-xxl-inline-block {
    display: inline-block;
  }
  .d-xxl-block {
    display: block;
  }
  .d-xxl-grid {
    display: grid;
  }
  .d-xxl-inline-grid {
    display: inline-grid;
  }
  .d-xxl-table {
    display: table;
  }
  .d-xxl-table-row {
    display: table-row;
  }
  .d-xxl-table-cell {
    display: table-cell;
  }
  .d-xxl-flex {
    display: flex;
  }
  .d-xxl-inline-flex {
    display: inline-flex;
  }
  .d-xxl-none {
    display: none;
  }
  .flex-xxl-fill {
    flex: 1 1 auto;
  }
  .flex-xxl-row {
    flex-direction: row;
  }
  .flex-xxl-column {
    flex-direction: column;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xxl-start {
    justify-content: flex-start;
  }
  .justify-content-xxl-end {
    justify-content: flex-end;
  }
  .justify-content-xxl-center {
    justify-content: center;
  }
  .justify-content-xxl-between {
    justify-content: space-between;
  }
  .justify-content-xxl-around {
    justify-content: space-around;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xxl-start {
    align-items: flex-start;
  }
  .align-items-xxl-end {
    align-items: flex-end;
  }
  .align-items-xxl-center {
    align-items: center;
  }
  .align-items-xxl-baseline {
    align-items: baseline;
  }
  .align-items-xxl-stretch {
    align-items: stretch;
  }
  .align-content-xxl-start {
    align-content: flex-start;
  }
  .align-content-xxl-end {
    align-content: flex-end;
  }
  .align-content-xxl-center {
    align-content: center;
  }
  .align-content-xxl-between {
    align-content: space-between;
  }
  .align-content-xxl-around {
    align-content: space-around;
  }
  .align-content-xxl-stretch {
    align-content: stretch;
  }
  .align-self-xxl-auto {
    align-self: auto;
  }
  .align-self-xxl-start {
    align-self: flex-start;
  }
  .align-self-xxl-end {
    align-self: flex-end;
  }
  .align-self-xxl-center {
    align-self: center;
  }
  .align-self-xxl-baseline {
    align-self: baseline;
  }
  .align-self-xxl-stretch {
    align-self: stretch;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-last {
    order: 6;
  }
  .m-xxl-0 {
    margin: 0;
  }
  .m-xxl-1 {
    margin: 8px;
  }
  .m-xxl-2 {
    margin: 16px;
  }
  .m-xxl-3 {
    margin: 24px;
  }
  .m-xxl-4 {
    margin: 32px;
  }
  .m-xxl-5 {
    margin: 40px;
  }
  .m-xxl-6 {
    margin: 48px;
  }
  .m-xxl-7 {
    margin: 56px;
  }
  .m-xxl-8 {
    margin: 64px;
  }
  .m-xxl-9 {
    margin: 72px;
  }
  .m-xxl-10 {
    margin: 80px;
  }
  .m-xxl-11 {
    margin: 88px;
  }
  .m-xxl-12 {
    margin: 96px;
  }
  .m-xxl-auto {
    margin: auto;
  }
  .mx-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xxl-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-xxl-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-xxl-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-xxl-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-xxl-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-xxl-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-xxl-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-xxl-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-xxl-9 {
    margin-right: 72px;
    margin-left: 72px;
  }
  .mx-xxl-10 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mx-xxl-11 {
    margin-right: 88px;
    margin-left: 88px;
  }
  .mx-xxl-12 {
    margin-right: 96px;
    margin-left: 96px;
  }
  .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xxl-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xxl-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xxl-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xxl-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xxl-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-xxl-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xxl-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-xxl-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-xxl-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-xxl-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .my-xxl-11 {
    margin-top: 88px;
    margin-bottom: 88px;
  }
  .my-xxl-12 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xxl-0 {
    margin-top: 0;
  }
  .mt-xxl-1 {
    margin-top: 8px;
  }
  .mt-xxl-2 {
    margin-top: 16px;
  }
  .mt-xxl-3 {
    margin-top: 24px;
  }
  .mt-xxl-4 {
    margin-top: 32px;
  }
  .mt-xxl-5 {
    margin-top: 40px;
  }
  .mt-xxl-6 {
    margin-top: 48px;
  }
  .mt-xxl-7 {
    margin-top: 56px;
  }
  .mt-xxl-8 {
    margin-top: 64px;
  }
  .mt-xxl-9 {
    margin-top: 72px;
  }
  .mt-xxl-10 {
    margin-top: 80px;
  }
  .mt-xxl-11 {
    margin-top: 88px;
  }
  .mt-xxl-12 {
    margin-top: 96px;
  }
  .mt-xxl-auto {
    margin-top: auto;
  }
  .me-xxl-0 {
    margin-right: 0;
  }
  .me-xxl-1 {
    margin-right: 8px;
  }
  .me-xxl-2 {
    margin-right: 16px;
  }
  .me-xxl-3 {
    margin-right: 24px;
  }
  .me-xxl-4 {
    margin-right: 32px;
  }
  .me-xxl-5 {
    margin-right: 40px;
  }
  .me-xxl-6 {
    margin-right: 48px;
  }
  .me-xxl-7 {
    margin-right: 56px;
  }
  .me-xxl-8 {
    margin-right: 64px;
  }
  .me-xxl-9 {
    margin-right: 72px;
  }
  .me-xxl-10 {
    margin-right: 80px;
  }
  .me-xxl-11 {
    margin-right: 88px;
  }
  .me-xxl-12 {
    margin-right: 96px;
  }
  .me-xxl-auto {
    margin-right: auto;
  }
  .mb-xxl-0 {
    margin-bottom: 0;
  }
  .mb-xxl-1 {
    margin-bottom: 8px;
  }
  .mb-xxl-2 {
    margin-bottom: 16px;
  }
  .mb-xxl-3 {
    margin-bottom: 24px;
  }
  .mb-xxl-4 {
    margin-bottom: 32px;
  }
  .mb-xxl-5 {
    margin-bottom: 40px;
  }
  .mb-xxl-6 {
    margin-bottom: 48px;
  }
  .mb-xxl-7 {
    margin-bottom: 56px;
  }
  .mb-xxl-8 {
    margin-bottom: 64px;
  }
  .mb-xxl-9 {
    margin-bottom: 72px;
  }
  .mb-xxl-10 {
    margin-bottom: 80px;
  }
  .mb-xxl-11 {
    margin-bottom: 88px;
  }
  .mb-xxl-12 {
    margin-bottom: 96px;
  }
  .mb-xxl-auto {
    margin-bottom: auto;
  }
  .ms-xxl-0 {
    margin-left: 0;
  }
  .ms-xxl-1 {
    margin-left: 8px;
  }
  .ms-xxl-2 {
    margin-left: 16px;
  }
  .ms-xxl-3 {
    margin-left: 24px;
  }
  .ms-xxl-4 {
    margin-left: 32px;
  }
  .ms-xxl-5 {
    margin-left: 40px;
  }
  .ms-xxl-6 {
    margin-left: 48px;
  }
  .ms-xxl-7 {
    margin-left: 56px;
  }
  .ms-xxl-8 {
    margin-left: 64px;
  }
  .ms-xxl-9 {
    margin-left: 72px;
  }
  .ms-xxl-10 {
    margin-left: 80px;
  }
  .ms-xxl-11 {
    margin-left: 88px;
  }
  .ms-xxl-12 {
    margin-left: 96px;
  }
  .ms-xxl-auto {
    margin-left: auto;
  }
  .p-xxl-0 {
    padding: 0;
  }
  .p-xxl-1 {
    padding: 8px;
  }
  .p-xxl-2 {
    padding: 16px;
  }
  .p-xxl-3 {
    padding: 24px;
  }
  .p-xxl-4 {
    padding: 32px;
  }
  .p-xxl-5 {
    padding: 40px;
  }
  .p-xxl-6 {
    padding: 48px;
  }
  .p-xxl-7 {
    padding: 56px;
  }
  .p-xxl-8 {
    padding: 64px;
  }
  .p-xxl-9 {
    padding: 72px;
  }
  .p-xxl-10 {
    padding: 80px;
  }
  .p-xxl-11 {
    padding: 88px;
  }
  .p-xxl-12 {
    padding: 96px;
  }
  .px-xxl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xxl-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-xxl-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-xxl-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-xxl-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-xxl-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-xxl-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-xxl-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-xxl-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .px-xxl-9 {
    padding-right: 72px;
    padding-left: 72px;
  }
  .px-xxl-10 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .px-xxl-11 {
    padding-right: 88px;
    padding-left: 88px;
  }
  .px-xxl-12 {
    padding-right: 96px;
    padding-left: 96px;
  }
  .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xxl-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xxl-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xxl-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xxl-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xxl-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-xxl-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-xxl-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-xxl-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-xxl-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-xxl-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .py-xxl-11 {
    padding-top: 88px;
    padding-bottom: 88px;
  }
  .py-xxl-12 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .pt-xxl-0 {
    padding-top: 0;
  }
  .pt-xxl-1 {
    padding-top: 8px;
  }
  .pt-xxl-2 {
    padding-top: 16px;
  }
  .pt-xxl-3 {
    padding-top: 24px;
  }
  .pt-xxl-4 {
    padding-top: 32px;
  }
  .pt-xxl-5 {
    padding-top: 40px;
  }
  .pt-xxl-6 {
    padding-top: 48px;
  }
  .pt-xxl-7 {
    padding-top: 56px;
  }
  .pt-xxl-8 {
    padding-top: 64px;
  }
  .pt-xxl-9 {
    padding-top: 72px;
  }
  .pt-xxl-10 {
    padding-top: 80px;
  }
  .pt-xxl-11 {
    padding-top: 88px;
  }
  .pt-xxl-12 {
    padding-top: 96px;
  }
  .pe-xxl-0 {
    padding-right: 0;
  }
  .pe-xxl-1 {
    padding-right: 8px;
  }
  .pe-xxl-2 {
    padding-right: 16px;
  }
  .pe-xxl-3 {
    padding-right: 24px;
  }
  .pe-xxl-4 {
    padding-right: 32px;
  }
  .pe-xxl-5 {
    padding-right: 40px;
  }
  .pe-xxl-6 {
    padding-right: 48px;
  }
  .pe-xxl-7 {
    padding-right: 56px;
  }
  .pe-xxl-8 {
    padding-right: 64px;
  }
  .pe-xxl-9 {
    padding-right: 72px;
  }
  .pe-xxl-10 {
    padding-right: 80px;
  }
  .pe-xxl-11 {
    padding-right: 88px;
  }
  .pe-xxl-12 {
    padding-right: 96px;
  }
  .pb-xxl-0 {
    padding-bottom: 0;
  }
  .pb-xxl-1 {
    padding-bottom: 8px;
  }
  .pb-xxl-2 {
    padding-bottom: 16px;
  }
  .pb-xxl-3 {
    padding-bottom: 24px;
  }
  .pb-xxl-4 {
    padding-bottom: 32px;
  }
  .pb-xxl-5 {
    padding-bottom: 40px;
  }
  .pb-xxl-6 {
    padding-bottom: 48px;
  }
  .pb-xxl-7 {
    padding-bottom: 56px;
  }
  .pb-xxl-8 {
    padding-bottom: 64px;
  }
  .pb-xxl-9 {
    padding-bottom: 72px;
  }
  .pb-xxl-10 {
    padding-bottom: 80px;
  }
  .pb-xxl-11 {
    padding-bottom: 88px;
  }
  .pb-xxl-12 {
    padding-bottom: 96px;
  }
  .ps-xxl-0 {
    padding-left: 0;
  }
  .ps-xxl-1 {
    padding-left: 8px;
  }
  .ps-xxl-2 {
    padding-left: 16px;
  }
  .ps-xxl-3 {
    padding-left: 24px;
  }
  .ps-xxl-4 {
    padding-left: 32px;
  }
  .ps-xxl-5 {
    padding-left: 40px;
  }
  .ps-xxl-6 {
    padding-left: 48px;
  }
  .ps-xxl-7 {
    padding-left: 56px;
  }
  .ps-xxl-8 {
    padding-left: 64px;
  }
  .ps-xxl-9 {
    padding-left: 72px;
  }
  .ps-xxl-10 {
    padding-left: 80px;
  }
  .ps-xxl-11 {
    padding-left: 88px;
  }
  .ps-xxl-12 {
    padding-left: 96px;
  }
  .gap-xxl-0 {
    gap: 0;
  }
  .gap-xxl-1 {
    gap: 8px;
  }
  .gap-xxl-2 {
    gap: 16px;
  }
  .gap-xxl-3 {
    gap: 24px;
  }
  .gap-xxl-4 {
    gap: 32px;
  }
  .gap-xxl-5 {
    gap: 40px;
  }
  .gap-xxl-6 {
    gap: 48px;
  }
  .gap-xxl-7 {
    gap: 56px;
  }
  .gap-xxl-8 {
    gap: 64px;
  }
  .gap-xxl-9 {
    gap: 72px;
  }
  .gap-xxl-10 {
    gap: 80px;
  }
  .gap-xxl-11 {
    gap: 88px;
  }
  .gap-xxl-12 {
    gap: 96px;
  }
  .row-gap-xxl-0 {
    row-gap: 0;
  }
  .row-gap-xxl-1 {
    row-gap: 8px;
  }
  .row-gap-xxl-2 {
    row-gap: 16px;
  }
  .row-gap-xxl-3 {
    row-gap: 24px;
  }
  .row-gap-xxl-4 {
    row-gap: 32px;
  }
  .row-gap-xxl-5 {
    row-gap: 40px;
  }
  .row-gap-xxl-6 {
    row-gap: 48px;
  }
  .row-gap-xxl-7 {
    row-gap: 56px;
  }
  .row-gap-xxl-8 {
    row-gap: 64px;
  }
  .row-gap-xxl-9 {
    row-gap: 72px;
  }
  .row-gap-xxl-10 {
    row-gap: 80px;
  }
  .row-gap-xxl-11 {
    row-gap: 88px;
  }
  .row-gap-xxl-12 {
    row-gap: 96px;
  }
  .column-gap-xxl-0 {
    column-gap: 0;
  }
  .column-gap-xxl-1 {
    column-gap: 8px;
  }
  .column-gap-xxl-2 {
    column-gap: 16px;
  }
  .column-gap-xxl-3 {
    column-gap: 24px;
  }
  .column-gap-xxl-4 {
    column-gap: 32px;
  }
  .column-gap-xxl-5 {
    column-gap: 40px;
  }
  .column-gap-xxl-6 {
    column-gap: 48px;
  }
  .column-gap-xxl-7 {
    column-gap: 56px;
  }
  .column-gap-xxl-8 {
    column-gap: 64px;
  }
  .column-gap-xxl-9 {
    column-gap: 72px;
  }
  .column-gap-xxl-10 {
    column-gap: 80px;
  }
  .column-gap-xxl-11 {
    column-gap: 88px;
  }
  .column-gap-xxl-12 {
    column-gap: 96px;
  }
  .text-xxl-start {
    text-align: left;
  }
  .text-xxl-end {
    text-align: right;
  }
  .text-xxl-center {
    text-align: center;
  }
}
@media (min-width: 1920px) {
  .float-fullhd-start {
    float: left;
  }
  .float-fullhd-end {
    float: right;
  }
  .float-fullhd-none {
    float: none;
  }
  .object-fit-fullhd-contain {
    object-fit: contain;
  }
  .object-fit-fullhd-cover {
    object-fit: cover;
  }
  .object-fit-fullhd-fill {
    object-fit: fill;
  }
  .object-fit-fullhd-scale {
    object-fit: scale-down;
  }
  .object-fit-fullhd-none {
    object-fit: none;
  }
  .d-fullhd-inline {
    display: inline;
  }
  .d-fullhd-inline-block {
    display: inline-block;
  }
  .d-fullhd-block {
    display: block;
  }
  .d-fullhd-grid {
    display: grid;
  }
  .d-fullhd-inline-grid {
    display: inline-grid;
  }
  .d-fullhd-table {
    display: table;
  }
  .d-fullhd-table-row {
    display: table-row;
  }
  .d-fullhd-table-cell {
    display: table-cell;
  }
  .d-fullhd-flex {
    display: flex;
  }
  .d-fullhd-inline-flex {
    display: inline-flex;
  }
  .d-fullhd-none {
    display: none;
  }
  .flex-fullhd-fill {
    flex: 1 1 auto;
  }
  .flex-fullhd-row {
    flex-direction: row;
  }
  .flex-fullhd-column {
    flex-direction: column;
  }
  .flex-fullhd-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-fullhd-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-fullhd-grow-0 {
    flex-grow: 0;
  }
  .flex-fullhd-grow-1 {
    flex-grow: 1;
  }
  .flex-fullhd-shrink-0 {
    flex-shrink: 0;
  }
  .flex-fullhd-shrink-1 {
    flex-shrink: 1;
  }
  .flex-fullhd-wrap {
    flex-wrap: wrap;
  }
  .flex-fullhd-nowrap {
    flex-wrap: nowrap;
  }
  .flex-fullhd-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-fullhd-start {
    justify-content: flex-start;
  }
  .justify-content-fullhd-end {
    justify-content: flex-end;
  }
  .justify-content-fullhd-center {
    justify-content: center;
  }
  .justify-content-fullhd-between {
    justify-content: space-between;
  }
  .justify-content-fullhd-around {
    justify-content: space-around;
  }
  .justify-content-fullhd-evenly {
    justify-content: space-evenly;
  }
  .align-items-fullhd-start {
    align-items: flex-start;
  }
  .align-items-fullhd-end {
    align-items: flex-end;
  }
  .align-items-fullhd-center {
    align-items: center;
  }
  .align-items-fullhd-baseline {
    align-items: baseline;
  }
  .align-items-fullhd-stretch {
    align-items: stretch;
  }
  .align-content-fullhd-start {
    align-content: flex-start;
  }
  .align-content-fullhd-end {
    align-content: flex-end;
  }
  .align-content-fullhd-center {
    align-content: center;
  }
  .align-content-fullhd-between {
    align-content: space-between;
  }
  .align-content-fullhd-around {
    align-content: space-around;
  }
  .align-content-fullhd-stretch {
    align-content: stretch;
  }
  .align-self-fullhd-auto {
    align-self: auto;
  }
  .align-self-fullhd-start {
    align-self: flex-start;
  }
  .align-self-fullhd-end {
    align-self: flex-end;
  }
  .align-self-fullhd-center {
    align-self: center;
  }
  .align-self-fullhd-baseline {
    align-self: baseline;
  }
  .align-self-fullhd-stretch {
    align-self: stretch;
  }
  .order-fullhd-first {
    order: -1;
  }
  .order-fullhd-0 {
    order: 0;
  }
  .order-fullhd-1 {
    order: 1;
  }
  .order-fullhd-2 {
    order: 2;
  }
  .order-fullhd-3 {
    order: 3;
  }
  .order-fullhd-4 {
    order: 4;
  }
  .order-fullhd-5 {
    order: 5;
  }
  .order-fullhd-last {
    order: 6;
  }
  .m-fullhd-0 {
    margin: 0;
  }
  .m-fullhd-1 {
    margin: 8px;
  }
  .m-fullhd-2 {
    margin: 16px;
  }
  .m-fullhd-3 {
    margin: 24px;
  }
  .m-fullhd-4 {
    margin: 32px;
  }
  .m-fullhd-5 {
    margin: 40px;
  }
  .m-fullhd-6 {
    margin: 48px;
  }
  .m-fullhd-7 {
    margin: 56px;
  }
  .m-fullhd-8 {
    margin: 64px;
  }
  .m-fullhd-9 {
    margin: 72px;
  }
  .m-fullhd-10 {
    margin: 80px;
  }
  .m-fullhd-11 {
    margin: 88px;
  }
  .m-fullhd-12 {
    margin: 96px;
  }
  .m-fullhd-auto {
    margin: auto;
  }
  .mx-fullhd-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-fullhd-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-fullhd-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-fullhd-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-fullhd-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-fullhd-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-fullhd-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-fullhd-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-fullhd-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-fullhd-9 {
    margin-right: 72px;
    margin-left: 72px;
  }
  .mx-fullhd-10 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mx-fullhd-11 {
    margin-right: 88px;
    margin-left: 88px;
  }
  .mx-fullhd-12 {
    margin-right: 96px;
    margin-left: 96px;
  }
  .mx-fullhd-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-fullhd-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-fullhd-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-fullhd-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-fullhd-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-fullhd-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-fullhd-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-fullhd-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-fullhd-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-fullhd-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-fullhd-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-fullhd-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .my-fullhd-11 {
    margin-top: 88px;
    margin-bottom: 88px;
  }
  .my-fullhd-12 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .my-fullhd-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-fullhd-0 {
    margin-top: 0;
  }
  .mt-fullhd-1 {
    margin-top: 8px;
  }
  .mt-fullhd-2 {
    margin-top: 16px;
  }
  .mt-fullhd-3 {
    margin-top: 24px;
  }
  .mt-fullhd-4 {
    margin-top: 32px;
  }
  .mt-fullhd-5 {
    margin-top: 40px;
  }
  .mt-fullhd-6 {
    margin-top: 48px;
  }
  .mt-fullhd-7 {
    margin-top: 56px;
  }
  .mt-fullhd-8 {
    margin-top: 64px;
  }
  .mt-fullhd-9 {
    margin-top: 72px;
  }
  .mt-fullhd-10 {
    margin-top: 80px;
  }
  .mt-fullhd-11 {
    margin-top: 88px;
  }
  .mt-fullhd-12 {
    margin-top: 96px;
  }
  .mt-fullhd-auto {
    margin-top: auto;
  }
  .me-fullhd-0 {
    margin-right: 0;
  }
  .me-fullhd-1 {
    margin-right: 8px;
  }
  .me-fullhd-2 {
    margin-right: 16px;
  }
  .me-fullhd-3 {
    margin-right: 24px;
  }
  .me-fullhd-4 {
    margin-right: 32px;
  }
  .me-fullhd-5 {
    margin-right: 40px;
  }
  .me-fullhd-6 {
    margin-right: 48px;
  }
  .me-fullhd-7 {
    margin-right: 56px;
  }
  .me-fullhd-8 {
    margin-right: 64px;
  }
  .me-fullhd-9 {
    margin-right: 72px;
  }
  .me-fullhd-10 {
    margin-right: 80px;
  }
  .me-fullhd-11 {
    margin-right: 88px;
  }
  .me-fullhd-12 {
    margin-right: 96px;
  }
  .me-fullhd-auto {
    margin-right: auto;
  }
  .mb-fullhd-0 {
    margin-bottom: 0;
  }
  .mb-fullhd-1 {
    margin-bottom: 8px;
  }
  .mb-fullhd-2 {
    margin-bottom: 16px;
  }
  .mb-fullhd-3 {
    margin-bottom: 24px;
  }
  .mb-fullhd-4 {
    margin-bottom: 32px;
  }
  .mb-fullhd-5 {
    margin-bottom: 40px;
  }
  .mb-fullhd-6 {
    margin-bottom: 48px;
  }
  .mb-fullhd-7 {
    margin-bottom: 56px;
  }
  .mb-fullhd-8 {
    margin-bottom: 64px;
  }
  .mb-fullhd-9 {
    margin-bottom: 72px;
  }
  .mb-fullhd-10 {
    margin-bottom: 80px;
  }
  .mb-fullhd-11 {
    margin-bottom: 88px;
  }
  .mb-fullhd-12 {
    margin-bottom: 96px;
  }
  .mb-fullhd-auto {
    margin-bottom: auto;
  }
  .ms-fullhd-0 {
    margin-left: 0;
  }
  .ms-fullhd-1 {
    margin-left: 8px;
  }
  .ms-fullhd-2 {
    margin-left: 16px;
  }
  .ms-fullhd-3 {
    margin-left: 24px;
  }
  .ms-fullhd-4 {
    margin-left: 32px;
  }
  .ms-fullhd-5 {
    margin-left: 40px;
  }
  .ms-fullhd-6 {
    margin-left: 48px;
  }
  .ms-fullhd-7 {
    margin-left: 56px;
  }
  .ms-fullhd-8 {
    margin-left: 64px;
  }
  .ms-fullhd-9 {
    margin-left: 72px;
  }
  .ms-fullhd-10 {
    margin-left: 80px;
  }
  .ms-fullhd-11 {
    margin-left: 88px;
  }
  .ms-fullhd-12 {
    margin-left: 96px;
  }
  .ms-fullhd-auto {
    margin-left: auto;
  }
  .p-fullhd-0 {
    padding: 0;
  }
  .p-fullhd-1 {
    padding: 8px;
  }
  .p-fullhd-2 {
    padding: 16px;
  }
  .p-fullhd-3 {
    padding: 24px;
  }
  .p-fullhd-4 {
    padding: 32px;
  }
  .p-fullhd-5 {
    padding: 40px;
  }
  .p-fullhd-6 {
    padding: 48px;
  }
  .p-fullhd-7 {
    padding: 56px;
  }
  .p-fullhd-8 {
    padding: 64px;
  }
  .p-fullhd-9 {
    padding: 72px;
  }
  .p-fullhd-10 {
    padding: 80px;
  }
  .p-fullhd-11 {
    padding: 88px;
  }
  .p-fullhd-12 {
    padding: 96px;
  }
  .px-fullhd-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-fullhd-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-fullhd-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-fullhd-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-fullhd-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-fullhd-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-fullhd-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-fullhd-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-fullhd-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .px-fullhd-9 {
    padding-right: 72px;
    padding-left: 72px;
  }
  .px-fullhd-10 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .px-fullhd-11 {
    padding-right: 88px;
    padding-left: 88px;
  }
  .px-fullhd-12 {
    padding-right: 96px;
    padding-left: 96px;
  }
  .py-fullhd-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-fullhd-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-fullhd-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-fullhd-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-fullhd-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-fullhd-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-fullhd-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-fullhd-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-fullhd-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-fullhd-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-fullhd-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .py-fullhd-11 {
    padding-top: 88px;
    padding-bottom: 88px;
  }
  .py-fullhd-12 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .pt-fullhd-0 {
    padding-top: 0;
  }
  .pt-fullhd-1 {
    padding-top: 8px;
  }
  .pt-fullhd-2 {
    padding-top: 16px;
  }
  .pt-fullhd-3 {
    padding-top: 24px;
  }
  .pt-fullhd-4 {
    padding-top: 32px;
  }
  .pt-fullhd-5 {
    padding-top: 40px;
  }
  .pt-fullhd-6 {
    padding-top: 48px;
  }
  .pt-fullhd-7 {
    padding-top: 56px;
  }
  .pt-fullhd-8 {
    padding-top: 64px;
  }
  .pt-fullhd-9 {
    padding-top: 72px;
  }
  .pt-fullhd-10 {
    padding-top: 80px;
  }
  .pt-fullhd-11 {
    padding-top: 88px;
  }
  .pt-fullhd-12 {
    padding-top: 96px;
  }
  .pe-fullhd-0 {
    padding-right: 0;
  }
  .pe-fullhd-1 {
    padding-right: 8px;
  }
  .pe-fullhd-2 {
    padding-right: 16px;
  }
  .pe-fullhd-3 {
    padding-right: 24px;
  }
  .pe-fullhd-4 {
    padding-right: 32px;
  }
  .pe-fullhd-5 {
    padding-right: 40px;
  }
  .pe-fullhd-6 {
    padding-right: 48px;
  }
  .pe-fullhd-7 {
    padding-right: 56px;
  }
  .pe-fullhd-8 {
    padding-right: 64px;
  }
  .pe-fullhd-9 {
    padding-right: 72px;
  }
  .pe-fullhd-10 {
    padding-right: 80px;
  }
  .pe-fullhd-11 {
    padding-right: 88px;
  }
  .pe-fullhd-12 {
    padding-right: 96px;
  }
  .pb-fullhd-0 {
    padding-bottom: 0;
  }
  .pb-fullhd-1 {
    padding-bottom: 8px;
  }
  .pb-fullhd-2 {
    padding-bottom: 16px;
  }
  .pb-fullhd-3 {
    padding-bottom: 24px;
  }
  .pb-fullhd-4 {
    padding-bottom: 32px;
  }
  .pb-fullhd-5 {
    padding-bottom: 40px;
  }
  .pb-fullhd-6 {
    padding-bottom: 48px;
  }
  .pb-fullhd-7 {
    padding-bottom: 56px;
  }
  .pb-fullhd-8 {
    padding-bottom: 64px;
  }
  .pb-fullhd-9 {
    padding-bottom: 72px;
  }
  .pb-fullhd-10 {
    padding-bottom: 80px;
  }
  .pb-fullhd-11 {
    padding-bottom: 88px;
  }
  .pb-fullhd-12 {
    padding-bottom: 96px;
  }
  .ps-fullhd-0 {
    padding-left: 0;
  }
  .ps-fullhd-1 {
    padding-left: 8px;
  }
  .ps-fullhd-2 {
    padding-left: 16px;
  }
  .ps-fullhd-3 {
    padding-left: 24px;
  }
  .ps-fullhd-4 {
    padding-left: 32px;
  }
  .ps-fullhd-5 {
    padding-left: 40px;
  }
  .ps-fullhd-6 {
    padding-left: 48px;
  }
  .ps-fullhd-7 {
    padding-left: 56px;
  }
  .ps-fullhd-8 {
    padding-left: 64px;
  }
  .ps-fullhd-9 {
    padding-left: 72px;
  }
  .ps-fullhd-10 {
    padding-left: 80px;
  }
  .ps-fullhd-11 {
    padding-left: 88px;
  }
  .ps-fullhd-12 {
    padding-left: 96px;
  }
  .gap-fullhd-0 {
    gap: 0;
  }
  .gap-fullhd-1 {
    gap: 8px;
  }
  .gap-fullhd-2 {
    gap: 16px;
  }
  .gap-fullhd-3 {
    gap: 24px;
  }
  .gap-fullhd-4 {
    gap: 32px;
  }
  .gap-fullhd-5 {
    gap: 40px;
  }
  .gap-fullhd-6 {
    gap: 48px;
  }
  .gap-fullhd-7 {
    gap: 56px;
  }
  .gap-fullhd-8 {
    gap: 64px;
  }
  .gap-fullhd-9 {
    gap: 72px;
  }
  .gap-fullhd-10 {
    gap: 80px;
  }
  .gap-fullhd-11 {
    gap: 88px;
  }
  .gap-fullhd-12 {
    gap: 96px;
  }
  .row-gap-fullhd-0 {
    row-gap: 0;
  }
  .row-gap-fullhd-1 {
    row-gap: 8px;
  }
  .row-gap-fullhd-2 {
    row-gap: 16px;
  }
  .row-gap-fullhd-3 {
    row-gap: 24px;
  }
  .row-gap-fullhd-4 {
    row-gap: 32px;
  }
  .row-gap-fullhd-5 {
    row-gap: 40px;
  }
  .row-gap-fullhd-6 {
    row-gap: 48px;
  }
  .row-gap-fullhd-7 {
    row-gap: 56px;
  }
  .row-gap-fullhd-8 {
    row-gap: 64px;
  }
  .row-gap-fullhd-9 {
    row-gap: 72px;
  }
  .row-gap-fullhd-10 {
    row-gap: 80px;
  }
  .row-gap-fullhd-11 {
    row-gap: 88px;
  }
  .row-gap-fullhd-12 {
    row-gap: 96px;
  }
  .column-gap-fullhd-0 {
    column-gap: 0;
  }
  .column-gap-fullhd-1 {
    column-gap: 8px;
  }
  .column-gap-fullhd-2 {
    column-gap: 16px;
  }
  .column-gap-fullhd-3 {
    column-gap: 24px;
  }
  .column-gap-fullhd-4 {
    column-gap: 32px;
  }
  .column-gap-fullhd-5 {
    column-gap: 40px;
  }
  .column-gap-fullhd-6 {
    column-gap: 48px;
  }
  .column-gap-fullhd-7 {
    column-gap: 56px;
  }
  .column-gap-fullhd-8 {
    column-gap: 64px;
  }
  .column-gap-fullhd-9 {
    column-gap: 72px;
  }
  .column-gap-fullhd-10 {
    column-gap: 80px;
  }
  .column-gap-fullhd-11 {
    column-gap: 88px;
  }
  .column-gap-fullhd-12 {
    column-gap: 96px;
  }
  .text-fullhd-start {
    text-align: left;
  }
  .text-fullhd-end {
    text-align: right;
  }
  .text-fullhd-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem;
  }
  .fs-2 {
    font-size: 2rem;
  }
  .fs-3 {
    font-size: 1.75rem;
  }
  .fs-4 {
    font-size: 1.5rem;
  }
}
@media print {
  .d-print-inline {
    display: inline;
  }
  .d-print-inline-block {
    display: inline-block;
  }
  .d-print-block {
    display: block;
  }
  .d-print-grid {
    display: grid;
  }
  .d-print-inline-grid {
    display: inline-grid;
  }
  .d-print-table {
    display: table;
  }
  .d-print-table-row {
    display: table-row;
  }
  .d-print-table-cell {
    display: table-cell;
  }
  .d-print-flex {
    display: flex;
  }
  .d-print-inline-flex {
    display: inline-flex;
  }
  .d-print-none {
    display: none;
  }
}
/*! purgecss start ignore */
:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.container {
  padding-right: var(--spacing-container-padding);
  padding-left: var(--spacing-container-padding);
}

.honung {
  display: none;
}

.swiper.swiper-horizontal {
  overflow: visible;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

.swiper-slide-visible {
  opacity: 1;
}

.swiper-buttons {
  position: absolute;
  width: calc(100% + 166px);
  max-width: 100vw;
  padding: 0 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  pointer-events: none;
}

.swiper-button {
  pointer-events: auto;
}
.swiper-button .icon {
  flex: 0 0 auto;
  height: auto;
  max-width: none;
}

.swiper-button-prev {
  float: left;
}

.swiper-button-next {
  float: right;
}

.swiper-button-disabled {
  opacity: 0;
}

.ts-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ts-hidden-accessible {
  clip-path: inset(50%) !important;
  width: 1px !important;
  white-space: nowrap !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.ts-control {
  flex: 0 1 auto;
  width: 100%;
  background: none;
  border: none;
  box-shadow: none;
  color: inherit;
}
.ts-control::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.ts-control .item {
  color: inherit;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: calc(100% - 40px);
}
.ts-control input {
  display: none;
}

.ts-dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 8px;
  z-index: 10;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--secondary-200);
  background: #fff;
  overflow: hidden;
}
.ts-dropdown .option {
  padding: 8px 16px;
  cursor: pointer;
}
.ts-dropdown .option.active {
  background: var(--primary-100);
}

:root {
  --bs-body-line-height: 24px;
}

body {
  font-family: "Merriweather Sans", sans-serif;
  color: var(--primary-800);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale !important;
}

figure {
  margin: 0;
}

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-bottom: 0;
  font-weight: 700;
}

h1, .h1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 800;
}

h2, .h2 {
  font-size: 32px;
  line-height: 40px;
}

h3, .h3 {
  font-size: 28px;
  line-height: 40px;
}

h4, .h4 {
  font-size: 24px;
  line-height: 32px;
}

h5, .h5 {
  font-size: 20px;
  line-height: 24px;
}

h6, .h6 {
  font-size: 20px;
  line-height: 24px;
}

html {
  scroll-behavior: smooth;
}

iframe {
  display: block;
  max-width: 100%;
  border-radius: 8px;
}

img {
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease;
  height: auto;
  max-width: 100%;
}
img[data-src], img[data-srcset] {
  display: block;
  min-height: 1px;
  min-width: 1px;
}
img[data-src]:not(.loaded) {
  opacity: 0;
}

a {
  display: inline-block;
  color: var(--primary-500);
  transition: text-decoration-color 0.3s ease;
  text-decoration: underline;
  text-decoration-color: currentcolor;
}
a:hover, a:focus {
  text-decoration-color: transparent;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

main {
  padding-top: 104px;
  overflow: hidden;
}
@media (max-width: 575.98px) {
  main {
    padding-top: 80px;
  }
}

strong,
b {
  font-weight: 700;
}

.entry-content .column {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 24px;
}
.entry-content .column img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  float: none !important;
}
@media (max-width: 991.98px) {
  .entry-content .column h2:first-of-type, .entry-content .column .h2:first-of-type,
  .entry-content .column h3:first-of-type,
  .entry-content .column .h3:first-of-type,
  .entry-content .column h4:first-of-type,
  .entry-content .column .h4:first-of-type,
  .entry-content .column h5:first-of-type,
  .entry-content .column .h5:first-of-type,
  .entry-content .column h6:first-of-type,
  .entry-content .column .h6:first-of-type {
    margin-top: 0;
  }
}
.entry-content .columns-1 {
  display: block;
}
.entry-content .columns-1 .column {
  display: block;
}
.entry-content .columns-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
@media (max-width: 991.98px) {
  .entry-content .columns-2 {
    display: flex;
    flex-direction: column;
  }
}
.entry-content .columns-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
@media (max-width: 991.98px) {
  .entry-content .columns-3 {
    display: flex;
    flex-direction: column;
  }
}
.entry-content .columns-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}
@media (max-width: 1199.98px) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 991.98px) {
  .entry-content .columns-4 {
    display: flex;
    flex-direction: column;
  }
}

div.validator {
  height: 0;
  position: absolute;
  top: 100%;
}
div.validator > span.erorr-s {
  position: absolute;
  padding: 4px 8px;
  display: block;
  left: 0;
  top: 4px;
  white-space: nowrap;
  border-radius: 8px;
  color: #fff;
  letter-spacing: -1px;
  font-size: 14px;
  background: #c92a2a;
  z-index: 99;
}
div.validator > span.erorr-s::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: -5px;
  left: 10px;
  border-style: solid;
  border-width: 0 5px 10px;
  border-color: transparent transparent #c92a2a;
}

.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 24px auto;
}
.aligncenter img,
div.aligncenter img {
  margin-inline: auto;
}

.alignright {
  float: right;
  margin: 0 0 0 30px;
}

.alignleft {
  float: left;
  margin: 0 30px 0 0;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

@media (max-width: 575.98px) {
  .alignleft,
  .alignright {
    float: none;
    display: block;
    margin: 0;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}
.u-bg-primary {
  background-color: var(--primary-300);
}

.u-bg-gray {
  background-color: var(--secondary-200);
}

.u-color-primary {
  color: var(--primary-300);
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.u-bottom-divider {
  position: relative;
}
.u-bottom-divider::after {
  content: url("/images/svg/paper-cut.svg");
  position: absolute;
  display: block;
  width: 100%;
  top: 100%;
  transform: translateY(-1px);
  left: 0;
}

.u-top-divider {
  position: relative;
}
.u-top-divider::after {
  content: url("/images/svg/paper-cut.svg");
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
  transform: translateY(-1px);
  left: 0;
}

@media (min-width: 992px) {
  .base-section.--divider, .u-section-bottom-divider {
    padding-bottom: var(--spacing-xl);
    position: relative;
  }
  .base-section.--divider::after, .u-section-bottom-divider::after {
    content: url("/images/svg/paper-cut.svg");
    position: absolute;
    display: block;
    width: 100%;
    top: 100%;
    transform: translateY(-1px);
    left: 0;
  }
}

@media (min-width: 992px) {
  .base-section.--background, .u-section-top-divider {
    position: relative;
  }
  .base-section.--background::after, .u-section-top-divider::after {
    content: url("/images/svg/paper-cut.svg");
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    transform: translateY(-1px);
    left: 0;
  }
}

.u-element-border {
  background: #fff;
  box-shadow: 5px 5px 15px rgba(190, 190, 190, 0.4980392157);
  border: 8px solid #fff;
  border-radius: 8px;
  overflow: hidden;
}

.u-element-scale-hover {
  transition: transform 0.2s ease;
}
.u-element-scale-hover.--left, .--left .u-element-scale-hover {
  transform-origin: left top;
}
.u-element-scale-hover.--right, .--right .u-element-scale-hover {
  transform-origin: right top;
}
.u-element-scale-hover:hover {
  transform: scale(1.025);
}

.u-flex-grid {
  display: grid;
}
@media (min-width: 576px) {
  .u-flex-grid.--sm-1 {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-auto-rows: auto;
  }
  .u-flex-grid.--sm-1 > * {
    grid-column: span 2;
  }
}
@media (min-width: 576px) {
  .u-flex-grid.--sm-2 {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--sm-2 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--sm-2 > *:nth-last-child(n):nth-child(2n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-2 > *:nth-last-child(1):nth-child(2n-1) {
    grid-column-end: calc(2 * -1);
  }
}
@media (min-width: 576px) {
  .u-flex-grid.--sm-3 {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--sm-3 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--sm-3 > *:nth-last-child(n):nth-child(3n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-3 > *:nth-last-child(1):nth-child(3n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--sm-3 > *:nth-last-child(n):nth-child(3n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-3 > *:nth-last-child(1):nth-child(3n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--sm-3 > *:nth-last-child(n):nth-child(3n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-3 > *:nth-last-child(2):nth-child(3n-2) {
    grid-column-end: calc(4 * -1);
  }
}
@media (min-width: 576px) {
  .u-flex-grid.--sm-4 {
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--sm-4 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--sm-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-4 > *:nth-last-child(1):nth-child(4n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--sm-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-4 > *:nth-last-child(1):nth-child(4n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--sm-4 > *:nth-last-child(n):nth-child(4n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-4 > *:nth-last-child(2):nth-child(4n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--sm-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-4 > *:nth-last-child(1):nth-child(4n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--sm-4 > *:nth-last-child(n):nth-child(4n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-4 > *:nth-last-child(2):nth-child(4n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--sm-4 > *:nth-last-child(n):nth-child(4n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-4 > *:nth-last-child(3):nth-child(4n-3) {
    grid-column-end: calc(6 * -1);
  }
}
@media (min-width: 576px) {
  .u-flex-grid.--sm-5 {
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--sm-5 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(1):nth-child(5n-4) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(1):nth-child(5n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(2):nth-child(5n-4) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(1):nth-child(5n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(2):nth-child(5n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(n):nth-child(5n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(3):nth-child(5n-4) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(1):nth-child(5n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(2):nth-child(5n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(n):nth-child(5n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(3):nth-child(5n-3) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(n):nth-child(5n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-5 > *:nth-last-child(4):nth-child(5n-4) {
    grid-column-end: calc(8 * -1);
  }
}
@media (min-width: 576px) {
  .u-flex-grid.--sm-6 {
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--sm-6 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(1):nth-child(6n-5) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(1):nth-child(6n-4) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(2):nth-child(6n-5) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(1):nth-child(6n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(2):nth-child(6n-4) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(3):nth-child(6n-5) {
    grid-column-end: calc(8 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(1):nth-child(6n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(2):nth-child(6n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(3):nth-child(6n-4) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(4):nth-child(6n-5) {
    grid-column-end: calc(9 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(1):nth-child(6n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(2):nth-child(6n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(3):nth-child(6n-3) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(4):nth-child(6n-4) {
    grid-column-end: calc(8 * -1);
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(n):nth-child(6n-4) {
    grid-column-end: auto;
  }
  .u-flex-grid.--sm-6 > *:nth-last-child(5):nth-child(6n-5) {
    grid-column-end: calc(10 * -1);
  }
}
@media (min-width: 768px) {
  .u-flex-grid.--md-1 {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-auto-rows: auto;
  }
  .u-flex-grid.--md-1 > * {
    grid-column: span 2;
  }
}
@media (min-width: 768px) {
  .u-flex-grid.--md-2 {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--md-2 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--md-2 > *:nth-last-child(n):nth-child(2n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-2 > *:nth-last-child(1):nth-child(2n-1) {
    grid-column-end: calc(2 * -1);
  }
}
@media (min-width: 768px) {
  .u-flex-grid.--md-3 {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--md-3 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--md-3 > *:nth-last-child(n):nth-child(3n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-3 > *:nth-last-child(1):nth-child(3n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--md-3 > *:nth-last-child(n):nth-child(3n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-3 > *:nth-last-child(1):nth-child(3n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--md-3 > *:nth-last-child(n):nth-child(3n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-3 > *:nth-last-child(2):nth-child(3n-2) {
    grid-column-end: calc(4 * -1);
  }
}
@media (min-width: 768px) {
  .u-flex-grid.--md-4 {
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--md-4 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--md-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-4 > *:nth-last-child(1):nth-child(4n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--md-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-4 > *:nth-last-child(1):nth-child(4n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--md-4 > *:nth-last-child(n):nth-child(4n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-4 > *:nth-last-child(2):nth-child(4n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--md-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-4 > *:nth-last-child(1):nth-child(4n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--md-4 > *:nth-last-child(n):nth-child(4n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-4 > *:nth-last-child(2):nth-child(4n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--md-4 > *:nth-last-child(n):nth-child(4n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-4 > *:nth-last-child(3):nth-child(4n-3) {
    grid-column-end: calc(6 * -1);
  }
}
@media (min-width: 768px) {
  .u-flex-grid.--md-5 {
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--md-5 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--md-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-5 > *:nth-last-child(1):nth-child(5n-4) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--md-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-5 > *:nth-last-child(1):nth-child(5n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--md-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-5 > *:nth-last-child(2):nth-child(5n-4) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--md-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-5 > *:nth-last-child(1):nth-child(5n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--md-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-5 > *:nth-last-child(2):nth-child(5n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--md-5 > *:nth-last-child(n):nth-child(5n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-5 > *:nth-last-child(3):nth-child(5n-4) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--md-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-5 > *:nth-last-child(1):nth-child(5n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--md-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-5 > *:nth-last-child(2):nth-child(5n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--md-5 > *:nth-last-child(n):nth-child(5n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-5 > *:nth-last-child(3):nth-child(5n-3) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--md-5 > *:nth-last-child(n):nth-child(5n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-5 > *:nth-last-child(4):nth-child(5n-4) {
    grid-column-end: calc(8 * -1);
  }
}
@media (min-width: 768px) {
  .u-flex-grid.--md-6 {
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--md-6 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(1):nth-child(6n-5) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(1):nth-child(6n-4) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(2):nth-child(6n-5) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(1):nth-child(6n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(2):nth-child(6n-4) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(3):nth-child(6n-5) {
    grid-column-end: calc(8 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(1):nth-child(6n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(2):nth-child(6n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(3):nth-child(6n-4) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(4):nth-child(6n-5) {
    grid-column-end: calc(9 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(1):nth-child(6n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(2):nth-child(6n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(3):nth-child(6n-3) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(4):nth-child(6n-4) {
    grid-column-end: calc(8 * -1);
  }
  .u-flex-grid.--md-6 > *:nth-last-child(n):nth-child(6n-4) {
    grid-column-end: auto;
  }
  .u-flex-grid.--md-6 > *:nth-last-child(5):nth-child(6n-5) {
    grid-column-end: calc(10 * -1);
  }
}
@media (min-width: 992px) {
  .u-flex-grid.--lg-1 {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-auto-rows: auto;
  }
  .u-flex-grid.--lg-1 > * {
    grid-column: span 2;
  }
}
@media (min-width: 992px) {
  .u-flex-grid.--lg-2 {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--lg-2 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--lg-2 > *:nth-last-child(n):nth-child(2n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-2 > *:nth-last-child(1):nth-child(2n-1) {
    grid-column-end: calc(2 * -1);
  }
}
@media (min-width: 992px) {
  .u-flex-grid.--lg-3 {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--lg-3 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--lg-3 > *:nth-last-child(n):nth-child(3n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-3 > *:nth-last-child(1):nth-child(3n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--lg-3 > *:nth-last-child(n):nth-child(3n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-3 > *:nth-last-child(1):nth-child(3n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--lg-3 > *:nth-last-child(n):nth-child(3n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-3 > *:nth-last-child(2):nth-child(3n-2) {
    grid-column-end: calc(4 * -1);
  }
}
@media (min-width: 992px) {
  .u-flex-grid.--lg-4 {
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--lg-4 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--lg-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-4 > *:nth-last-child(1):nth-child(4n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--lg-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-4 > *:nth-last-child(1):nth-child(4n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--lg-4 > *:nth-last-child(n):nth-child(4n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-4 > *:nth-last-child(2):nth-child(4n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--lg-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-4 > *:nth-last-child(1):nth-child(4n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--lg-4 > *:nth-last-child(n):nth-child(4n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-4 > *:nth-last-child(2):nth-child(4n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--lg-4 > *:nth-last-child(n):nth-child(4n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-4 > *:nth-last-child(3):nth-child(4n-3) {
    grid-column-end: calc(6 * -1);
  }
}
@media (min-width: 992px) {
  .u-flex-grid.--lg-5 {
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--lg-5 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(1):nth-child(5n-4) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(1):nth-child(5n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(2):nth-child(5n-4) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(1):nth-child(5n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(2):nth-child(5n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(n):nth-child(5n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(3):nth-child(5n-4) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(1):nth-child(5n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(2):nth-child(5n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(n):nth-child(5n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(3):nth-child(5n-3) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(n):nth-child(5n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-5 > *:nth-last-child(4):nth-child(5n-4) {
    grid-column-end: calc(8 * -1);
  }
}
@media (min-width: 992px) {
  .u-flex-grid.--lg-6 {
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--lg-6 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(1):nth-child(6n-5) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(1):nth-child(6n-4) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(2):nth-child(6n-5) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(1):nth-child(6n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(2):nth-child(6n-4) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(3):nth-child(6n-5) {
    grid-column-end: calc(8 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(1):nth-child(6n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(2):nth-child(6n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(3):nth-child(6n-4) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(4):nth-child(6n-5) {
    grid-column-end: calc(9 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(1):nth-child(6n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(2):nth-child(6n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(3):nth-child(6n-3) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(4):nth-child(6n-4) {
    grid-column-end: calc(8 * -1);
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(n):nth-child(6n-4) {
    grid-column-end: auto;
  }
  .u-flex-grid.--lg-6 > *:nth-last-child(5):nth-child(6n-5) {
    grid-column-end: calc(10 * -1);
  }
}
@media (min-width: 1200px) {
  .u-flex-grid.--xl-1 {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-auto-rows: auto;
  }
  .u-flex-grid.--xl-1 > * {
    grid-column: span 2;
  }
}
@media (min-width: 1200px) {
  .u-flex-grid.--xl-2 {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--xl-2 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--xl-2 > *:nth-last-child(n):nth-child(2n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-2 > *:nth-last-child(1):nth-child(2n-1) {
    grid-column-end: calc(2 * -1);
  }
}
@media (min-width: 1200px) {
  .u-flex-grid.--xl-3 {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--xl-3 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--xl-3 > *:nth-last-child(n):nth-child(3n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-3 > *:nth-last-child(1):nth-child(3n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--xl-3 > *:nth-last-child(n):nth-child(3n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-3 > *:nth-last-child(1):nth-child(3n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--xl-3 > *:nth-last-child(n):nth-child(3n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-3 > *:nth-last-child(2):nth-child(3n-2) {
    grid-column-end: calc(4 * -1);
  }
}
@media (min-width: 1200px) {
  .u-flex-grid.--xl-4 {
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--xl-4 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--xl-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-4 > *:nth-last-child(1):nth-child(4n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--xl-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-4 > *:nth-last-child(1):nth-child(4n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--xl-4 > *:nth-last-child(n):nth-child(4n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-4 > *:nth-last-child(2):nth-child(4n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--xl-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-4 > *:nth-last-child(1):nth-child(4n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--xl-4 > *:nth-last-child(n):nth-child(4n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-4 > *:nth-last-child(2):nth-child(4n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--xl-4 > *:nth-last-child(n):nth-child(4n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-4 > *:nth-last-child(3):nth-child(4n-3) {
    grid-column-end: calc(6 * -1);
  }
}
@media (min-width: 1200px) {
  .u-flex-grid.--xl-5 {
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--xl-5 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(1):nth-child(5n-4) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(1):nth-child(5n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(2):nth-child(5n-4) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(1):nth-child(5n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(2):nth-child(5n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(n):nth-child(5n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(3):nth-child(5n-4) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(1):nth-child(5n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(2):nth-child(5n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(n):nth-child(5n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(3):nth-child(5n-3) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(n):nth-child(5n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-5 > *:nth-last-child(4):nth-child(5n-4) {
    grid-column-end: calc(8 * -1);
  }
}
@media (min-width: 1200px) {
  .u-flex-grid.--xl-6 {
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--xl-6 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(1):nth-child(6n-5) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(1):nth-child(6n-4) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(2):nth-child(6n-5) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(1):nth-child(6n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(2):nth-child(6n-4) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(3):nth-child(6n-5) {
    grid-column-end: calc(8 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(1):nth-child(6n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(2):nth-child(6n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(3):nth-child(6n-4) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(4):nth-child(6n-5) {
    grid-column-end: calc(9 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(1):nth-child(6n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(2):nth-child(6n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(3):nth-child(6n-3) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(4):nth-child(6n-4) {
    grid-column-end: calc(8 * -1);
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(n):nth-child(6n-4) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xl-6 > *:nth-last-child(5):nth-child(6n-5) {
    grid-column-end: calc(10 * -1);
  }
}
@media (min-width: 1500px) {
  .u-flex-grid.--xxl-1 {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-auto-rows: auto;
  }
  .u-flex-grid.--xxl-1 > * {
    grid-column: span 2;
  }
}
@media (min-width: 1500px) {
  .u-flex-grid.--xxl-2 {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--xxl-2 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--xxl-2 > *:nth-last-child(n):nth-child(2n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-2 > *:nth-last-child(1):nth-child(2n-1) {
    grid-column-end: calc(2 * -1);
  }
}
@media (min-width: 1500px) {
  .u-flex-grid.--xxl-3 {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--xxl-3 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--xxl-3 > *:nth-last-child(n):nth-child(3n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-3 > *:nth-last-child(1):nth-child(3n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--xxl-3 > *:nth-last-child(n):nth-child(3n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-3 > *:nth-last-child(1):nth-child(3n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--xxl-3 > *:nth-last-child(n):nth-child(3n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-3 > *:nth-last-child(2):nth-child(3n-2) {
    grid-column-end: calc(4 * -1);
  }
}
@media (min-width: 1500px) {
  .u-flex-grid.--xxl-4 {
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--xxl-4 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--xxl-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-4 > *:nth-last-child(1):nth-child(4n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--xxl-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-4 > *:nth-last-child(1):nth-child(4n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--xxl-4 > *:nth-last-child(n):nth-child(4n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-4 > *:nth-last-child(2):nth-child(4n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--xxl-4 > *:nth-last-child(n):nth-child(4n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-4 > *:nth-last-child(1):nth-child(4n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--xxl-4 > *:nth-last-child(n):nth-child(4n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-4 > *:nth-last-child(2):nth-child(4n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--xxl-4 > *:nth-last-child(n):nth-child(4n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-4 > *:nth-last-child(3):nth-child(4n-3) {
    grid-column-end: calc(6 * -1);
  }
}
@media (min-width: 1500px) {
  .u-flex-grid.--xxl-5 {
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--xxl-5 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(1):nth-child(5n-4) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(1):nth-child(5n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(2):nth-child(5n-4) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(1):nth-child(5n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(2):nth-child(5n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(n):nth-child(5n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(3):nth-child(5n-4) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(n):nth-child(5n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(1):nth-child(5n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(n):nth-child(5n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(2):nth-child(5n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(n):nth-child(5n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(3):nth-child(5n-3) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(n):nth-child(5n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-5 > *:nth-last-child(4):nth-child(5n-4) {
    grid-column-end: calc(8 * -1);
  }
}
@media (min-width: 1500px) {
  .u-flex-grid.--xxl-6 {
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 1fr;
  }
  .u-flex-grid.--xxl-6 > * {
    grid-column: span 2;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(1):nth-child(6n-5) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(1):nth-child(6n-4) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(2):nth-child(6n-5) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(1):nth-child(6n-3) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(2):nth-child(6n-4) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(3):nth-child(6n-5) {
    grid-column-end: calc(8 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(1):nth-child(6n-2) {
    grid-column-end: calc(3 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(2):nth-child(6n-3) {
    grid-column-end: calc(5 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(3):nth-child(6n-4) {
    grid-column-end: calc(7 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(4):nth-child(6n-5) {
    grid-column-end: calc(9 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-0) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(1):nth-child(6n-1) {
    grid-column-end: calc(2 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-1) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(2):nth-child(6n-2) {
    grid-column-end: calc(4 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-2) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(3):nth-child(6n-3) {
    grid-column-end: calc(6 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-3) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(4):nth-child(6n-4) {
    grid-column-end: calc(8 * -1);
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(n):nth-child(6n-4) {
    grid-column-end: auto;
  }
  .u-flex-grid.--xxl-6 > *:nth-last-child(5):nth-child(6n-5) {
    grid-column-end: calc(10 * -1);
  }
}

.u-fz-12 {
  font-size: 12px;
}

.u-fz-14 {
  font-size: 14px;
}

.u-fz-16 {
  font-size: 16px;
}

.u-fz-18 {
  font-size: 18px;
}

.u-fz-20 {
  font-size: 20px;
}

.u-fz-24 {
  font-size: 24px;
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

.u-fw-800 {
  font-weight: 800;
}

.u-fw-900 {
  font-weight: 900;
}

.u-img-hover-large, .u-img-hover {
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  border-radius: 8px;
}
.u-img-hover-large img, .u-img-hover img {
  transition-property: opacity, transform;
}

/* stylelint-disable no-descending-specificity */
.u-img-hover:hover img {
  transform: scale(1.07);
}
.u-img-hover:hover .icon img {
  transform: none;
}

.u-img-hover-large:hover img {
  transform: scale(1.15);
}
.u-img-hover-large:hover .icon img {
  transform: none;
}

/* stylelint-enable no-descending-specificity */
.--loading {
  position: relative;
  pointer-events: none;
}
.--loading::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-200);
  z-index: 9000;
  transition: opacity 0.3s ease;
  bottom: 0;
  left: 0;
  opacity: 0.5;
}
.--loading::after {
  content: "";
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  margin-top: -15px;
  margin-left: -15px;
  transform: translateX(-50%) translateY(-50%);
  border: 4px solid var(--primary-300);
  border-bottom-color: var(--primary-300);
  border-radius: 50%;
  animation: rotation 1s ease infinite;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  z-index: 9001;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.i-aside__title {
  margin-bottom: 32px;
}

.i-aside__content {
  position: relative;
  max-width: 480px;
}

.i-aside-nav__in {
  position: relative;
  border-radius: 8px;
  box-shadow: 5px 5px 20px rgba(190, 190, 190, 0.4980392157);
  background: #fff;
  z-index: 10;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.base-header {
  margin: 0 auto;
  max-width: 940px;
  margin-bottom: var(--spacing-lg);
}
@media (max-width: 991.98px) {
  .base-header {
    max-width: none;
  }
}
.base-header.--left {
  text-align: left;
  margin-right: 0;
  margin-left: 0;
  max-width: none;
}
.base-header.--m-bottom-0 {
  margin-bottom: 0;
}
.base-header.--m-bottom-4 {
  margin-bottom: 32px;
}
.base-header.--m-top-4 {
  margin-top: 32px;
}

@media (min-width: 992px) {
  .base-header__paragraph {
    font-size: 16px;
  }
}

.base-header__subheading {
  margin-bottom: var(--spacing-sm);
  color: var(--secondary-500);
  line-height: 24px;
  text-transform: uppercase;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.base-heading {
  margin-bottom: 0;
}
.base-heading--h1 {
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  margin-bottom: 32px;
}
@media (max-width: 767.98px) {
  .base-heading--h1 {
    font-size: 32px;
    line-height: 40px;
  }
}
.base-heading--h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: var(--spacing-sm);
}
@media (max-width: 767.98px) {
  .base-heading--h2 {
    font-size: 24px;
    line-height: 32px;
  }
}
.base-heading--h3 {
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
}
.base-heading--h4 {
  font-size: 24px;
  line-height: 32px;
}
.base-heading--h5 {
  font-size: 20px;
  line-height: 24px;
}
.base-heading--h6 {
  font-size: 20px;
  line-height: 24px;
}
.base-heading.--m-bottom-0 {
  margin-bottom: 0;
}

.base-list.--ul li {
  list-style: none;
}
.base-list.--ol li {
  position: relative;
  margin: 8px 0;
  counter-increment: ol-counter;
  padding-left: 32px;
}
.base-list.--ol li::before {
  content: counter(ol-counter);
  position: absolute;
  left: 0;
  top: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--primary-300);
  color: #fff;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.base-section {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}
.base-section:first-of-type {
  margin-top: 0;
}
.base-section.--p-top-0 {
  padding-top: 0;
}
.base-section.--p-bottom-0 {
  padding-bottom: 0;
}
.base-section.--container-reset .container {
  padding-left: 0;
  padding-right: 0;
}
.base-section.--background {
  background-color: var(--primary-100);
  padding-bottom: 0;
}
.base-section.--background .container {
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
}
.base-section.--background .--grayscale {
  background-color: var(--primary-100);
}
.base-section.--background + .base-section.--background {
  margin-top: calc(-1 * var(--spacing-xl));
}
.base-section.--background + .base-section.--background::after {
  display: none;
}
.base-section.--background + .base-section.--background .container {
  padding-top: 0;
}
@media (min-width: 992px) {
  .base-section.--divider + .base-section.--background {
    margin-top: calc(-1 * var(--spacing-xl));
  }
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.s-breadcrumbs {
  padding-top: var(--spacing-lg);
  margin-bottom: var(--spacing-xl-mobile);
}
@media (max-width: 991.98px) {
  .s-breadcrumbs {
    margin-bottom: var(--spacing-lg);
  }
}

.s-breadcrumbs__item {
  color: var(--primary-500);
}
.s-breadcrumbs__item::after {
  content: "";
  display: block;
  position: absolute;
  border-right: 1px solid var(--secondary-200);
  height: 20px;
  right: 0;
  top: 0;
}
.s-breadcrumbs__item:last-child::after {
  display: none;
}

:root {
  --bs-body-line-height: 24px;
}

.btn__icon svg,
.btn__icon .img {
  height: 16px;
  width: 16px;
  transition: transform 0.3s ease;
}

.btn {
  user-select: none;
  transition: background-color 0.3s ease-out;
  transition-property: background-color;
  line-height: 32px;
  font-family: "Merriweather Sans", sans-serif;
  color: #fff;
  font-weight: 600;
  border-radius: 8px;
  padding: 12px 32px;
  border: none;
  z-index: 1;
  text-decoration: none;
  touch-action: manipulation;
  cursor: pointer;
  overflow: hidden;
  /* stylelint-disable no-descending-specificity */
  /* stylelint-enable no-descending-specificity */
}
.btn:hover {
  text-decoration: none;
  color: #fff;
}
.btn:focus, .btn:active {
  outline: 0;
  color: #fff;
  text-decoration: none;
}
.btn.--conversion {
  background-color: var(--conversion-400);
  min-width: 168px;
  transition: box-shadow 0.2s ease;
}
.btn.--conversion path {
  fill: #fff;
}
.btn.--conversion::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 32px);
  height: 100%;
  transform: translateX(-100%);
  background-color: var(--conversion-500);
  clip-path: polygon(0 0, calc(100% - 32px) 0, 100% 100%, 0% 100%);
  transition: transform 0.2s ease;
  z-index: -1;
}
.btn.--conversion:hover {
  color: #fff;
  box-shadow: 0 10px 10px rgba(238, 108, 114, 0.3019607843);
}
.btn.--conversion:hover::before {
  transform: translateX(0%);
}
.btn.--primary {
  background-color: var(--primary-100);
  min-width: 168px;
  transition: background-color 0.2s ease;
}
.btn.--primary path {
  fill: var(--primary-500);
}
.btn.--primary:hover {
  background-color: var(--primary-500);
  color: #fff;
}
.btn.--primary:hover path {
  fill: #fff;
}
.btn.--outline {
  background: transparent;
  box-shadow: inset 0 0 0 2px var(--conversion-400);
  min-width: 168px;
  color: var(--conversion-500);
  border: none;
  transition: background 0.2s ease;
}
.btn.--outline path {
  fill: var(--conversion-400);
}
.btn.--outline:hover {
  background: var(--conversion-400);
  color: #fff;
}
.btn.--outline:hover path {
  fill: #fff;
}
.btn.--white {
  background-color: rgba(255, 255, 255, 0.5647058824);
}
.btn.--white:hover {
  background-color: #fff;
}
.btn.--center {
  width: max-content;
  display: flex;
  margin: 0 auto;
}
.btn.--animation-right .btn__icon svg,
.btn.--animation-right .btn__icon .img {
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.btn.--animation-right:hover svg,
.btn.--animation-right:hover .img {
  transform: translateX(5px);
}
.btn.--animation-left .btn__icon svg,
.btn.--animation-left .btn__icon .img {
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.btn.--animation-left:hover svg,
.btn.--animation-left:hover .img {
  transform: translateX(-5px);
}
.btn.--animation-zoom:hover svg,
.btn.--animation-zoom:hover .img {
  transform: scale(1.1);
}
.btn.--animation-rotate:hover svg,
.btn.--animation-rotate:hover .img {
  transform: rotate(45deg);
}
.btn.--small {
  padding: 8px 10px;
  min-width: 100px;
  font-size: 14px;
  line-height: 1;
}
.btn.--medium {
  padding: 16px 16px;
  min-width: 120px;
  line-height: 1;
}
.btn.--icon-before .btn__icon {
  order: -1;
}
.btn.--icon-only {
  width: 48px;
  height: 48px;
  min-width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn.--icon-only .btn__title {
  display: none;
}
.btn.--icon-only .btn__icon {
  height: 100%;
}
.btn.--icon-only .btn__icon svg,
.btn.--icon-only .btn__icon .img {
  width: 24px;
  height: 24px;
}
.btn.--icon-only.--small {
  width: 32px;
  height: 32px;
}
.btn.--icon-only.--small .btn__icon svg,
.btn.--icon-only.--small .btn__icon .img {
  width: 16px;
  height: 16px;
}
.btn.--large {
  padding: 12px 48px;
  font-size: 16px;
}
@media (max-width: 575.98px) {
  .btn.--full-width-mobile {
    max-width: 360px;
    width: 100%;
  }
}
.btn.--circle {
  border-radius: 50%;
  min-width: auto;
}
.btn.--no-style {
  padding: 0;
  background-color: transparent;
  border-radius: 0;
}
.btn.--full-width {
  width: 100%;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
@media (min-width: 992px) {
  .content-w-aside__content {
    grid-column: 1/span 8;
    grid-row: 1/span 3;
  }
}

.content-w-aside .base-section {
  margin: var(--spacing-lg) 0;
}
.content-w-aside .base-section:first-child {
  margin-top: 0;
}
.content-w-aside .content-w-aside__row {
  gap: 32px;
}
@media (min-width: 992px) {
  .content-w-aside .content-w-aside__row {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;
  }
}
@media (max-width: 991.98px) {
  .content-w-aside .content-w-aside__row {
    display: flex;
    flex-direction: column;
  }
}
.content-w-aside .content-w-aside__row > .content-w-aside__content:last-child {
  grid-column: 3/span 8;
}
.content-w-aside .content-w-aside__aside {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-lg);
}
@media (min-width: 992px) {
  .content-w-aside .content-w-aside__aside {
    grid-column: span 3/-1;
  }
}
@media (max-width: 991.98px) {
  .content-w-aside .content-w-aside__aside {
    margin-bottom: var(--spacing-lg);
  }
}
@media (max-width: 991.98px) {
  .content-w-aside .content-w-aside__aside.--bottom {
    order: 1;
  }
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.footer-column {
  position: relative;
  width: min(264px, 100%);
  padding-top: 16px;
}
@media (max-width: 991.98px) {
  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.footer-column__heading {
  margin-bottom: 16px;
}

.footer-column__perex {
  line-height: 32px;
}
@media (max-width: 991.98px) {
  .footer-column__perex {
    text-align: center;
  }
}

.footer-column__subheading {
  margin-bottom: 16px;
}

.footer-column__image {
  width: max-content;
  background-color: var(--primary-300);
  border: 4px solid var(--primary-300);
  border-radius: 8px;
}
.footer-column__image a {
  display: block;
}

@media (max-width: 991.98px) {
  .footer-column__logos {
    justify-content: center;
  }
}
.footer-column__logos .img,
.footer-column__logos .img img {
  border-radius: 0;
  display: flex;
  width: 50px;
  align-items: center;
}

.footer-social {
  transition-property: text-decoration-color, color;
  text-decoration: underline;
  color: var(--secondary-200);
  text-decoration-color: transparent;
}
.footer-social rect,
.footer-social circle,
.footer-social path {
  transition: opacity 0.2s ease;
}
.footer-social:hover rect,
.footer-social:hover circle,
.footer-social:hover path {
  opacity: 1;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.footer {
  background-color: #fff;
}

.footer__top-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: var(--spacing-sm);
  position: relative;
  padding: var(--spacing-lg) 0 var(--spacing-md);
}
@media (min-width: 768px) {
  .footer__top-content {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacing-md);
  }
}
@media (min-width: 1200px) {
  .footer__top-content {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--spacing-xl-mobile);
  }
}
@media (max-width: 991.98px) {
  .footer__top-content {
    text-align: center;
    justify-items: center;
  }
}
.footer__top-content::before {
  content: "";
  display: block;
  position: absolute;
  width: 100vw;
  border-bottom: 8px solid var(--primary-300);
  right: 0;
}

.footer__middle-content {
  display: grid;
  row-gap: 32px;
  grid-template-columns: 1fr;
  padding: 24px 0;
  border-top: 4px solid var(--primary-100);
}
@media (min-width: 992px) {
  .footer__middle-content {
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
  }
}
@media (min-width: 1200px) {
  .footer__middle-content {
    grid-template-columns: 30% 35% 35%;
  }
}
.footer__middle-content .footer-column {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 0 32px;
}
@media (max-width: 1499.98px) {
  .footer__middle-content .footer-column {
    padding: 0 24px;
  }
}
@media (max-width: 991.98px) {
  .footer__middle-content .footer-column {
    flex-direction: column;
    align-items: center;
  }
}
.footer__middle-content .footer-column::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-left: 1px solid var(--secondary-200);
  height: min(70px, 100%);
}
@media (max-width: 1199.98px) {
  .footer__middle-content .footer-column::before {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .footer__middle-content .footer-column::before {
    display: block;
    width: min(100%, 320px);
    top: -16px;
    left: auto;
    margin: 0 auto;
    height: auto;
    border-bottom: 1px solid var(--secondary-200);
  }
}
.footer__middle-content .footer-column:first-child::before {
  display: none;
}
.footer__middle-content .footer-column__heading {
  margin-bottom: 0;
  flex: 0 0 auto;
}
.footer__middle-content .footer-column__perex {
  line-height: 24px;
  margin-bottom: 0;
}
.footer__middle-content .footer-column__logo {
  flex: 0 0 auto;
  max-width: 35%;
}
.footer__middle-content .base-heading {
  font-size: 16px;
  color: var(--primary-500);
}

.footer__bottom-content {
  padding: 24px 0;
  border-top: 4px solid var(--primary-100);
}

.f-contact__wrapper {
  display: grid;
  gap: 32px;
}
@media (min-width: 768px) {
  .f-contact__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
.f-contact__wrapper.--radios {
  gap: 24px;
}

.f-contact__flash {
  padding: 8px 12px;
  margin-bottom: 0;
}
.f-contact__flash.--success {
  background: #e7f4e4;
  color: #48a463;
}
.f-contact__flash.--error {
  background: #f9dede;
  color: #e21c3d;
}
.f-contact__flash p {
  margin-bottom: 0;
}

.f-contact {
  padding: 48px;
  box-shadow: 5px 5px 15px rgba(190, 190, 190, 0.4980392157);
  background-color: #fff;
  border-radius: 8px;
}
@media (max-width: 767.98px) {
  .f-contact {
    padding: 24px;
  }
}
.f-contact .link {
  font-size: 1em;
}
@media (min-width: 768px) {
  .f-contact.--large .f-contact__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .f-contact.--large .f-contact__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
.f-contact.--large textarea.f-base {
  min-height: 224px;
}

.f-contact__perex {
  gap: 20px;
}

.f-contact__bottom {
  gap: 32px;
}
@media (max-width: 575.98px) {
  .f-contact__bottom {
    flex-direction: column;
  }
}

.f-contact__file-input {
  gap: 16px 64px;
}
@media (max-width: 991.98px) {
  .f-contact__file-input {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .f-contact__btn-wrapper {
    width: 100%;
  }
}

.f-error__list {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  margin-top: 8px;
  padding: 12px 16px;
  right: 0;
  background: #f9dede;
  border: 1px solid #e21c3d;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  z-index: 100;
}
@media (max-width: 991.98px) {
  .f-error__list {
    position: static;
    opacity: 1;
  }
}

.f-error__item {
  color: #e21c3d;
  margin-bottom: 4px;
}

.f-error__icon {
  position: absolute;
  width: 16px;
  right: -16px;
  top: 25px;
  transform: translateY(-50%);
  pointer-events: auto;
  cursor: pointer;
}
.f-error__icon:hover + .f-error__list {
  opacity: 1;
  pointer-events: auto;
}

@media (min-width: 992px) {
  .f-error {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
}

.f-base {
  display: block;
  width: 100%;
  color: var(--primary-800);
  background-color: #fff;
  background-clip: padding-box;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 16px;
  appearance: none;
  border: none;
}
.f-base:focus {
  color: var(--primary-800);
  border-color: none;
  outline: none;
}
.f-base::placeholder {
  opacity: 1;
  color: var(--secondary-200);
}

textarea.f-base {
  min-height: 152px;
  resize: vertical;
}

.f-file__info {
  align-self: flex-start;
  flex: 0 0 auto;
}

.f-file__label {
  pointer-events: none;
}
@media (max-width: 991.98px) {
  .f-file__label {
    gap: 16px;
  }
}
@media (max-width: 767.98px) {
  .f-file__label {
    flex-wrap: wrap;
  }
}
.f-file__label .btn {
  flex: 0 0 auto;
  max-width: 100%;
}

.f-file__input {
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.f-file__message {
  max-width: calc(100% - 64px);
  opacity: 0.5;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.f-file__remove {
  flex: 0 0 auto;
  opacity: 0;
  pointer-events: none;
}
.f-file__remove.--visible {
  opacity: 1;
  pointer-events: auto;
}
.f-file__remove path,
.f-file__remove rect {
  fill: var(--conversion-500);
}

@media (max-width: 991.98px) {
  .f-file__size-error {
    grid-column: 1/span 2;
  }
}
.f-file__size-error.--hidden {
  display: none;
}
.f-file__size-error .f-error {
  left: 0;
}

.f-file {
  overflow: hidden;
  transition: opacity 0.2s ease;
}
.f-file.--hidden {
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 991.98px) {
  .f-file.--hidden {
    display: none;
  }
}
.f-file.--error {
  padding: 4px;
  margin: -4px;
  box-shadow: 0 0 0 1px #e21c3d;
  border-radius: 8px;
}
.f-file.--visible {
  opacity: 1;
  pointer-events: auto;
}
@media (max-width: 991.98px) {
  .f-file.--visible {
    display: grid;
    grid-template-columns: 32px 1fr;
  }
}
.f-file.--visible .btn {
  pointer-events: auto;
}
.f-file .info {
  margin-top: 8px;
}

.f-input__icon {
  padding: 8px;
  position: absolute;
  pointer-events: none;
}
.f-input__icon.--before {
  padding-left: 16px;
  left: 0;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
.f-input__icon.--after {
  padding-right: 16px;
  right: 0;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.f-input__info {
  right: 8px;
  top: calc(50% - 16px);
}
.f-input__info .link {
  display: block;
}

.f-input {
  border: 1px solid var(--secondary-200);
  color: var(--primary-500);
  border-radius: 8px;
}
.f-input:focus-within {
  border-color: var(--primary-500);
  color: var(--primary-500);
}
.f-input.--icon-before .f-input__input {
  padding-left: 48px;
}
.f-input.--icon-after .f-input__input {
  padding-right: 48px;
}
.f-input.--error {
  border-color: #e21c3d;
  color: #e21c3d;
}

.f-label {
  color: inherid;
}
.f-label.--required::after {
  content: "*";
  display: inline-block;
  width: 1em;
  color: #e21c3d;
  font-size: 0.85em;
  vertical-align: top;
  margin-left: -0.2em;
}

.f-radio input {
  height: 0;
  width: 0;
  opacity: 0;
  pointer-events: none;
}
.f-radio label {
  position: relative;
  padding-left: 26px;
  padding-right: 0;
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-800);
}
.f-radio label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: var(--primary-500);
  background-color: var(--primary-100);
  transform: translateY(-50%);
  transition: background-color 0.2s, border-color 0.2s;
}
.f-radio label::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 3px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: var(--primary-500);
  transform: translateY(-50%);
  transition: opacity 0.2s, background-color 0.2s;
  opacity: 0;
}
.f-radio label:hover {
  cursor: pointer;
}
.f-radio label:hover::before {
  background-color: #fff;
}
.f-radio input:checked + label::after {
  opacity: 1;
}
.f-radio input:checked + label::before {
  border-color: var(--primary-500);
}
.f-radio input:checked + label:hover::before {
  background-color: #fff;
}
.f-radio input:checked + label:hover::after {
  background-color: var(--primary-500);
}

.f-radio__info .link {
  display: block;
}

.f-search-form .f-wrap {
  flex: 1;
}
.f-search-form .btn {
  flex: 0 0 auto;
  padding: 8px 24px;
}
@media (max-width: 991.98px) {
  .f-search-form .btn {
    min-width: auto;
  }
}

.f-select__icon {
  padding: 8px;
  position: absolute;
  pointer-events: none;
  transition: 0.2s ease;
}
.f-select__icon.--after {
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.dropdown-active + .f-select__icon {
  transform: translateY(-50%) rotate(-180deg);
}

.f-select__info {
  position: absolute;
  right: 32px;
  top: calc(50% - 16px);
}
.f-select__info .link {
  display: block;
}

.f-select {
  border: 1px solid var(--secondary-200);
  border-radius: 8px;
  color: var(--primary-500);
}
.f-select.--icon-after .f-select__select {
  padding-right: 24px;
}
.f-select .f-label {
  flex: 0 0 auto;
  padding: 12px 8px 12px 16px;
}
.f-select.--error {
  border-color: #e21c3d;
  color: #e21c3d;
}

.f-switch__label {
  position: relative;
  height: 32px;
  width: 64px;
  display: block;
  margin: 0 16px;
}
.f-switch__label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  border: 1px solid var(--primary-500);
  background-color: var(--primary-100);
  transition: background-color 0.2s, border-color 0.2s;
}
.f-switch__label::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 100%;
  background-color: var(--primary-500);
  transform-origin: left center;
  transform: translate(4px, -50%);
  transition: 0.2s, transform 0.2s;
}
.f-switch__label:hover, .f-switch__label:focus {
  cursor: pointer;
}
.f-switch__label:hover::after, .f-switch__label:focus::after {
  transform: translate(4px, -50%) scale(1.18);
}

.f-switch label {
  text-transform: uppercase;
  font-size: 14px;
  padding: 0;
}
.f-switch input {
  height: 0;
  width: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.f-switch input:disabled + .f-switch__label {
  pointer-events: none;
}
.f-switch input:disabled + .f-switch__label::before {
  border-color: var(--secondary-200);
  border-width: 1px;
}
.f-switch input:disabled + .f-switch__label::after {
  transform: translateX(4px);
  background-color: var(--secondary-200);
}
.f-switch input:checked + .f-switch__label::after {
  transform-origin: right center;
  transform: translate(38px, -50%);
}
.f-switch input:checked + .f-switch__label:hover::after, .f-switch input:checked + .f-switch__label:focus::after {
  transform: translate(38px, -50%) scale(1.18);
}

.f-textarea {
  border: 1px solid var(--secondary-200);
  border-radius: 8px;
}
.f-textarea:focus-within {
  border-color: var(--primary-500);
  color: var(--primary-500);
}
.f-textarea.--error {
  border-color: #e21c3d;
  color: #e21c3d;
}

.f-wrap .f-label {
  position: absolute;
  left: 8px;
  top: 12px;
  background-color: #fff;
  transition: 0.1s ease;
  border-radius: 16px;
  pointer-events: none;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.f-wrap input:focus-within + .f-label, .f-wrap input:not(:placeholder-shown) + .f-label,
.f-wrap input + .validator + .f-label,
.f-wrap textarea:focus-within + .f-label,
.f-wrap textarea:not(:placeholder-shown) + .f-label,
.f-wrap textarea + .validator + .f-label {
  transform: translateY(-50%);
  top: 0;
  font-size: 12px;
}

.g-base.--gap-xs {
  gap: 4px;
}
.g-base.--gap-sm {
  gap: 8px;
}
.g-base.--gap-md {
  gap: 16px;
}
.g-base.--gap-lg {
  gap: 24px;
}
.g-base.--gap-xl {
  gap: 32px;
}
.g-base.--align-left {
  justify-content: flex-start;
}
.g-base.--align-right {
  justify-content: flex-end;
}
.g-base.--align-center {
  justify-content: center;
}
.g-base.--align-between {
  justify-content: space-between;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.g-buttons {
  margin-top: var(--spacing-lg);
}

.g-catalog {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
}
@media (max-width: 1199.98px) {
  .g-catalog {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 991.98px) {
  .g-catalog {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.98px) {
  .g-catalog {
    grid-template-columns: 1fr;
  }
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.g-category-cards {
  gap: 32px;
  margin-bottom: var(--spacing-lg);
}

.content-w-aside .g-category-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}
@media (max-width: 767.98px) {
  .content-w-aside .g-category-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 575.98px) {
  .content-w-aside .g-category-cards {
    display: flex;
    flex-direction: column;
  }
}
.content-w-aside .g-category-cards .i-product-list {
  grid-column: span 1;
  grid-column-end: auto !important;
}

.g-counter-number {
  row-gap: 40px;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.g-footer-nav {
  margin-bottom: var(--spacing-md);
}
.g-footer-nav li {
  line-height: 32px;
}
.g-footer-nav li .link {
  text-decoration-color: currentcolor;
}
.g-footer-nav li .link:hover {
  text-decoration-color: transparent;
}
.g-footer-nav.--horizontal {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
@media (max-width: 991.98px) {
  .g-footer-nav.--horizontal {
    flex-wrap: wrap;
  }
}
.g-footer-nav.--horizontal li {
  text-align: center;
  padding: 0 36px;
}
@media (max-width: 1499.98px) {
  .g-footer-nav.--horizontal li {
    padding: 0 24px;
  }
}
@media (max-width: 1199.98px) {
  .g-footer-nav.--horizontal li {
    padding: 0 12px;
  }
}
@media (max-width: 991.98px) {
  .g-footer-nav.--horizontal li {
    flex: 1 1 100%;
  }
}

.footer-social-group {
  margin: 80px auto 0;
  width: min(200px, 100%);
}
.footer-social-group li {
  display: block;
}
@media (max-width: 991.98px) {
  .footer-social-group {
    gap: 16px;
  }
}
@media (max-width: 767.98px) {
  .footer-social-group {
    margin-top: 48px;
  }
}

.g-gallery-slider {
  --g-gallery-slider-image-height: 352px;
}
.g-gallery-slider.swiper {
  overflow: visible;
}
@media (max-width: 767.98px) {
  .g-gallery-slider.swiper {
    padding: 0 44px;
  }
}
@media (max-width: 575.98px) {
  .g-gallery-slider.swiper {
    padding: 0;
  }
}
.g-gallery-slider .swiper-buttons {
  top: calc(var(--g-gallery-slider-image-height) * 0.5);
  margin-top: 8px;
}

.g-graphic-detail {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.g-graphic-detail.--behind {
  z-index: -1;
}
@media (max-width: 767.98px) {
  .g-graphic-detail.--hide-md {
    display: none;
  }
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.g-icon-boxes {
  margin: 0 calc(var(--spacing-xl-mobile) / -2);
  row-gap: var(--spacing-xl-mobile);
  margin-bottom: var(--spacing-lg);
}
.g-icon-boxes > * {
  padding: 0 calc(var(--spacing-xl-mobile) / 2);
}

.g-icon-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 32px;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 991.98px) {
  .g-icon-cards {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.g-icon-progress {
  row-gap: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .g-icon-progress {
    flex-direction: column;
    align-items: center;
  }
}

.g-logo-slider {
  height: 152px;
}
.g-logo-slider.swiper {
  overflow: visible;
}
@media (max-width: 575.98px) {
  .g-logo-slider .swiper-slide {
    padding: 0 44px;
  }
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.g-media-gallery {
  display: inline-flex;
  max-width: 100%;
  gap: var(--spacing-sm);
}
@media (max-width: 991.98px) {
  .g-media-gallery {
    justify-content: center;
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .g-media-gallery {
    flex-wrap: wrap;
  }
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.g-review {
  gap: 32px;
  margin-bottom: var(--spacing-lg);
}

.g-share-button {
  gap: 20px;
}

.icon {
  color: var(--primary-800);
  flex: 0 0 16px;
  max-width: 16px;
  height: 16px;
}
.icon svg {
  width: 100%;
  height: 100%;
}
.icon.--primary {
  color: var(--primary-300);
}
.icon.--primary path {
  fill: var(--primary-300);
}
.icon.--white {
  color: #fff;
}
.icon.--white path {
  fill: #fff;
}
.icon.--black {
  color: var(--primary-800);
}
.icon.--black path {
  fill: var(--primary-800);
}
.icon.--conversion {
  color: var(--conversion-500);
}
.icon.--conversion path {
  fill: var(--conversion-500);
}
.icon.--lg {
  flex: 0 0 24px;
  max-width: 24px;
  height: 24px;
}
.icon.--sm {
  flex: 0 0 14px;
  max-width: 14px;
  height: 14px;
}
.icon.--xs {
  flex: 0 0 10px;
  max-width: 10px;
  height: 10px;
}
.icon.--xl {
  flex: 0 0 48px;
  max-width: 48px;
  height: 48px;
}
.icon.--full {
  flex: 0 0 auto;
  max-width: max-content;
  height: max-content;
}
.icon.--full svg {
  width: auto;
  height: auto;
}

.img {
  overflow: hidden;
  border-radius: 8px;
}
.img.--ratio-4-3 {
  aspect-ratio: 4/3;
}
.img.--ratio-16-9 {
  aspect-ratio: 16/9;
}
.img.--ratio-3-2 {
  aspect-ratio: 3/2;
}
.img.--ratio-1-1 {
  aspect-ratio: 1/1;
}
.img img {
  border-radius: 8px;
}
.img.--contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.img.--cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.img.--placeholder {
  position: relative;
}
.img.--placeholder::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-200);
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}

.info {
  color: var(--primary-800);
  padding: 8px;
}
.info.--with-title {
  padding: 0;
}
.info.--primary path {
  fill: var(--primary-500);
}
.info.--conversion path {
  fill: var(--conversion-400);
}

.i-author {
  column-gap: 20px;
}
@media (max-width: 575.98px) {
  .i-author {
    column-gap: 12px;
  }
}

.i-author__image {
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  height: 48px;
}
@media (max-width: 575.98px) {
  .i-author__image {
    flex: none;
  }
}
.i-author__image .img,
.i-author__image img {
  border-radius: 50%;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.i-button-header {
  margin-bottom: var(--spacing-lg);
}
.i-button-header .base-header {
  max-width: none;
}
.i-button-header .base-heading {
  max-width: 940px;
  margin: 0 auto;
  margin-bottom: 8px;
}
.i-button-header .base-header__paragraph {
  max-width: 928px;
  margin: 0 auto;
}
.i-button-header.--left {
  align-items: flex-start;
  justify-content: center;
}
.i-button-header.--left .base-heading,
.i-button-header.--left .base-header__paragraph {
  max-width: none;
  margin: 0;
}
.i-button-header.--m-bottom-0 {
  margin-bottom: 0;
}

.i-catalog {
  background-color: #fff;
  box-shadow: 3px 3px 6px rgba(56, 61, 61, 0.3019607843);
  border-radius: 8px;
  transition: box-shadow 0.2s ease;
  min-width: 0;
}
.i-catalog .link {
  text-decoration: none;
  flex: 1 1 auto;
}
@media (min-width: 768px) {
  .i-catalog .link {
    overflow: hidden;
  }
}
@media (max-width: 767.98px) {
  .i-catalog .link__title {
    word-break: break-word;
  }
}
@media (min-width: 768px) {
  .i-catalog .link__title {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.i-catalog .icon {
  flex: 0 0 auto;
}
.i-catalog path {
  transition: transform 0.2s ease;
}
.i-catalog .i-catalog__icon {
  flex: 0 0 auto;
}
.i-catalog:hover {
  box-shadow: 3px 3px 15px rgba(56, 61, 61, 0.3019607843);
}
.i-catalog:hover .i-catalog__icon path:first-child {
  transform: translateY(5px);
}

.i-category-cards__content {
  padding: 16px 8px 8px 16px;
  flex: 1 0 auto;
  justify-content: space-between;
}
.i-category-cards__content .link {
  align-self: flex-end;
}
.i-category-cards__content .base-heading {
  padding-right: 80px;
  transition: 0.2s ease;
}

.i-category-cards {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--secondary-200);
  transition: box-shadow 0.2s ease;
}
.i-category-cards:hover {
  box-shadow: 5px 5px 15px rgba(190, 190, 190, 0.4980392157);
}
.i-category-cards:hover .base-heading {
  color: var(--primary-500);
}

.i-category-cards__image {
  aspect-ratio: 4/3;
  position: relative;
  flex: 0 0 auto;
}
.i-category-cards__image .icon {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  right: 16px;
}
.i-category-cards__image .img,
.i-category-cards__image img {
  height: 100%;
  border-radius: 0;
}

.i-counter-number {
  flex: 0 0 auto;
  width: clamp(25%, 328px, 100%);
}

.i-counter-number__number {
  font-size: 48px;
  line-height: 1;
  white-space: nowrap;
  color: var(--primary-500);
}
.i-counter-number__number::before {
  content: "";
  display: block;
  position: absolute;
  width: 88px;
  height: 100%;
  border-top: 1px solid var(--primary-300);
  border-bottom: 1px solid var(--primary-300);
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.i-counter-number__perex {
  width: clamp(75%, 272px, 100%);
}

@media (max-width: 991.98px) {
  .i-entry-content-w-header,
  .i-entry-content-w-header .entry-content,
  .i-entry-content-w-header .base-header {
    text-align: center;
  }
}

.i-footer-contact .link {
  line-height: 32px;
}

.i-footer-contact__person {
  text-align: left;
}
.i-footer-contact__person::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid var(--secondary-200);
}

.i-footer-contact__person-credentials {
  color: var(--primary-500);
}

.i-footer-contact__person-name {
  font-weight: 700;
}

.i-footer-contact__person-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--primary-500);
  flex: 0 0 auto;
}

.i-gallery-slider__content::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: max(48px, 75%);
  background-color: var(--secondary-200);
  transform: translateY(-50%);
}

.i-gallery-slider__author {
  line-height: 24px;
  color: var(--primary-500);
}

.i-gallery-slider__image .img,
.i-gallery-slider__image img {
  height: var(--g-gallery-slider-image-height);
  aspect-ratio: auto;
  border-radius: 0;
}

.i-graphic-detail {
  position: absolute;
  pointer-events: none;
  /* vertical align */
  /* horizontal align */
  /* vertical self align */
  /* horizontal self align */
}
.i-graphic-detail .icon {
  max-width: 100%;
  width: max-content;
  height: auto;
}
.i-graphic-detail svg {
  width: auto;
  height: auto;
}
@media (max-width: 767.98px) {
  .i-graphic-detail svg {
    max-width: 50%;
  }
}
.i-graphic-detail path {
  fill: var(--secondary-600);
}
.i-graphic-detail.--v-top {
  top: 0;
}
.i-graphic-detail.--v-bottom {
  top: 100%;
}
.i-graphic-detail.--v-center {
  top: 50%;
}
.i-graphic-detail.--h-right {
  left: 100%;
}
.i-graphic-detail.--h-left {
  left: 0;
}
.i-graphic-detail.--h-center {
  left: 50%;
}
.i-graphic-detail.--vs-top {
  transform: translateY(0);
}
.i-graphic-detail.--vs-bottom {
  transform: translateY(-100%);
}
.i-graphic-detail.--vs-center {
  transform: translateY(-50%);
}
.i-graphic-detail.--hs-right .i-graphic-detail__in {
  transform: translateX(0);
}
.i-graphic-detail.--hs-left .i-graphic-detail__in {
  transform: translateX(-100%);
}
.i-graphic-detail.--hs-center .i-graphic-detail__in {
  transform: translateX(-50%);
}

.i-icon-boxes {
  flex: 0 1 auto;
  width: clamp(280px, 25%, 100%);
}
.i-icon-boxes p {
  font-size: 16px;
}

:root {
  --bs-body-line-height: 24px;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.i-icon-cards__sliding-box {
  position: relative;
  width: 100%;
  background: var(--primary-100);
  z-index: -1;
}
@media (min-width: 992px) {
  .i-icon-cards__sliding-box {
    position: relative;
    max-height: 0;
    z-index: 1;
  }
}

.i-icon-cards__list {
  overflow: hidden;
  background: var(--primary-100);
  border-radius: 0 0 8px 8px;
}
@media (min-width: 992px) {
  .i-icon-cards__list {
    max-height: 0;
    transform: translateY(-100%) scaleY(0);
    transition: transform 0.2s ease;
  }
}
@media (min-width: 1200px) {
  .i-icon-cards__list {
    position: absolute;
    width: 100%;
    padding-left: 146px;
  }
}

.i-icon-cards__title-box {
  padding: 32px 12px 12px 24px;
  background: #fff;
  z-index: 2;
}
@media (min-width: 992px) {
  .i-icon-cards__title-box {
    outline: 1px solid var(--secondary-200);
    border-radius: 8px;
  }
}

.i-icon-cards {
  filter: drop-shadow(0 0 0 #bebebe);
  transition: filter 0.2s ease;
}
@media (max-width: 991.98px) {
  .i-icon-cards {
    border-radius: 8px;
    overflow: hidden;
    filter: drop-shadow(5px 5px 15px rgba(190, 190, 190, 0.4980392157));
  }
}
.i-icon-cards:hover {
  filter: drop-shadow(5px 5px 15px rgba(190, 190, 190, 0.4980392157));
  z-index: 10;
}
@media (min-width: 992px) {
  .i-icon-cards:hover .i-icon-cards__title-box {
    border-radius: 8px 8px 0 0;
    outline: none;
  }
}
@media (min-width: 992px) {
  .i-icon-cards:hover .i-icon-cards__list {
    max-height: 100vh;
    transform: translateY(0);
  }
}

.i-icon-cards__icon {
  flex: 0 0 auto;
}

.i-icon-cards__list-title {
  color: var(--primary-500);
  font-weight: 700;
}

.i-icon-cards__header-with-icon {
  column-gap: 42px;
  padding-right: 12px;
}
@media (max-width: 991.98px) {
  .i-icon-cards__header-with-icon {
    flex-direction: column;
    row-gap: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
  }
}

.i-icon-cards__header {
  width: clamp(50%, 360px, 100%);
}

.i-icon-cards__link {
  align-self: flex-end;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.i-icon-progress {
  width: clamp(268px, 20%, 100%);
  padding: 0 40px;
  counter-increment: ol-counter;
}
@media (max-width: 991.98px) {
  .i-icon-progress {
    flex-direction: row;
    width: clamp(50%, 320px, 100%);
    gap: var(--spacing-lg) 24px;
    padding: 0 0 0 32px;
    text-align: left;
  }
}

.i-icon-progress__icon-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media (max-width: 991.98px) {
  .i-icon-progress__icon-wrapper {
    width: auto;
    flex: 0 0 auto;
  }
}
.i-icon-progress__icon-wrapper::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 80px);
  height: 1px;
  background-color: var(--secondary-500);
  z-index: 0;
}
@media (max-width: 991.98px) {
  .i-icon-progress__icon-wrapper::before {
    width: 1px;
    height: 100vh;
  }
}

.i-icon-progress__icon {
  flex: 0 0 auto;
}
.i-icon-progress__icon .icon {
  position: relative;
  z-index: 1;
  border: 10px solid #fff;
  border-radius: 50%;
}
.i-icon-progress__icon .icon svg {
  border-radius: inherit;
}
.i-icon-progress__icon::after {
  content: counter(ol-counter);
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #fff;
  color: var(--secondary-600);
  font-size: 26px;
  line-height: 48px;
  font-weight: 900;
  text-align: center;
  z-index: 2;
}

.i-img-w-link {
  position: relative;
  box-shadow: 5px 5px 20px rgba(190, 190, 190, 0.4980392157);
  border-radius: 8px;
}

.i-info-card {
  padding: 40px;
  background: var(--secondary-300);
  border-radius: 8px;
  color: var(--secondary-600);
}
@media (min-width: 1200px) {
  .i-info-card {
    padding-right: 96px;
  }
}
@media (max-width: 991.98px) {
  .i-info-card {
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  .i-info-card {
    padding: 24px;
  }
}
.i-info-card .base-header {
  max-width: none;
}
@media (max-width: 991.98px) {
  .i-info-card .base-header {
    text-align: center;
  }
}

.i-info-card__header {
  width: clamp(50%, 824px, 100%);
}

.i-info-card__button {
  flex: 0 0 auto;
}

.i-logo.--grayscale img {
  filter: grayscale(1) brightness(60%) contrast(0);
  transition: 0.2s ease;
}
.i-logo.--grayscale:hover img {
  opacity: 1;
  filter: grayscale(0) brightness(1) contrast(1);
}

.i-media-gallery {
  pointer-events: auto;
  position: relative;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
}
.i-media-gallery .icon {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.i-media-gallery .icon path {
  transition: 0.2s ease;
}
.i-media-gallery:hover path {
  opacity: 1;
}
.i-media-gallery.--grouped {
  width: 100%;
}
@media (min-width: 576px) {
  .i-media-gallery.--grouped {
    width: 220px;
  }
}
@media (min-width: 768px) {
  .i-media-gallery.--grouped {
    width: 280px;
  }
}
@media (max-width: 767.98px) {
  .i-media-gallery {
    width: 100%;
  }
}

.i-post__image-col {
  grid-area: image;
}

.i-post__content-col {
  grid-area: content;
}

.i-post__heading {
  line-height: 24px;
}

.i-post__perex {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.i-post__heading-link {
  text-decoration-color: transparent;
  color: var(--primary-800);
  overflow: hidden;
}
.i-post__heading-link:hover {
  color: var(--primary-500);
  text-decoration-color: transparent;
}

.i-post-image-link {
  border-radius: 8px;
}
.i-post-image-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-200);
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}
@media (max-width: 575.98px) {
  .i-post-image-link {
    margin-bottom: 16px;
  }
}
.i-post-image-link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transform: scale(1);
  transition-property: opacity, transform;
}
.i-post-image-link img[data-src]:not(.loaded) {
  opacity: 0;
  transform: scale(0.95);
}

.i-post__date {
  color: var(--primary-500);
}

.i-post__author {
  color: var(--primary-500);
}

.i-post {
  display: grid;
  grid-template-columns: 240px auto;
  grid-template-areas: "image content";
}
@media (max-width: 767.98px) {
  .i-post {
    grid-template-columns: 192px auto;
  }
}
@media (max-width: 575.98px) {
  .i-post {
    display: flex;
    flex-direction: column;
    max-width: 336px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .i-post.--column {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  .i-post.--column .i-post__content-col {
    height: 100%;
  }
  .i-post.--column .i-post__heading {
    font-size: 24px;
    line-height: 28px;
  }
}
.i-post.--bordered {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--secondary-200);
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.i-post-list-small {
  padding: var(--spacing-md) 0;
}
.i-post-list-small::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--secondary-200);
}
.i-post-list-small:last-child::after {
  display: none;
}

.i-post-list-small__image {
  flex: 0 0 88px;
  max-width: 30%;
}

.i-post-list-small__link {
  line-height: 24px;
  color: var(--primary-800);
  text-decoration: underline;
  text-decoration-color: currentcolor;
  transition: text-decoration 0.2s ease;
}
.i-post-list-small__link:hover {
  color: var(--primary-500);
  text-decoration-color: transparent;
}

.i-product-list {
  box-shadow: 0 0 0 1px var(--secondary-200);
  border-radius: 8px;
  overflow: hidden;
  transition: 0.2s ease;
}
.i-product-list:hover {
  box-shadow: 5px 5px 15px rgba(190, 190, 190, 0.4980392157);
}

.i-product-list__content {
  border-top: 4px solid var(--primary-300);
  padding: 20px 0 24px;
  justify-content: space-between;
  row-gap: 20px;
}

.i-product-list__title {
  line-height: 24px;
  width: clamp(80%, 225px, 100%);
}

.i-product-list__price .link {
  flex: 0 0 auto;
  align-self: flex-end;
}

.i-product-price {
  flex: 1 0 auto;
}

.i-product-price__main {
  color: var(--primary-500);
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.i-review {
  background-color: #fff;
  padding: var(--spacing-lg) 24px 32px;
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--secondary-200);
}
.i-review.--rating-1 .icon:nth-child(n+2) path {
  fill: var(--secondary-200);
}
.i-review.--rating-2 .icon:nth-child(n+3) path {
  fill: var(--secondary-200);
}
.i-review.--rating-3 .icon:nth-child(n+4) path {
  fill: var(--secondary-200);
}
.i-review.--rating-4 .icon:nth-child(n+5) path {
  fill: var(--secondary-200);
}

.i-review__rating {
  width: max-content;
  left: 50%;
  top: 0;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 12px 16px;
}

.i-review__perex {
  text-decoration-color: transparent;
  transition: text-decoration-color 0.2s ease;
}
.i-review__perex:hover {
  text-decoration-color: currentcolor;
}
.i-review__perex .link {
  margin-left: 4px;
}

.i-review__shortened-perex {
  text-decoration: underline;
  text-decoration-color: inherit;
}

.i-review__author {
  align-self: flex-end;
  color: var(--primary-500);
}

.i-review__date {
  color: var(--primary-500);
}

.i-share-button.btn::before {
  display: none;
}
.i-share-button.btn:hover {
  box-shadow: none;
}
.i-share-button.--facebook {
  background-color: #0879cf;
}
.i-share-button.--twitter {
  background-color: #31b4ff;
}
.i-share-button.--linkedin {
  background-color: #1b44a2;
}
.i-share-button.--pinterest {
  background-color: #e01a3e;
}

.i-tag {
  padding: 4px 10px;
  background-color: var(--primary-100);
  color: var(--conversion-500);
  box-shadow: inset 0 0 0 #bebebe;
  font-weight: 600;
  border-radius: 8px;
}
.i-tag.link {
  text-decoration: none;
  transition: box-shadow 0.2s ease;
}
.i-tag:hover {
  box-shadow: inset 0 0 0 1px currentcolor;
}

.link__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.link__icon svg {
  transition: transform 0.3s ease;
  will-change: transform;
}

.link {
  display: inline-flex;
  transition: text-decoration-color 0.3s ease;
  transition-property: text-decoration-color, color;
  text-decoration: underline;
  align-items: center;
  column-gap: 8px;
  font-weight: 600;
  /* stylelint-disable no-descending-specificity */
  /* stylelint-enable no-descending-specificity */
}
.link.--black {
  color: var(--primary-800);
  text-decoration-color: transparent;
}
.link.--black:hover, .link.--black:focus {
  color: var(--primary-800);
  text-decoration-color: var(--primary-800);
}
.link.--primary {
  color: var(--primary-500);
  text-decoration-color: currentcolor;
}
.link.--primary:hover, .link.--primary:focus {
  text-decoration-color: transparent;
}
.link.--conversion {
  color: var(--conversion-500);
  text-decoration-color: transparent;
}
.link.--conversion:hover, .link.--conversion:focus {
  color: var(--conversion-500);
  text-decoration-color: var(--conversion-500);
}
.link.--icon-before .link__icon {
  order: -1;
}
.link.--animation-right svg {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.link.--animation-right:hover svg {
  transform: translateX(8px);
}
.link.--animation-zoom:hover svg {
  transform: scale(1.1);
}
.link.--animation-rotate svg {
  transform: rotate(45deg);
}
.link.--animation-rotate:hover svg {
  transform: rotate(0);
}
.link.--underline-initial {
  text-decoration-color: inherit;
}
.link.--underline-initial:hover, .link.--underline-initial:focus {
  text-decoration-color: transparent;
}
.link.--invisible-title {
  text-decoration: none;
}
.link.--invisible-title .link__title {
  font-weight: 700;
}
@media (min-width: 992px) {
  .link.--invisible-title .link__title {
    transition: opacity 0.2s ease;
    opacity: 0;
  }
}
@media (min-width: 992px) {
  .link.--invisible-title:hover .link__title {
    opacity: 1;
  }
}
.link.--full-size-click::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.link.--hover-primary:hover {
  color: var(--primary-500);
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.main-header-search__form {
  position: absolute;
  right: 0;
  width: 0;
  overflow: hidden;
  transition: width 0.2s ease;
  background-color: #fff;
  z-index: 150;
}

.main-header-search__icon .icon path {
  fill: var(--primary-300);
}

.main-header-search.--search-open .main-header-search__form {
  width: 100%;
}
@media (max-width: 575.98px) {
  .main-header-search.--search-open .main-header-search__form {
    width: 100vw;
    padding: 7px var(--spacing-container-padding);
    top: 50%;
    transform: translateY(-50%);
    right: calc(var(--spacing-container-padding) * -1);
  }
}

@media (max-width: 991.98px) {
  .main-header__container {
    align-items: center;
    min-height: 64px;
  }
}

.main-header__content {
  width: 70%;
}
.main-header__content::before {
  content: "";
  display: none;
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  border-top: 1px solid var(--secondary-200);
}
@media (min-width: 992px) {
  .main-header__content::before {
    display: block;
  }
}
@media (min-width: 992px) {
  .main-header__content {
    padding-top: 8px;
  }
}

.main-header__nav {
  display: flex;
  align-items: center;
}

.main-header__logo img {
  max-width: 232px;
  max-height: 72px;
}
@media (max-width: 767.98px) {
  .main-header__logo img {
    max-width: 160px;
    max-height: 56px;
  }
}

.main-header {
  min-height: 112px;
  background-color: #fff;
  transition: 0.4s ease;
  transition-property: padding-bottom, padding-top, box-shadow, transform;
  z-index: 150;
}
.main-header.--search-open .main-header__nav {
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease;
}
.main-header.headroom--pinned {
  transform: translateY(0%);
}
.main-header.headroom--unpinned {
  box-shadow: none;
}
@media (min-width: 992px) {
  .main-header.headroom--unpinned {
    transform: translateY(-100%);
  }
}
.main-header.headroom--not-top {
  padding-top: 0;
  padding-bottom: 0;
  min-height: auto;
  box-shadow: 0 0 5px rgba(56, 61, 61, 0.3019607843);
}
.main-header.headroom--not-top .main-header__content {
  padding-top: 0;
}
.main-header.headroom--not-top .main-header__logo img {
  max-height: 64px;
  padding: 8px 0;
}

.main-nav__list {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  .main-nav__list {
    flex-direction: column;
    width: 100%;
  }
}
.main-nav__list.--lang {
  padding-left: 56px;
  flex: 0;
}
@media (max-width: 1499.98px) {
  .main-nav__list.--lang {
    padding-left: 24px;
  }
}
@media (max-width: 991.98px) {
  .main-nav__list.--lang {
    padding-left: 0;
  }
}
.main-nav__list.--lang::before {
  content: "";
  display: block;
  width: calc(100% - 48px);
  height: 1px;
  margin: 0 auto -1px;
  background: var(--primary-300);
  position: relative;
}
@media (min-width: 992px) {
  .main-nav__list.--lang::before {
    display: none;
  }
}

.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 991.98px) {
  .main-nav {
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 64px;
    flex-direction: column;
    min-width: min(70%, 320px);
    width: max-content;
    max-width: 100%;
    position: fixed;
    left: 100%;
    top: 0;
    min-height: 100vh;
    max-height: 100vh;
    background-color: #fff;
    box-shadow: 5px 5px 30px rgba(56, 61, 61, 0.3019607843);
    transform: translate(0);
    transition: transform 0.2s ease;
  }
}
@media (max-width: 991.98px) {
  .main-nav.--opened {
    transform: translate(-100%);
  }
}

.main-nav__in {
  display: flex;
  width: 100%;
}
@media (max-width: 991.98px) {
  .main-nav__in {
    overflow: auto;
    flex-direction: column;
  }
}

.mobile-button {
  display: none;
}
@media (max-width: 991.98px) {
  .mobile-button {
    display: block;
  }
}
.mobile-button .mobile-button__button-close {
  display: none;
}
.mobile-button.--opened .mobile-button__button-close {
  display: block;
}
.mobile-button.--opened .mobile-button__button-open {
  display: none;
}

.c-modal__dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  margin: 50vh auto 0;
  transform: translateY(-50%);
  cursor: cell;
}

.c-modal__content {
  position: relative;
  width: min(100% - 32px, 688px);
  margin-inline: auto;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  background-color: #fff;
  cursor: auto;
  box-shadow: 4px 4px 24px rgba(15, 38, 57, 0.1019607843);
  transform: translateY(32px);
  transition: transform var(--transtion-length) ease;
  border-radius: 8px;
  max-height: 80vh;
  overflow: auto;
}

.c-modal__body {
  position: relative;
  flex: 1 1 auto;
  padding: 48px 48px 24px;
}

.c-modal__header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 48px 48px 0;
}

.c-modal__button-container {
  position: absolute;
  right: 0;
  top: 0;
  order: -1;
  display: flex;
  justify-content: flex-end;
}

.c-modal__close-cross {
  transform: translate(-16px, 16px);
}
.c-modal__close-cross svg rect,
.c-modal__close-cross svg path {
  fill: var(--primary-500);
}
.c-modal__close-cross:hover svg rect,
.c-modal__close-cross:hover svg path {
  fill: #fff;
}

.c-modal__footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 48px 48px;
  gap: 16px;
}
.c-modal__footer.--right {
  justify-content: flex-end;
}
.c-modal__footer.--center {
  justify-content: center;
}
.c-modal__footer.--between {
  justify-content: space-between;
}

.c-modal__heading {
  font-size: 22px;
}

.c-modal__inner {
  display: flex;
  flex-direction: column;
}

.c-modal {
  --transtion-length: $transitionLength;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;
}
.c-modal.--open {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}
.c-modal.--open .c-modal__content {
  transform: translateY(0);
}
.c-modal.--hidden {
  visibility: hidden;
}
.c-modal.--size-sm .c-modal__content {
  width: min(100% - 32px, 480px);
}
.c-modal.--size-xl .c-modal__content {
  width: min(100% - 32px, 1140px);
}

.scroll-up {
  width: 40px;
  height: 40px;
  bottom: 40px;
  right: 40px;
  border-radius: 20px;
  background-color: var(--primary-300);
  z-index: 50;
  transition: background-color 0.3s ease, opacity 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  will-change: opacity, transform, background-color;
  pointer-events: none;
  opacity: 0;
  transform: translateY(40px) scale(0.8);
  cursor: pointer;
}
.scroll-up:hover {
  background-color: var(--primary-300);
}
.scroll-up::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 20px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  transition-property: opacity, box-shadow;
  box-shadow: 0 0 10px 40px #fff;
}
.scroll-up:active::after {
  box-shadow: 0 0 0 0 #fff;
  position: absolute;
  opacity: 1;
  transition: 0s;
}
.scroll-up svg {
  width: 16px;
  height: 16px;
}
.scroll-up path {
  fill: #fff;
}
.scroll-up.--active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0) scale(1);
}

.s-contact-form__icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(50%);
}
@media (max-width: 1199.98px) {
  .s-contact-form__icon {
    display: none;
  }
}

.s-contact-form__image {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 1499.98px) {
  .s-contact-form__image {
    display: none;
  }
}

.s-contact-form__form {
  position: relative;
  margin: 0 auto;
}

.s-contact-form.--large .s-contact-form__image {
  display: none;
}
.s-contact-form.--large .s-contact-form__icon {
  display: none;
}
.s-contact-form.--large .s-contact-form__form {
  width: 100%;
}
@media (max-width: 1199.98px) {
  .s-contact-form.--large .i-graphic-detail__in svg {
    max-width: 50%;
  }
}

.s-contact-form__row {
  position: relative;
}
@media (min-width: 1200px) {
  .s-contact-form__row {
    padding-top: 32px;
  }
}
@media (min-width: 1500px) {
  .s-contact-form__row.--with-image {
    min-height: 688px;
  }
}
@media (min-width: 1500px) {
  .s-contact-form__row.--with-image .s-contact-form__form {
    margin: 0;
  }
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.s-content-detail__intro {
  position: relative;
  margin-bottom: 32px;
  padding-bottom: 24px;
}

.s-content-detail__header {
  margin-bottom: 24px;
  row-gap: 0;
}
.s-content-detail__header h1, .s-content-detail__header .h1 {
  margin-bottom: 24px;
}

.s-counter-number .container {
  position: relative;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.s-entry-content-media__gallery {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
}
@media (min-width: 1200px) {
  .s-entry-content-media__gallery {
    position: relative;
    width: 100%;
    margin-top: -40px;
  }
}
@media (min-width: 1500px) {
  .s-entry-content-media__gallery {
    margin-top: -232px;
  }
}
.s-entry-content-media__gallery > .icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -75%);
  z-index: 10;
}
@media (max-width: 1499.98px) {
  .s-entry-content-media__gallery > .icon {
    left: 42%;
  }
}
@media (max-width: 1199.98px) {
  .s-entry-content-media__gallery > .icon {
    display: none;
  }
}

.s-entry-content-media__content {
  padding-bottom: var(--spacing-lg);
}
@media (max-width: 1499.98px) {
  .s-entry-content-media__content.--without-button {
    padding-bottom: 4px;
  }
}
.s-entry-content-media__content .entry-content {
  margin-bottom: 40px;
}

.s-entry-content-media__media {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
@media (max-width: 1199.98px) {
  .s-entry-content-media__media {
    margin-bottom: var(--spacing-sm);
  }
}

.s-entry-content-media {
  --gallery-side-padding: 64px;
  --column-added-padding: 80px;
}
@media (min-width: 1500px) {
  .s-entry-content-media {
    --gallery-side-padding: 192px;
    --column-added-padding: 96px;
  }
}
@media (min-width: 1200px) {
  .s-entry-content-media.--has-gallery .s-entry-content-media__content {
    margin-bottom: var(--spacing-lg-mobile);
  }
}
@media (min-width: 1500px) {
  .s-entry-content-media.--has-gallery .s-entry-content-media__content {
    margin-bottom: 232px;
  }
}
@media (min-width: 1500px) {
  .s-entry-content-media.--left .s-entry-content-media__content {
    padding-right: var(--column-added-padding);
  }
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .s-entry-content-media.--left .s-entry-content-media__content {
    padding-right: calc(96px * 0.5);
  }
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .s-entry-content-media.--left .s-entry-content-media__media {
    padding-left: var(--column-added-padding);
  }
}
@media (min-width: 1200px) {
  .s-entry-content-media.--left .s-entry-content-media__gallery {
    padding-right: var(--gallery-side-padding);
  }
}
@media (min-width: 992px) and (max-width: 1499.98px) {
  .s-entry-content-media.--left .s-entry-content-media__gallery {
    justify-content: flex-end;
  }
}
@media (max-width: 1499.98px) {
  .s-entry-content-media.--left .s-entry-content-media__gallery > .icon {
    left: 58%;
  }
}
@media (min-width: 992px) {
  .s-entry-content-media.--right .row {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1500px) {
  .s-entry-content-media.--right .s-entry-content-media__content {
    padding-left: var(--column-added-padding);
  }
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .s-entry-content-media.--right .s-entry-content-media__content {
    padding-left: calc(96px * 0.5);
  }
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .s-entry-content-media.--right .s-entry-content-media__media {
    padding-right: var(--column-added-padding);
  }
}
@media (min-width: 1200px) {
  .s-entry-content-media.--right .s-entry-content-media__gallery {
    padding-left: var(--gallery-side-padding);
  }
}
@media (min-width: 992px) and (max-width: 1499.98px) {
  .s-entry-content-media.--right .s-entry-content-media__gallery {
    justify-content: flex-start;
  }
}
@media (min-width: 992px) and (max-width: 1499.98px) {
  .s-entry-content-media.--single .s-entry-content-media__content {
    padding-bottom: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .s-entry-content-media.--single .s-entry-content-media__media {
    margin-bottom: 212px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .s-entry-content-media.--single .s-entry-content-media__media {
    margin-bottom: 202px;
  }
}
@media (min-width: 1200px) {
  .s-entry-content-media.--single .s-entry-content-media__gallery {
    padding: 0 240px;
  }
}
@media (min-width: 992px) and (max-width: 1499.98px) {
  .s-entry-content-media.--single .s-entry-content-media__gallery {
    position: absolute;
  }
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .s-entry-content-media.--single .s-entry-content-media__gallery {
    top: 420px;
    right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .s-entry-content-media.--single .s-entry-content-media__gallery {
    top: 360px;
    padding: 0 80px;
  }
}
@media (max-width: 767.98px) {
  .s-entry-content-media.--single .s-entry-content-media__gallery .i-media-gallery {
    width: 100%;
  }
}

.s-entry-content .container {
  position: relative;
}

.s-error-404 {
  text-align: center;
}

.s-error-404__heading {
  display: block;
  font-size: 176px;
  line-height: 176px;
  color: var(--primary-500);
}
@media (max-width: 767.98px) {
  .s-error-404__heading {
    font-size: 136px;
    line-height: 136px;
  }
}

.s-error-404__sub-heading {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  max-width: 500px;
}

.s-error-404__perex {
  max-width: 500px;
  font-weight: 400;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.s-icon-progress svg,
.s-icon-progress img {
  background: #fff;
  border-radius: 50%;
}
.s-icon-progress.--background svg,
.s-icon-progress.--background img {
  background: var(--primary-100);
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.s-info {
  margin: var(--spacing-md) 0 var(--spacing-lg);
}

.s-info__block {
  border-radius: 8px;
  background: var(--primary-100);
}
@media (max-width: 767.98px) {
  .s-info__block {
    padding: 16px;
    flex-direction: column;
  }
}

.s-info__perex {
  margin-bottom: 0;
  padding: 16px 0;
}
@media (min-width: 768px) {
  .s-info__perex {
    flex-direction: column;
    margin-left: 32px;
    padding-left: 32px;
    border-left: 1px solid var(--secondary-200);
  }
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.s-intro__image {
  position: relative;
  margin: 0 var(--spacing-row-overflow);
}
.s-intro__image img {
  aspect-ratio: 62/19;
}
@media (max-width: 991.98px) {
  .s-intro__image img {
    aspect-ratio: 16/9;
  }
}

.s-intro .base-section__container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.s-intro.--bottom .s-intro__image {
  order: -1;
  margin-bottom: var(--spacing-lg);
}
.s-intro.--top .s-intro__content {
  margin-bottom: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}
.s-intro.--inside .s-intro__content {
  left: 0;
  margin: 0;
  padding: var(--spacing-xl-mobile) 0;
  color: #fff;
}
.s-intro.--inside .s-intro__image {
  position: absolute;
  height: 100%;
  width: auto;
  margin: 0 calc(var(--spacing-row-overflow) + var(--spacing-container-padding));
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.s-intro.--inside .s-intro__image .img {
  background-color: var(--secondary-600);
  height: 100%;
}
.s-intro.--inside .s-intro__image img {
  opacity: 0.35;
  aspect-ratio: auto;
}
.s-intro.--inside path {
  fill: var(--primary-500);
}
.s-intro.--left .container, .s-intro.--right .container {
  flex-direction: row;
  column-gap: 64px;
}
@media (max-width: 991.98px) {
  .s-intro.--left .container, .s-intro.--right .container {
    flex-direction: column;
  }
}
.s-intro.--left .s-intro__image, .s-intro.--right .s-intro__image {
  position: relative;
  align-self: center;
  margin: 0;
  width: 100%;
}
@media (min-width: 1500px) {
  .s-intro.--left .s-intro__image, .s-intro.--right .s-intro__image {
    min-width: 808px;
  }
}
.s-intro.--left .s-intro__image::before, .s-intro.--right .s-intro__image::before {
  content: "";
  width: calc(100% - 48px);
  height: calc(100% + 8px);
  position: absolute;
  top: 40px;
  border-radius: 8px 8px 0 0;
  filter: drop-shadow(5px 5px 15px rgba(190, 190, 190, 0.4980392157));
  z-index: -1;
  background: url("/images/svg/paper-cut-mask.svg") no-repeat bottom center, linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, white 105px, white 100%);
  background-size: 100% auto;
}
.s-intro.--left .s-intro__image img, .s-intro.--right .s-intro__image img {
  aspect-ratio: auto;
}
.s-intro.--left .s-intro__content, .s-intro.--right .s-intro__content {
  display: flex;
  width: 100%;
}
@media (max-width: 991.98px) {
  .s-intro.--left .s-intro__content, .s-intro.--right .s-intro__content {
    margin-bottom: var(--spacing-lg);
  }
}
.s-intro.--left .s-intro__image::before {
  right: -32px;
}
@media (min-width: 992px) {
  .s-intro.--right .s-intro__image {
    order: -1;
  }
}
.s-intro.--right .s-intro__image::before {
  left: -32px;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.s-logo-slider .base-header {
  margin-bottom: var(--spacing-xl-mobile);
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.s-post-detail__meta {
  color: var(--primary-500);
  gap: 16px 32px;
}

.s-post-detail__meta-left {
  gap: 36px;
}
@media (max-width: 575.98px) {
  .s-post-detail__meta-left {
    gap: 16px;
  }
}

.s-post-detail {
  margin-bottom: var(--spacing-lg);
}

.s-post-detail__intro::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  border-top: 1px solid var(--secondary-200);
}

.s-post-detail__header {
  margin-bottom: 0;
}

.s-posts-grid__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 64px;
}
@media (min-width: 1200px) {
  .s-posts-grid__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .s-posts-grid__grid-cell:first-of-type {
    grid-row: span 3/auto;
  }
}

.s-posts__grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  column-gap: 32px;
}
@media (min-width: 1200px) {
  .s-posts__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 991.98px) {
  .s-share-button {
    flex-direction: column;
  }
}
.s-share-button::before, .s-share-button::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  height: 1px;
  width: 100%;
  background: var(--secondary-200);
}
.s-share-button::after {
  top: 100%;
}

.s-share-button__title {
  flex: 0 0 auto;
}

.widget.widget_media_image {
  padding-left: 0;
  padding-right: 0;
  background: none;
  padding-top: 0;
  padding-bottom: 0;
}
.widget.widget_media_image .widgettitle {
  padding-bottom: 40px;
  background: #fff;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .widget.widget_media_image .widgettitle {
    padding-bottom: 24px;
  }
}
.widget.widget_media_image a {
  display: block;
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
}
.widget.widget_media_image a img {
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}

/* stylelint-disable */
.widget_nav_menu .sub-menu-button {
  display: block;
  width: 24px;
  height: 24px !important;
  position: absolute;
  top: 8px;
  right: 0;
  transform: translate(-50%, 0);
  transition: transform 0.3s;
  z-index: 20;
  cursor: pointer;
}
.widget_nav_menu .sub-menu-button::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: var(--primary-300);
  border-width: 0 2px 2px 0;
  height: 12px;
  width: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: transform 0.2s;
}
.widget_nav_menu .sub-menu-button.js-open::before {
  transform: translate(-50%, -50%) rotate(225deg);
}

.widget_nav_menu > div > ul .active > a,
.widget_nav_menu > div > ul .current-menu-item > a,
.widget_pages > ul .active > a,
.widget_pages > ul .current-menu-item > a {
  color: var(--primary-300);
}

/* stylelint-enable */
:root {
  --bs-body-line-height: 24px;
}

:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
/* stylelint-disable no-descending-specificity */
.aside-main {
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}
@media (max-width: 575.98px) {
  .aside-main {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.widget {
  background: #fff;
  font-weight: 400;
}

.widgettitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
  font-family: "Merriweather Sans", sans-serif;
}
@media (max-width: 575.98px) {
  .widgettitle {
    margin-bottom: 24px;
  }
}

.widget_categories:not(.widget_categories_select) ul,
.widget_nav_menu > div > ul,
.widget_pages > ul {
  margin-bottom: -8px;
}
.widget_categories:not(.widget_categories_select) ul li,
.widget_nav_menu > div > ul li,
.widget_pages > ul li {
  position: relative;
}
.widget_categories:not(.widget_categories_select) ul li a::before,
.widget_nav_menu > div > ul li a::before,
.widget_pages > ul li a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  width: 100%;
  background-color: var(--secondary-200);
}
.widget_categories:not(.widget_categories_select) ul li > ul,
.widget_nav_menu > div > ul li > ul,
.widget_pages > ul li > ul {
  height: 0;
  opacity: 0;
  pointer-events: none;
  transition: height 0.2s, opacity 0s;
}
.widget_categories:not(.widget_categories_select) ul li.js-open > ul,
.widget_nav_menu > div > ul li.js-open > ul,
.widget_pages > ul li.js-open > ul {
  height: auto;
  opacity: 1;
  pointer-events: inherit;
  transition: height 0.2s, opacity 0.2s;
}
.widget_categories:not(.widget_categories_select) ul li li,
.widget_nav_menu > div > ul li li,
.widget_pages > ul li li {
  padding-left: 15px;
}
.widget_categories:not(.widget_categories_select) ul .active > a,
.widget_categories:not(.widget_categories_select) ul .current-menu-item > a,
.widget_nav_menu > div > ul .active > a,
.widget_nav_menu > div > ul .current-menu-item > a,
.widget_pages > ul .active > a,
.widget_pages > ul .current-menu-item > a {
  text-decoration: none;
}
.widget_categories:not(.widget_categories_select) ul > li:last-of-type a::before,
.widget_nav_menu > div > ul > li:last-of-type a::before,
.widget_pages > ul > li:last-of-type a::before {
  display: none;
}
.widget_categories:not(.widget_categories_select) ul a,
.widget_nav_menu > div > ul a,
.widget_pages > ul a {
  color: var(--primary-800);
  padding: 8px 0;
  display: block;
  position: relative;
  text-decoration: none;
  z-index: 1;
  width: 100%;
}
.widget_categories:not(.widget_categories_select) ul a:hover,
.widget_nav_menu > div > ul a:hover,
.widget_pages > ul a:hover {
  text-decoration: underline;
}

.widget_categories:not(.widget_categories_select) ul li {
  display: flex;
  align-items: center;
}

.widget_categories form {
  position: relative;
}
.widget_categories form::before {
  content: "";
  position: absolute;
  right: 16px;
  border-style: solid;
  border-color: var(--primary-300);
  border-width: 0 2px 2px 0;
  top: 50%;
  height: 12px;
  width: 12px;
  transform: translate(0, -50%) rotate(45deg);
  pointer-events: none;
}
.widget_categories select {
  border: none;
  box-shadow: inset 0 0 0 1px var(--secondary-200);
  width: 100%;
  padding: 8px;
  appearance: none;
  outline: none;
  background-color: #fff;
}
.widget_categories select:active, .widget_categories select:focus {
  box-shadow: inset 0 0 0 1px var(--primary-300);
}

.widget_search form > div {
  display: flex;
  align-items: center;
}
.widget_search #s {
  height: 40px;
  line-height: 48px;
  outline: 0;
  padding: 0 15px;
  width: 100%;
  border: none;
  box-shadow: inset 0 0 0 1px var(--secondary-200);
  appearance: none;
}
.widget_search #searchsubmit {
  margin-left: 3px;
  min-width: 0;
  flex-shrink: 0;
  user-select: none;
  border: none;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease-out;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 16px;
  color: #fff;
  border-radius: 6px;
  transition-property: background-color;
  line-height: 24px;
  padding: 8px 15px;
  background-color: var(--primary-300);
  z-index: 1;
}
.widget_search #searchsubmit:hover {
  text-decoration: none;
  color: #fff;
  background-color: var(--primary-300);
}
.widget_search #searchsubmit:focus, .widget_search #searchsubmit:active {
  outline: 0;
  color: #fff;
  text-decoration: none;
}

.widget_custom_html .custom-html-widget {
  max-height: 500px;
  overflow: scroll;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .widget {
    padding: 16px;
  }
}
@media (max-width: 991px) {
  .aside-main {
    order: -2;
  }
  .widget {
    padding: 16px;
  }
  .widget_nav_menu .widgettitle,
  .widget_pages .widgettitle,
  .widget_categories .widgettitle {
    position: relative;
    line-height: 24px;
    padding: 0;
    margin-bottom: 8px;
    transform: none;
  }
  .widget_nav_menu .widgettitle::before,
  .widget_pages .widgettitle::before,
  .widget_categories .widgettitle::before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    border-style: solid;
    border-color: var(--primary-300);
    border-width: 0 2px 2px 0;
    height: 12px;
    width: 12px;
    transform: translate(0, -50%) rotate(45deg);
    transition: transform 0.2s;
  }
  .widget_nav_menu .js-open,
  .widget_pages .js-open,
  .widget_categories .js-open {
    opacity: 1;
    height: auto;
    pointer-events: inherit;
    transition: opacity 0.2s, height 0.2s;
  }
  .widget_nav_menu .js-open::before,
  .widget_pages .js-open::before,
  .widget_categories .js-open::before {
    transform: translate(0%, -50%) rotate(225deg);
  }
  .widget_nav_menu form.js-open::before,
  .widget_pages form.js-open::before,
  .widget_categories form.js-open::before {
    transform: translateY(-50%) rotate(45deg);
  }
  .widget_categories ul,
  .widget_nav_menu > div > ul,
  .widget_pages > ul,
  .widget_categories form {
    opacity: 0;
    box-shadow: none;
    height: 0;
    pointer-events: none;
  }
}
@media (max-width: 767px) {
  .aside-main {
    padding-top: 24px;
    margin-bottom: 24px;
  }
}
/* stylelint-enable no-descending-specificity */
:root {
  --spacing-xl: 112px;
  --spacing-lg: 56px;
  --spacing-md: 24px;
  --spacing-sm: 16px;
  --spacing-lg-mobile: 40px;
  --spacing-xl-mobile: 80px;
  --spacing-row-overflow: -40px;
  --spacing-container-padding: 26px;
  --spacing-container-padding-small: 8px;
}

@media (max-width: 359px) {
  :root {
    --spacing-container-padding: var(--spacing-container-padding-small);
  }
}
@media only screen and (max-width: 992px) {
  :root {
    --spacing-xl: 80px;
    --spacing-lg: 40px;
    --spacing-md: 16px;
    --spacing-sn: 16px;
    --spacing-row-overflow: -16px;
  }
}
.breadcrumbs {
  z-index: 50;
  padding: var(--spacing-lg) 0;
  color: var(--primary-500);
}
.breadcrumbs span {
  line-height: 24px;
  display: inline;
}
.breadcrumbs span a {
  display: inline-block;
  position: relative;
  color: var(--conversion-400);
  padding-right: 16px;
  margin-right: 16px;
  transition: text-decoration-color 0.2s ease;
  line-height: 24px;
  text-decoration: underline;
  text-decoration-color: transparent;
}
.breadcrumbs span a:hover {
  text-decoration-color: currentcolor;
}
.breadcrumbs span a::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 20px;
  right: 0;
  background-color: var(--secondary-200);
  border-radius: 0;
}
.breadcrumbs > span > span > a:first-child {
  user-select: none;
}
.breadcrumbs > span > span > a:first-child .icon {
  position: relative;
  top: -4px;
  max-width: 20px;
}
.breadcrumbs > span > span > a:first-child .icon svg {
  width: auto;
  height: auto;
}
.breadcrumbs > span > span > a:first-child path {
  fill: var(--conversion-400);
}
@media (max-width: 1199.98px) {
  .breadcrumbs.breadcrumbs-absolute {
    position: relative;
    top: 0;
  }
}

@media (max-width: 767px) {
  .breadcrumbs {
    padding-top: 0;
    padding-bottom: 0;
    display: none;
  }
}
.entry-content {
  padding: 0;
  text-align: left;
}
.entry-content strong:empty,
.entry-content b:empty,
.entry-content p:empty {
  display: none;
}
.entry-content hr {
  border: none;
  background: var(--secondary-200);
  height: 1px;
  margin: 48px 0;
  transform: translateY(6px);
}
.entry-content .wp-caption p {
  font-size: 12px;
  line-height: 16px;
  margin: 4px 0 16px;
  text-align: left;
}
.entry-content .wp-caption a {
  float: none;
  margin: 0;
}
@media (min-width: 1200px) {
  .entry-content.--narrow {
    width: 66.7%;
    margin: 0 auto;
  }
}

.entry-content blockquote {
  font-size: 16px;
  font-weight: 400;
  padding: 32px 24px 24px;
  margin: 48px 0;
  border-radius: 8px;
  position: relative;
  font-style: italic;
  color: var(--secondary-600);
  background-color: var(--secondary-300);
}
.entry-content blockquote::before {
  content: url("/images/svg/quotes.svg");
  display: block;
  position: absolute;
  top: 4px;
  transform: translateY(-50%);
}
.entry-content blockquote p {
  font-style: italic;
  margin-bottom: 0;
}

.entry-content code {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  padding: 24px 32px;
  margin: 48px 0;
  border-radius: 8px;
  position: relative;
  font-style: italic;
  color: rgba(41, 41, 41, 0.7019607843);
  background-color: rgba(41, 41, 41, 0.0392156863);
}
.entry-content code p {
  font-style: italic;
  margin-bottom: 0;
}

:root {
  --bs-body-line-height: 24px;
}

@media (max-width: 575.98px) {
  .entry-content {
    margin-top: 16px;
  }
}
.entry-content h1, .entry-content .h1,
.entry-content h2,
.entry-content .h2,
.entry-content h3,
.entry-content .h3,
.entry-content h4,
.entry-content .h4,
.entry-content h5,
.entry-content .h5,
.entry-content h6,
.entry-content .h6 {
  margin-top: 48px;
  color: var(--primary-800);
  font-family: "Merriweather Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 16px;
}
@media (max-width: 575.98px) {
  .entry-content h1, .entry-content .h1,
  .entry-content h2,
  .entry-content .h2,
  .entry-content h3,
  .entry-content .h3,
  .entry-content h4,
  .entry-content .h4,
  .entry-content h5,
  .entry-content .h5,
  .entry-content h6,
  .entry-content .h6 {
    margin-bottom: 24px !important;
  }
}
.entry-content h1, .entry-content .h1 {
  margin-top: 0;
  position: relative;
}
.entry-content h2, .entry-content .h2 {
  position: relative;
}
.entry-content > h2:first-child, .entry-content > .h2:first-child, .entry-content > h3:first-child, .entry-content > .h3:first-child {
  margin-top: 0;
}
.entry-content h5, .entry-content .h5,
.entry-content h6,
.entry-content .h6 {
  font-size: 16px;
  line-height: 24px;
}
.entry-content .base-heading {
  margin-top: 0;
}
.entry-content .i-product-list__title {
  margin: 0;
}

.entry-content img {
  max-width: 100%;
  border-radius: 8px;
}

.entry-content ol > li {
  position: relative;
  counter-increment: item;
  padding-left: 35px;
}
.entry-content ol > li::before {
  content: counter(item) ".";
  font-weight: 700;
  font-size: 18px;
  top: 1px;
  width: 28px;
  height: 28px;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-right: 8px;
  color: var(--primary-300);
  margin-left: -35px;
  border-radius: 100%;
  padding: 1px 4px;
  padding-left: 6px;
}
.entry-content ol > li ol > li { /* stylelint-disable-line no-descending-specificity */
  counter-increment: sub-item;
}
.entry-content ol > li ol > li::before {
  content: counter(sub-item) ".";
}
.entry-content ol > li ol > li ol > li { /* stylelint-disable-line no-descending-specificity */
  counter-increment: sub-sub-item;
}
.entry-content ol > li ol > li ol > li::before {
  content: counter(sub-sub-item) ".";
}
.entry-content ol[style*="list-style-type: lower-alpha"] li::before {
  content: counter(item, lower-alpha) ")";
}
.entry-content ol[style*="list-style-type: upper-alpha"] li::before {
  content: counter(item, upper-alpha) ")";
}
.entry-content ol[style*="list-style-type: lower-latin"] li::before {
  content: counter(item, lower-latin) ")";
}
.entry-content ol[style*="list-style-type: upper-latin"] li::before {
  content: counter(item, upper-latin) ")";
}
.entry-content ol[style*="list-style-type: lower-greek"] li::before {
  content: counter(item, lower-greek) ")";
}
.entry-content ol[style*="list-style-type: upper-greek"] li::before {
  content: counter(item, upper-greek) ")";
}
.entry-content ol[style*="list-style-type: lower-roman"] li::before {
  content: counter(item, lower-roman) ")";
}
.entry-content ol[style*="list-style-type: upper-roman"] li::before {
  content: counter(item, upper-roman) ")";
}
.entry-content ul > li { /* stylelint-disable-line no-descending-specificity */
  padding-left: 20px;
}
.entry-content ul > li::before { /* stylelint-disable-line no-descending-specificity */
  content: "";
  display: inline-block;
  position: relative;
  top: 50%;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 10px;
  background-color: var(--primary-300);
  transform: translateY(-2px);
  margin-left: -16px;
}
.entry-content ul,
.entry-content ol {
  list-style: none !important;
  padding: 0;
  margin: 0;
  margin-bottom: 24px !important;
  color: var(--primary-800);
}
.entry-content ul ul,
.entry-content ul ol,
.entry-content ol ul,
.entry-content ol ol {
  margin-bottom: 0 !important;
  margin-top: 8px;
}
.entry-content ul li,
.entry-content ol li { /* stylelint-disable-line no-descending-specificity */
  margin-bottom: 20px;
}
.--background .entry-content ul li::before,
.--background .entry-content ol li::before {
  background-color: var(--conversion-400);
}

.entry-content p {
  color: var(--primary-800);
  margin-bottom: 24px;
}
.entry-content p a {
  display: inline;
}
.entry-content .base-header__paragraph.mb-0 {
  margin-bottom: 0;
}

.entry-content .ratio {
  margin: 32px 0 40px;
}

.entry-content .table-responsive {
  margin: 16px 0;
  padding: 16px;
  box-shadow: inset 0 0 0 1px var(--secondary-200);
  overflow: auto;
  border-radius: 8px;
}
.entry-content th,
.entry-content td,
.entry-content tr {
  border: none !important;
}
.entry-content td,
.entry-content th {
  padding: 16px;
  text-align: center;
  position: relative;
}
.entry-content thead {
  background-color: var(--primary-500);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.entry-content tbody {
  background-color: #fff;
  font-size: 14px;
}
.entry-content tbody tr:nth-child(2n) {
  background-color: var(--primary-100);
}
.entry-content tbody td,
.entry-content tbody th {
  padding: 16px;
}
.entry-content table {
  margin-bottom: 0 !important;
  width: 100%;
  max-width: 100%;
}
.entry-content table p {
  margin-bottom: 0 !important;
  font-size: 16px;
  transform: none;
}

.g-pagination.--left {
  justify-content: flex-start;
}
.g-pagination.--center {
  justify-content: center;
}
.g-pagination.--right {
  justify-content: flex-end;
}

.g-search-results {
  padding-top: 80px;
}

/* mobile version of menu */
.menu-item__toggle {
  transition: 0.2s ease;
}
.menu-item__toggle .btn {
  width: auto;
  height: auto;
}

.menu-item {
  --horizontal-padding: 24px;
  --vertical-padding: 12px;
  position: relative;
  /* stylelint-disable no-descending-specificity */
  /* stylelint-enable no-descending-specificity */
}
@media (min-width: 992px) and (max-width: 1499.98px) {
  .menu-item {
    --horizontal-padding: 12px;
    --vertical-padding: 12px;
  }
}
@media (max-width: 991.98px) {
  .menu-item {
    --horizontal-padding: 8px;
    --vertical-padding: 8px;
  }
}
.menu-item a {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: var(--primary-800);
  text-decoration: none;
}
.menu-item a:hover {
  color: var(--primary-500);
}
.menu-item a .icon {
  max-width: 10px;
}
.menu-item a .icon path {
  fill: var(--conversion-400);
}
.menu-item .sub-menu {
  width: max-content;
  min-width: 180px;
  background-color: #fff;
  position: absolute;
  left: 0;
  transform: translateY(10px);
  opacity: 0;
  pointer-events: none;
  border-radius: 4px;
  box-shadow: 5px 5px 30px rgba(56, 61, 61, 0.3019607843);
  transition-property: opacity, transform;
  padding: var(--vertical-padding) 0;
}
.menu-item .sub-menu a {
  justify-content: space-between;
  padding: var(--vertical-padding) var(--horizontal-padding);
  font-size: 16px;
  font-weight: 600;
}
@media (min-width: 992px) {
  .menu-item .sub-menu a .icon {
    transform: rotate(-90deg);
  }
}
@media (min-width: 992px) {
  .menu-item:hover > .sub-menu {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }
}
.menu-item.--active > a {
  color: var(--primary-500);
}
.menu-item.--level-1 > a {
  position: relative;
  padding: 16px var(--horizontal-padding);
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}
.menu-item.--level-1 > a::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 16px;
  width: 100%;
  height: 14px;
  background-color: var(--primary-100);
  transform: scaleY(0);
  transform-origin: bottom;
  transition: 0.2s ease;
  z-index: -1;
}
.menu-item.--level-1 > a:hover::before {
  transform: scaleY(1);
}
.menu-item.--level-1 > .sub-menu::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 100%;
  left: var(--horizontal-padding);
  width: 24px;
  height: 13px;
  background: inherit;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
@media (min-width: 992px) {
  .menu-item.--level-2 > .sub-menu {
    position: absolute;
    top: calc(-1 * var(--vertical-padding));
    left: 100%;
  }
}
@media (max-width: 991.98px) {
  .menu-item a {
    --horizontal-padding: 24px;
    --vertical-padding: 8px;
    justify-content: space-between;
    line-height: 2;
  }
  .menu-item > a {
    padding: var(--vertical-padding) var(--horizontal-padding);
  }
  .menu-item.--level-1:first-child {
    padding-top: calc(2 * var(--vertical-padding));
  }
  .menu-item.--level-1:last-child {
    padding-bottom: calc(2 * var(--vertical-padding));
  }
  .menu-item.--level-1 > a::before {
    display: none;
  }
  .menu-item .sub-menu {
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 100;
    padding: 0;
    pointer-events: auto;
    border-radius: 0;
    box-shadow: none;
    transition: opacity 0.2s ease;
    transition-property: max-height, opacity, transform;
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
  }
  .menu-item .sub-menu::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--primary-100);
    opacity: 0.5;
    z-index: -1;
  }
  .menu-item .sub-menu a .icon {
    transform: none;
  }
  .menu-item .menu-item__toggle .btn {
    width: 32px;
    height: 32px;
  }
  .menu-item.--opened > .sub-menu {
    max-height: 10000px;
    transform: scaleY(1);
    opacity: 1;
  }
  .menu-item.--opened > a .menu-item__toggle {
    transform: rotate(-180deg);
  }
  .menu-item .menu-item.--level-2 > a {
    padding-left: calc(var(--horizontal-padding) + 12px);
  }
  .menu-item .menu-item.--level-2 .sub-menu {
    margin-left: 24px;
    background: var(--primary-100);
    width: auto;
  }
}
.menu-item.--mobile a {
  --horizontal-padding: 24px;
  --vertical-padding: 8px;
  justify-content: space-between;
  line-height: 2;
}
.menu-item.--mobile > a {
  padding: var(--vertical-padding) var(--horizontal-padding);
}
.menu-item.--mobile.--level-1:first-child {
  padding-top: calc(2 * var(--vertical-padding));
}
.menu-item.--mobile.--level-1:last-child {
  padding-bottom: calc(2 * var(--vertical-padding));
}
.menu-item.--mobile.--level-1 > a::before {
  display: none;
}
.menu-item.--mobile .sub-menu {
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  max-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 100;
  padding: 0;
  pointer-events: auto;
  border-radius: 0;
  box-shadow: none;
  transition: opacity 0.2s ease;
  transition-property: max-height, opacity, transform;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
}
.menu-item.--mobile .sub-menu::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary-100);
  opacity: 0.5;
  z-index: -1;
}
.menu-item.--mobile .sub-menu a .icon {
  transform: none;
}
.menu-item.--mobile .menu-item__toggle .btn {
  width: 32px;
  height: 32px;
}
.menu-item.--mobile.--opened > .sub-menu {
  max-height: 10000px;
  transform: scaleY(1);
  opacity: 1;
}
.menu-item.--mobile.--opened > a .menu-item__toggle {
  transform: rotate(-180deg);
}
.menu-item.--mobile .menu-item.--level-2 > a {
  padding-left: calc(var(--horizontal-padding) + 12px);
}
.menu-item.--mobile .menu-item.--level-2 .sub-menu {
  margin-left: 24px;
  background: var(--primary-100);
  width: auto;
}

.i-search-result {
  max-width: 80ch;
}

.i-search-result__heading {
  margin-bottom: 16px;
}

.i-search-result__heading-link {
  color: var(--primary-800);
  text-decoration-color: currentcolor;
}
.i-search-result__heading-link:hover {
  color: var(--primary-500);
  text-decoration-color: transparent;
}

.i-search-result__link {
  margin-bottom: 8px;
  text-decoration-color: transparent;
  transition-property: color, text-decoration-color;
}
.i-search-result__link:hover {
  color: var(--primary-300);
  text-decoration-color: var(--primary-300);
}

.entry-content .gallery-item {
  position: relative;
  margin: 0;
  padding: 0;
}
.entry-content .gallery-item a {
  display: block;
  transition: opacity 0.3s ease;
}
.entry-content .gallery-item a:hover {
  opacity: 0.7;
}
.entry-content .gallery-item img {
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.pagination {
  display: flex;
  align-items: center;
}
.pagination li {
  text-align: center;
}
.pagination li a,
.pagination li span {
  width: 24px;
  height: 24px;
  font-weight: bold;
  font-size: 14px;
  display: block;
}
.pagination li a {
  color: var(--primary-800);
  text-decoration-color: transparent;
}
.pagination li a:hover, .pagination li a:active, .pagination li a:focus {
  color: var(--primary-500);
}
.pagination .prev,
.pagination .next {
  transform: rotate(-90deg);
  transition: transform 0.2s ease;
}
.pagination .prev img,
.pagination .next img {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.pagination .prev:hover,
.pagination .next:hover {
  transform: translateX(-3px) rotate(-90deg);
}
.pagination .next { /* stylelint-disable-line no-descending-specificity */
  transform: rotate(90deg);
}
.pagination .next:hover {
  transform: translateX(3px) rotate(90deg);
}
.pagination .active span { /* stylelint-disable-line no-descending-specificity */
  color: var(--primary-500);
  background-color: var(--primary-100);
  border-radius: 50%;
  font-weight: bold;
  font-size: 14px;
}

#projectNotices {
  text-align: center;
  position: relative;
  z-index: 60;
}
#projectNotices p {
  padding: 12px;
  transform: none;
}
#projectNotices p:first-child {
  margin-top: 24px;
}
#projectNotices p.success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
#projectNotices p.error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
#projectNotices p.warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
#projectNotices p.info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.search-form__wrap {
  display: flex;
  align-items: center;
  position: relative;
  gap: 16px 32px;
  max-width: 80ch;
}
@media (max-width: 991.98px) {
  .search-form__wrap {
    flex-wrap: wrap;
  }
}

.search-form__heading {
  flex-shrink: 0;
}

.search-form {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px var(--secondary-200);
  display: flex;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.search-form__input {
  border: 0;
  background: none;
  padding: 0 16px;
  flex-grow: 1;
  outline: 0;
  color: var(--primary-800);
}
@media (max-width: 575.98px) {
  .search-form__input {
    min-width: 0;
  }
}

.search-form__submit {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.search-form__submit svg {
  width: 18px;
  height: 18px;
  transition: fill 0.2s;
  fill: var(--primary-300);
}
.search-form__submit:hover {
  background-color: var(--primary-300);
}
.search-form__submit:hover svg {
  fill: #fff;
}
.search-form__submit:active, .search-form__submit:focus {
  outline: 0;
}

.entry-content .gallery {
  margin-top: 48px;
  display: grid;
  gap: 16px;
}
.entry-content .gallery-columns-9 {
  grid-template-columns: repeat(8, 1fr);
}
@media (max-width: 991.98px) {
  .entry-content .gallery-columns-9 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.entry-content .gallery-columns-8 {
  grid-template-columns: repeat(8, 1fr);
}
@media (max-width: 991.98px) {
  .entry-content .gallery-columns-8 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.entry-content .gallery-columns-7 {
  grid-template-columns: repeat(7, 1fr);
}
@media (max-width: 991.98px) {
  .entry-content .gallery-columns-7 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.entry-content .gallery-columns-6 {
  grid-template-columns: repeat(6, 1fr);
}
@media (max-width: 991.98px) {
  .entry-content .gallery-columns-6 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.entry-content .gallery-columns-5 {
  grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 767.98px) {
  .entry-content .gallery-columns-5 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.entry-content .gallery-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 767.98px) {
  .entry-content .gallery-columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.entry-content .gallery-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}
.entry-content .gallery-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}
.entry-content .wp-gallery-no-gutter .gallery {
  gap: 0;
}

/*! purgecss end ignore */