@use "settings/s-general.scss" as general;

.i-media-gallery {
  pointer-events: auto;
  position: relative;
  width: 100%;
  max-width: 100%;
  cursor: pointer;

  .icon {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    path {
      transition: general.$transition-01;
    }
  }

  &:hover {

    path {
      opacity: 1;
    }
  }

  &.--grouped {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 220px;
    }

    @include media-breakpoint-up(md) {
      width: 280px;
    }
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
