@use "settings/s-colors.scss" as colors;

.base-list {

  &.--ul {

    li {
      list-style: none;
    }
  }

  &.--ol {

    li {
      position: relative;
      margin: 8px 0;
      counter-increment: ol-counter;
      padding-left: 32px;

      &::before {
        content: counter(ol-counter);
        position: absolute;
        left: 0;
        top: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: colors.$primary-300;
        color: colors.$white;
      }
    }
  }
}
