@use "settings/s-colors.scss" as colors;

.s-icon-progress {

  svg,
  img {
    background: colors.$white;
    border-radius: 50%;
  }

  &.--background {

    svg,
    img {
      background: colors.$background;
    }
  }
}
