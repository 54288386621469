@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

$dialogBackgroundColor: rgb(0 0 0 / 25%);
$contentBackgroundColor: colors.$white;
$contentPaddingX: 48px;
$contentPaddingY: 48px;
$contentBoxShadow: 4px 4px 24px #0f26391a;

$transitionLength: 300ms;

.c-modal__dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  margin: 50vh auto 0;
  transform: translateY(-50%);
  cursor: cell;
}

.c-modal__content {
  position: relative;
  width: min(100% - 32px, 688px);
  margin-inline: auto;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  background-color: $contentBackgroundColor;
  cursor: auto;
  box-shadow: $contentBoxShadow;
  transform: translateY(32px);
  transition: transform var(--transtion-length) ease;
  border-radius: general.$border-base;
  max-height: 80vh;
  overflow: auto;
}

.c-modal__body {
  position: relative;
  flex: 1 1 auto;
  padding: 48px $contentPaddingX 24px;
}

.c-modal__header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: $contentPaddingY $contentPaddingX 0;
}

.c-modal__button-container {
  position: absolute;
  right: 0;
  top: 0;
  order: -1;
  display: flex;
  justify-content: flex-end;
}

.c-modal__close-cross {
  transform: translate(-16px, 16px);

  svg {

    rect,
    path {
      fill: colors.$primary-500;
    }
  }

  &:hover {

    svg {

      rect,
      path {
        fill: colors.$white;
      }
    }
  }
}

.c-modal__footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 $contentPaddingX $contentPaddingY;
  gap: 16px;

  &.--right {
    justify-content: flex-end;
  }

  &.--center {
    justify-content: center;
  }

  &.--between {
    justify-content: space-between;
  }
}

.c-modal__heading {
  font-size: 22px;
}

.c-modal__inner {
  display: flex;
  flex-direction: column;
}

.c-modal {
  --transtion-length: $transitionLength;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  opacity: 0;
  pointer-events: none;
  background-color: $dialogBackgroundColor;
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;

  &.--open {
    opacity: 1;
    pointer-events: all;
    visibility: visible;

    .c-modal__content {
      transform: translateY(0);
    }
  }

  &.--hidden {
    visibility: hidden;
  }

  &.--size-sm {

    .c-modal__content {
      width: min(100% - 32px, 480px);
    }
  }

  &.--size-xl {

    .c-modal__content {
      width: min(100% - 32px, 1140px);
    }
  }
}
