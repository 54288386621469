@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

@use "settings/s-spacing.scss" as spacing;

.main-header-search__form {
  position: absolute;
  right: 0;
  width: 0;
  overflow: hidden;
  transition: width general.$transition-01;
  background-color: colors.$white;
  z-index: 150;
}

.main-header-search__icon {

  .icon {

    path {
      fill: colors.$primary-300;
    }
  }
}

.main-header-search {

  &.--search-open {

    .main-header-search__form {
      width: 100%;

      @include media-breakpoint-down(sm) {
        width: 100vw;
        padding: 7px spacing.$container-padding;
        top: 50%;
        transform: translateY(-50%);
        right: calc(#{spacing.$container-padding} * -1);
      }
    }
  }
}
