@use "settings/s-spacing.scss" as spacing;

.base-heading {
  margin-bottom: 0;

  &--h1 {
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    margin-bottom: 32px;

    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &--h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: spacing.$spacing-sm;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &--h3 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 700;
  }

  &--h4 {
    font-size: 24px;
    line-height: 32px;
  }

  &--h5 {
    font-size: 20px;
    line-height: 24px;
  }

  &--h6 {
    font-size: 20px;
    line-height: 24px;
  }

  &.--m-bottom-0 {
    margin-bottom: 0;
  }
}
