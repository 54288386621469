@use "settings/s-general" as general;

$paddingY: general.$spacing * 6;

.g-pagination {

  &.--left {
    justify-content: flex-start;
  }

  &.--center {
    justify-content: center;
  }

  &.--right {
    justify-content: flex-end;
  }
}
