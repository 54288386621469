.g-icon-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 32px;
  justify-content: space-between;
  flex-wrap: wrap;

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
}
