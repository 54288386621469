@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

@use "settings/s-spacing.scss" as spacing;

.footer-column {
  position: relative;
  width: min(264px, 100%);
  padding-top: 16px;

  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.footer-column__heading {
  margin-bottom: general.$spacing * 2;
}

.footer-column__perex {
  line-height: 32px;

  @include media-breakpoint-down(lg) {
    text-align: center;
  }
}

.footer-column__subheading {
  margin-bottom: general.$spacing * 2;
}

.footer-column__image {
  width: max-content;
  background-color: colors.$primary-300;
  border: 4px solid colors.$primary-300;
  border-radius: general.$border-base;

  a {
    display: block;
  }
}

.footer-column__logos {

  @include media-breakpoint-down(lg) {
    justify-content: center;
  }

  .img,
  .img img {
    border-radius: 0;
    display: flex;
    width: 50px;
    align-items: center;
  }
}
