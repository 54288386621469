@use "settings/s-fonts.scss" as fonts;

@use "settings/s-colors.scss" as colors;

body {
  font-family: fonts.$primary;
  color: colors.$black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale !important;
}
