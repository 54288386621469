@mixin grid-orphan-handler($items-per-row) {
  $items-span: 2;

  grid-template-columns: repeat(#{$items-per-row * $items-span}, 1fr);
  grid-auto-rows: 1fr;

  @if $items-per-row == 1 {
    grid-auto-rows: auto;
  }

  & > * {
    grid-column: span $items-span;

    @for $i from 1 to $items-per-row {

      @for $j from 1 through $i {
        &:nth-last-child(n):nth-child(#{$items-per-row}n - #{$j - 1}) {
          grid-column-end: auto;
        }

        &:nth-last-child(#{$j}):nth-child(#{$items-per-row}n - #{$items-per-row - $i + $j - 1}) {
          grid-column-end: calc(#{$j * $items-span + $items-per-row - $items-span - $i + 1} * -1);
        }
      }
    }
  }
}
