.g-graphic-detail {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  &.--behind {
    z-index: -1;
  }

  &.--hide-md {

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
