@use "settings/s-spacing.scss" as spacing;

.g-media-gallery {
  display: inline-flex;
  max-width: 100%;
  gap: spacing.$spacing-sm;

  @include media-breakpoint-down(lg) {
    justify-content: center;
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}
