@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

@use "settings/s-spacing.scss" as spacing;

.footer {
  background-color: colors.$white;
}

.footer__top-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: spacing.$spacing-sm;
  position: relative;
  padding: spacing.$spacing-lg 0 spacing.$spacing-md;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: spacing.$spacing-md;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: spacing.$spacing-xl-mobile;
  }

  @include media-breakpoint-down(lg) {
    text-align: center;
    justify-items: center;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100vw;
    border-bottom: 8px solid colors.$primary-300;
    right: 0;
  }
}

.footer__middle-content {
  display: grid;
  row-gap: 32px;
  grid-template-columns: 1fr;
  padding: 24px 0;
  border-top: 4px solid colors.$primary-100;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 30% 35% 35%;
  }

  .footer-column {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 0 32px;

    @include media-breakpoint-down(xxl) {
      padding: 0 24px;
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      align-items: center;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-left: 1px solid colors.$secondary-200;
      height: min(70px, 100%);

      @include media-breakpoint-down(xl) {
        display: none;
      }

      @include media-breakpoint-down(lg) {
        display: block;
        width: min(100%, 320px);
        top: -16px;
        left: auto;
        margin: 0 auto;
        height: auto;
        border-bottom: 1px solid colors.$secondary-200;
      }
    }

    &:first-child {

      &::before {
        display: none;
      }
    }
  }

  .footer-column__heading {
    margin-bottom: 0;
    flex: 0 0 auto;
  }

  .footer-column__perex {
    line-height: 24px;
    margin-bottom: 0;
  }

  .footer-column__logo {
    flex: 0 0 auto;
    max-width: 35%;
  }

  .base-heading {
    font-size: 16px;
    color: colors.$primary-500;
  }
}

.footer__bottom-content {
  padding: 24px 0;
  border-top: 4px solid colors.$primary-100;
}
