@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

.i-info-card {
  padding: 40px;
  background: colors.$secondary-300;
  border-radius: general.$border-base;
  color: colors.$secondary-600;

  @include media-breakpoint-up(xl) {
    padding-right: 96px;
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }

  @include media-breakpoint-down(md) {
    padding: 24px;
  }

  .base-header {
    max-width: none;

    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }
}

.i-info-card__header {
  width: clamp(50%, 824px, 100%);
}

.i-info-card__button {
  flex: 0 0 auto;
}
