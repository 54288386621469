@use "tools/t-grid-orphan-item-handler.scss" as orphan-handler;

.u-flex-grid {
  display: grid;

  $sizes: sm, md, lg, xl, xxl;

  @each $size in $sizes {

    @for $i from 1 through 6 {

      @include media-breakpoint-up($size) {
        &.--#{$size}-#{$i} {

          @include orphan-handler.grid-orphan-handler($i);
        }
      }
    }
  }
}
