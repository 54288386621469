@use "settings/s-spacing.scss" as spacing;

.g-category-cards {
  gap: 32px;
  margin-bottom: spacing.$spacing-lg;
}

.content-w-aside {

  .g-category-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
    }

    .i-product-list {
      grid-column: span 1;
      grid-column-end: auto !important;
    }
  }
}
