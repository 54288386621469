@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

$borderRadius: general.$border-base;
$placeholderColor: colors.$gray-200;

.img {
  overflow: hidden;
  border-radius: $borderRadius;

  &.--ratio-4-3 {
    aspect-ratio: 4/3;
  }

  &.--ratio-16-9 {
    aspect-ratio: 16/9;
  }

  &.--ratio-3-2 {
    aspect-ratio: 3/2;
  }

  &.--ratio-1-1 {
    aspect-ratio: 1/1;
  }

  img {
    border-radius: $borderRadius;
  }

  &.--contain {

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &.--cover {

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &.--placeholder {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $placeholderColor;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: -1;
    }
  }
}
