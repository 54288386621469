@use "settings/s-general.scss" as general;

.footer-social-group {
  margin: general.$spacing * 10 auto 0;
  width: min(200px, 100%);

  li {
    display: block;
  }

  @include media-breakpoint-down(lg) {
    gap: 16px;
  }

  @include media-breakpoint-down(md) {
    margin-top: general.$spacing * 6;
  }
}
