@use "settings/s-colors.scss" as colors;

.i-gallery-slider__content {

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: max(48px, 75%);
    background-color: colors.$secondary-200;
    transform: translateY(-50%);
  }
}

.i-gallery-slider__author {
  line-height: 24px;
  color: colors.$primary-500;
}

.i-gallery-slider__image {

  .img,
  img {
    height: var(--g-gallery-slider-image-height);
    aspect-ratio: auto;
    border-radius: 0;
  }
}
