@use "settings/s-spacing.scss" as spacing;

@use "settings/s-colors.scss" as colors;

$section-background-color: colors.$background;

.base-section {
  margin-top: spacing.$spacing-xl;
  margin-bottom: spacing.$spacing-xl;

  &:first-of-type {
    margin-top: 0;
  }

  &.--p-top-0 {
    padding-top: 0;
  }

  &.--p-bottom-0 {
    padding-bottom: 0;
  }

  &.--container-reset {

    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.--background {
    background-color: $section-background-color;
    padding-bottom: 0;

    .container {
      padding-top: spacing.$spacing-xl;
      padding-bottom: spacing.$spacing-xl;
    }

    .--grayscale {
      background-color: $section-background-color;
    }

    @extend %u-section-top-divider;

    & + & {
      margin-top: calc(-1 * #{spacing.$spacing-xl});

      &::after {
        display: none;
      }

      .container {
        padding-top: 0;
      }
    }
  }

  &.--divider {

    @extend %u-section-bottom-divider;

    & + .base-section.--background {

      @include media-breakpoint-up(lg) {
        margin-top: calc(-1 * #{spacing.$spacing-xl});
      }
    }
  }
}
