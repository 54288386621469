@use "settings/s-general.scss" as general;

@use "settings/s-typography.scss" as typo;

@use "settings/s-colors.scss" as colors;

.entry-content {
  padding: 0;
  text-align: left;

  // Empty tags white space fix

  strong:empty,
  b:empty,
  p:empty {
    display: none;
  }

  hr {
    border: none;
    background: colors.$gray-200;
    height: 1px;
    margin: general.$spacing * 6 0;
    transform: translateY(typo.$base-text-offset);
  }

  .wp-caption {

    p {
      font-size: 12px;
      line-height: 16px;
      margin: 4px 0 16px;
      text-align: left;
    }

    a {
      float: none;
      margin: 0;
    }
  }

  &.--narrow {

    @include media-breakpoint-up(xl) {
      width: 66.7%;
      margin: 0 auto;
    }
  }
}
