@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

.i-aside-nav__in {
  position: relative;
  border-radius: general.$border-base;
  box-shadow: general.$shadow-06;
  background: colors.$white;
  z-index: 10;
}
