@use "settings/s-general" as general;

@use "settings/s-colors" as colors;

$border-radius: general.$border-base;

.f-textarea {
  border: 1px solid colors.$gray;
  border-radius: $border-radius;

  &:focus-within {
    border-color: colors.$focus;
    color: colors.$focus;
  }

  &.--error {
    border-color: colors.$danger;
    color: colors.$danger;
  }
}
