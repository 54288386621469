@use "settings/s-general.scss" as general;

@use "settings/s-typography.scss" as typo;

@use "settings/s-colors.scss" as colors;

.entry-content {

  code {
    display: inline-block;
    font-size: typo.$base-text-font-size;
    font-weight: 400;
    padding: 24px 32px;
    margin: general.$spacing * 6 0;
    border-radius: general.$border-base;
    position: relative;
    font-style: italic;
    color: #292929b3;
    background-color: #2929290a;

    p {
      font-style: italic;
      margin-bottom: 0;
    }
  }
}
