@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

$styleTypes: "lower-alpha", "upper-alpha", "lower-latin", "upper-latin",
  "lower-greek", "upper-greek", "lower-roman", "upper-roman";

.entry-content {

  ol {

    & > li {
      position: relative;
      counter-increment: item;
      padding-left: 35px;

      &::before {
        content: counter(item) ".";
        font-weight: 700;
        font-size: 18px;
        top: 1px;
        width: 28px;
        height: 28px;
        text-align: center;
        display: inline-block;
        position: relative;
        margin-right: 8px;
        color: colors.$primary;
        margin-left: -35px;
        border-radius: 100%;
        padding: 1px 4px;
        padding-left: 6px;
      }

      ol > li { /* stylelint-disable-line no-descending-specificity */
        counter-increment: sub-item;

        &::before {
          content: counter(sub-item) ".";
        }

        ol > li { /* stylelint-disable-line no-descending-specificity */
          counter-increment: sub-sub-item;

          &::before {
            content: counter(sub-sub-item) ".";
          }
        }
      }
    }

    @each $type in $styleTypes {
      &[style*="list-style-type: #{$type}"] {

        li {

          &::before {
            content: counter(item, #{$type}) ")";
          }
        }
      }
    }
  }

  ul {

    & > li { /* stylelint-disable-line no-descending-specificity */
      padding-left: 20px;

      &::before { /* stylelint-disable-line no-descending-specificity */
        content: "";
        display: inline-block;
        position: relative;
        top: 50%;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        margin-right: 10px;
        background-color: colors.$primary;
        transform: translateY(-2px);
        margin-left: -16px;
      }
    }
  }

  ul,
  ol {
    list-style: none !important;
    padding: 0;
    margin: 0;
    margin-bottom: general.$spacing * 3 !important;
    color: colors.$black-400;

    ul,
    ol {
      margin-bottom: 0 !important;
      margin-top: general.$spacing;
    }

    li { /* stylelint-disable-line no-descending-specificity */
      margin-bottom: general.$spacing * 2.5;

      .--background & {

        &::before {
          background-color: colors.$conversion-400;
        }
      }
    }
  }
}
