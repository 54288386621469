@use "settings/s-colors.scss" as colors;

@use "settings/s-spacing.scss" as spacing;

.g-footer-nav {
  margin-bottom: spacing.$spacing-md;

  li {
    line-height: 32px;

    .link {
      text-decoration-color: currentcolor;

      &:hover {
        text-decoration-color: transparent;
      }
    }
  }

  &.--horizontal {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
    }

    li {
      text-align: center;
      padding: 0 36px;

      @include media-breakpoint-down(xxl) {
        padding: 0 24px;
      }

      @include media-breakpoint-down(xl) {
        padding: 0 12px;
      }

      @include media-breakpoint-down(lg) {
        flex: 1 1 100%;
      }
    }
  }
}
