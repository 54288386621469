.s-contact-form__icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(50%);

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.s-contact-form__image {
  position: absolute;
  right: 0;
  top: 0;

  @include media-breakpoint-down(xxl) {
    display: none;
  }
}

.s-contact-form__form {
  position: relative;
  margin: 0 auto;
}

.s-contact-form {

  &.--large {

    .s-contact-form__image {
      display: none;
    }

    .s-contact-form__icon {
      display: none;
    }

    .s-contact-form__form {
      width: 100%;
    }

    .i-graphic-detail__in {

      svg {

        @include media-breakpoint-down(xl) {
          max-width: 50%;
        }
      }
    }
  }
}

.s-contact-form__row {
  position: relative;

  @include media-breakpoint-up(xl) {
    padding-top: 32px;
  }

  &.--with-image {

    @include media-breakpoint-up(xxl) {
      min-height: 688px;
    }

    .s-contact-form__form {

      @include media-breakpoint-up(xxl) {
        margin: 0;
      }
    }
  }
}
