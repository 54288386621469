@use "settings/s-colors.scss" as colors;

$color: inherid;
$colorRequired: colors.$danger;

.f-label {
  color: $color;

  &.--required {

    &::after {
      content: "*";
      display: inline-block;
      width: 1em;
      color: $colorRequired;
      font-size: 0.85em;
      vertical-align: top;
      margin-left: -0.2em;
    }
  }
}
