@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

$menu-item-padding-vertical: 12px;
$menu-item-padding-horizontal: 24px;

$menu-item-padding-vertical-small: 12px;
$menu-item-padding-horizontal-small: 16px;

$menu-item-padding-vertical-mobile: 8px;
$menu-item-padding-horizontal-mobile: 24px;

/* mobile version of menu */

@mixin menu-item-mobile {

  a {
    --horizontal-padding: #{$menu-item-padding-horizontal-mobile};
    --vertical-padding: #{$menu-item-padding-vertical-mobile};

    justify-content: space-between;
    line-height: 2;
  }

  > a {
    padding: var(--vertical-padding) var(--horizontal-padding);
  }

  &.--level-1 {

    &:first-child {
      padding-top: calc(2 * var(--vertical-padding));
    }

    &:last-child {
      padding-bottom: calc(2 * var(--vertical-padding));
    }

    > a {

      &::before {
        display: none;
      }
    }
  }

  .sub-menu {
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 100;
    padding: 0;
    pointer-events: auto;
    border-radius: 0;
    box-shadow: none;
    transition: opacity 0.2s ease;
    transition-property: max-height, opacity, transform;
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: colors.$primary-100;
      opacity: 0.5;
      z-index: -1;
    }

    a {

      .icon {
        transform: none;
      }
    }
  }

  .menu-item__toggle {

    .btn {
      width: 32px;
      height: 32px;
    }
  }

  &.--opened {

    > .sub-menu {
      max-height: 10000px;
      transform: scaleY(1);
      opacity: 1;
    }

    > a {

      .menu-item__toggle {
        transform: rotate(-180deg);
      }
    }
  }

  .menu-item {

    &.--level-2 {

      > a {
        padding-left: calc(var(--horizontal-padding) + 12px);
      }

      .sub-menu {
        margin-left: 24px;
        background: colors.$primary-100;
        width: auto;
      }
    }
  }
}

.menu-item__toggle {
  transition: general.$transition-01;

  .btn {
    width: auto;
    height: auto;
  }
}

.menu-item {
  --horizontal-padding: #{$menu-item-padding-horizontal};
  --vertical-padding: #{$menu-item-padding-vertical};

  @include media-breakpoint-between(lg, xxl) {
    --horizontal-padding: #{$menu-item-padding-vertical-small};
    --vertical-padding: #{$menu-item-padding-vertical-small};
  }

  @include media-breakpoint-down(lg) {
    --horizontal-padding: #{$menu-item-padding-vertical-mobile};
    --vertical-padding: #{$menu-item-padding-vertical-mobile};
  }

  position: relative;

  a {
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: colors.$primary-800;
    text-decoration: none;

    &:hover {
      color: colors.$primary-500;
    }

    .icon {
      max-width: 10px;

      path {
        fill: colors.$conversion-400;
      }
    }
  }

  .sub-menu {
    width: max-content;
    min-width: 180px;
    background-color: colors.$white;
    position: absolute;
    left: 0;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    border-radius: general.$border-base-small;
    box-shadow: general.$shadow-03;
    transition-property: opacity, transform;
    padding: var(--vertical-padding) 0;

    a {
      justify-content: space-between;
      padding: var(--vertical-padding) var(--horizontal-padding);
      font-size: 16px;
      font-weight: 600;

      .icon {

        @include media-breakpoint-up(lg) {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &:hover {

    > .sub-menu {

      @include media-breakpoint-up(lg) {
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  /* stylelint-disable no-descending-specificity */

  &.--active {

    > a {
      color: colors.$primary-500;
    }
  }

  &.--level-1 {

    > a {
      position: relative;
      padding: 16px var(--horizontal-padding);
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 16px;
        width: 100%;
        height: 14px;
        background-color: colors.$primary-100;
        transform: scaleY(0);
        transform-origin: bottom;
        transition: general.$transition-01;
        z-index: -1;
      }

      &:hover {

        &::before {
          transform: scaleY(1);
        }
      }
    }

    > .sub-menu {

      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 100%;
        left: var(--horizontal-padding);
        width: 24px;
        height: 13px;
        background: inherit;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      }
    }
  }

  &.--level-2 {

    > .sub-menu {

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: calc(-1 * var(--vertical-padding));
        left: 100%;
      }
    }
  }

  @include media-breakpoint-down(lg) {

    @include menu-item-mobile;
  }

  &.--mobile {

    @include menu-item-mobile;
  }
  /* stylelint-enable no-descending-specificity */
}
