@use "settings/s-colors.scss" as colors;

@use "settings/s-general.scss" as general;

.ts-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ts-hidden-accessible {
  clip-path: inset(50%) !important;
  width: 1px !important;
  white-space: nowrap !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.ts-control {
  flex: 0 1 auto;
  width: 100%;
  background: none;
  border: none;
  box-shadow: none;
  color: inherit;

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .item {
    color: inherit;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: calc(100% - 40px);
  }

  input {
    display: none;
  }
}

.ts-dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 8px;
  z-index: 10;
  width: 100%;
  border-radius: general.$border-base;
  box-shadow: general.$shadow-border colors.$gray;
  background: colors.$white;
  overflow: hidden;

  .option {
    padding: 8px 16px;
    cursor: pointer;

    &.active {
      background: colors.$primary-100;
    }
  }
}
