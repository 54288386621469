@use "settings/s-general.scss" as general;

@use "settings/s-typography.scss" as typo;

@use "settings/s-colors.scss" as colors;

.entry-content {

  .ratio {
    margin: 32px 0 40px;
  }
}
